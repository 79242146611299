import React, { useCallback, useMemo } from 'react';
import { FixedSizeList } from 'react-window';
import { observer } from 'mobx-react-lite';

import { TranslationKeys as T } from '../../../../../core/constants/translationKeys';
import useTranslation from '../../../../../hooks/useTranslation';
import PartnerCheckboxRow from './partner-checkbox-row';

import './style.css';

const PartnersCheckboxGroup = observer(({
  data,
  value,
  onChange
}) => {

  const t = useTranslation();

  const slectedItemsMap = useMemo(() => {
    return value.reduce((acc, curr) => {
      acc[curr] = true;
      return acc;
    }, {});
  }, [value]);

  const isAllSelected = useMemo(() => {
    return value.length === data.length;
  }, [value, data]);

  const handleSelectAll = useCallback(() => {
    const selectedItems = isAllSelected ? [] : data.map(({ id }) => id);
    onChange(selectedItems);
  }, [onChange, data, isAllSelected]);

  const handleChange = useCallback((event, id) => {
    const { checked } = event.target;
    let valuesCopy = [...value];

    if (checked) {
      valuesCopy.push(id);
    } else {
      valuesCopy = valuesCopy.filter((partnerId) => partnerId !== id);
    }
    onChange(valuesCopy);
  }, [onChange, value]);

  const rowRenderer = useCallback(({ index, style }) => {
    const { id, name } = data[index];
    return (
      <div style={style}>
        <PartnerCheckboxRow
          id={id}
          key={id}
          label={name}
          checked={slectedItemsMap[id] || false}
          onChange={(event) => handleChange(event, id)}
        />
      </div>
    )
  }, [data, handleChange, slectedItemsMap]);

  return (
    <div className="partners-virtual-scroll">
      {
        data.length ? (
          <PartnerCheckboxRow
            checked={isAllSelected}
            label={t(T.SELECT_ALL)}
            onChange={handleSelectAll}
          />
        ) : null
      }
      <FixedSizeList
        height={152}
        itemSize={38}
        itemCount={data.length}
      >
        {rowRenderer}
      </FixedSizeList>
    </div>
  );
});

export default PartnersCheckboxGroup;
