import { httpClientV2 } from '../httpClient/httpClient';
import { ApiVersion } from '../../core/enums/ApiVersion';
import {
  getRoundReportsUrl,
  getBogBetReportsUrl,
  getArcadeBetReportsUrl,
  getTableGamesRoundReportsUrl,
  getTableGamesBetReportsUrl,
  getTableGamesBetDetailsUrl,
  getTableGamesRoundDetailsUrl,
  getLotteryRoundReportsUrl,
  getLotteryBetReportsUrl,
  getLotteryBetDetailsUrl,
  getBetshopRoundReportsUrl,
  getBetshopBetReportsUrl,
  getBogBetDetailsUrl,
  getBogGamesRoundDetailsUrl,
  getSlotBetReportsUrl,
  getSlotBetDetailsUrl,
  getTronixBetReportsUrl,
  getTronixBetDetailsUrl,
  getVirtualBetReportsUrl,
  getVirtualBetDetailsUrl,
  getVirtualRoundReportsUrl,
  getVirtualMarketReportsUrl,
  getBetshopBetDetailsUrl,
  getPlayersReportUrl,
  getPlayersByGameReportUrl,
  getBetshopMarketReportsUrl,
  getArcadeGamesBetDetailsUrl,
  getArcadeRoundReportsUrl,
} from '../../core/constants/endpoints';
import { abortFetch } from '../../core/helpers/abortController';
import { ABORT_API_KEYS } from '../../core/constants/abortApiKeys';

class ReportsService {

  /**
   * @name getBogRoundReports
   * @description this endpoint need to get rounds report
   * @param {BogRoundReportQuery} params
   * @param {ApiOption=} option
   * @returns {Promise<RoundReportResponse>}
   */
  async getBogRoundReports(params, option = { version: ApiVersion.V1 }) {
    const signal = abortFetch(ABORT_API_KEYS.GetRoundReport);
    return await httpClientV2.get(getRoundReportsUrl(option), {
      params,
      signal
    });
  }

  /**
   * @name getBogBetReports
   * @description this endpoint need to get bets report
   * @param {BogBetReportQuery} params
   * @param {ApiOption=} option
   * @returns {Promise<BetReportResponse>}
   */
  async getBogBetReports(params, option = { version: ApiVersion.V1 }) {
    const signal = abortFetch(ABORT_API_KEYS.GetBetReport);
    return await httpClientV2.get(getBogBetReportsUrl(option), {
      params,
      signal
    });
  }

  /**
   * @name getBetshopRoundReports
   * @description this endpoint need to get rounds report
   * @param {BogRoundReportQuery} params
   * @param {ApiOption=} option
   * @returns {Promise<RoundReportResponse>}
   */
  async getBetshopRoundReports(params, option = { version: ApiVersion.V1 }) {
    const signal = abortFetch(ABORT_API_KEYS.GetRoundReport);
    return await httpClientV2.get(getBetshopRoundReportsUrl(option), {
      params,
      signal
    });
  }

  /**
   * @name getBetshopBetReports
   * @description this endpoint need to get bets report
   * @param {BogBetReportQuery} params
   * @param {ApiOption=} option
   * @returns {Promise<BetReportResponse>}
   */
  async getBetshopBetReports(params, option = { version: ApiVersion.V1 }) {
    const signal = abortFetch(ABORT_API_KEYS.GetBetReport);
    return await httpClientV2.get(getBetshopBetReportsUrl(option), {
      params,
      signal
    });
  }

  /**
   * @name getTableGamesRoundReports
   * @description this endpoint need to get rounds report
   * @param {BogRoundReportQuery} params
   * @param {ApiOption=} option
   * @returns {Promise<RoundReportResponse>}
   */
  async getTableGamesRoundReports(params, option = { version: ApiVersion.V1 }) {
    const signal = abortFetch(ABORT_API_KEYS.GetRoundReport);
    return await httpClientV2.get(getTableGamesRoundReportsUrl(option), {
      params,
      signal
    });
  }

  /**
   * @name getTableGamesBetReports
   * @description this endpoint need to get bets report
   * @param {BogBetReportQuery} params
   * @param {ApiOption=} option
   * @returns {Promise<BetReportResponse>}
   */
  async getTableGamesBetReports(params, option = { version: ApiVersion.V1 }) {
    const signal = abortFetch(ABORT_API_KEYS.GetBetReport);
    return await httpClientV2.get(getTableGamesBetReportsUrl(option), {
      params,
      signal
    });
  }

  /**
   * @name getBetshopBetDetails
   * @description this endpoint need to get bets details
   * @param {TableBetReportDetailsQuery} params
   * @param {ApiOption=} option
   * @returns {Promise<Array<TableBetReportDetailsItem>}
   */
  async getBetshopBetDetails(params, option = { version: ApiVersion.V1 }) {
    return await httpClientV2.get(getBetshopBetDetailsUrl(option), {
      params,
    });
  }

  /**
   * @name getBetshopMarketReports
   * @description this endpoint need to get market details
   * @param {VirtualMarketReportParams} params
   * @param {ApiOption=} option
   * @returns {Promise<Array<VirtualMarketReportResponse>}
   */
  async getBetshopMarketReports(params, option = { version: ApiVersion.V1 }) {
    const signal = abortFetch(ABORT_API_KEYS.GetMarketReport);
    return await httpClientV2.get(getBetshopMarketReportsUrl(option), {
      params,
      signal,
    });
  }

  /**
   * @name getTableGamesBetDetails
   * @description this endpoint need to get bets details
   * @param {TableBetReportDetailsQuery} params
   * @param {ApiOption=} option
   * @returns {Promise<Array<TableBetReportDetailsItem>}
   */
  async getTableGamesBetDetails(params, option = { version: ApiVersion.V1 }) {
    return await httpClientV2.get(getTableGamesBetDetailsUrl(option), {
      params,
    });
  }

  async getArcadeGamesBetDetails(params, option = { version: ApiVersion.V1 }) {
    return await httpClientV2.get(getArcadeGamesBetDetailsUrl(option), {
      params,
    });
  }

  /**
   * @name getTableGamesRoundDetails
   * @description this endpoint need to get bets details
   * @param {TableRoundReportDetailsQuery} params
   * @param {ApiOption=} option
   * @returns {Promise<TableRoundReportDetailsResponse>}
   */
  async getTableGamesRoundDetails(params, option = { version: ApiVersion.V1 }) {
    return await httpClientV2.get(getTableGamesRoundDetailsUrl(option), {
      params,
    });
  }

  /**
   * @name getLotteryRoundReports
   * @description this endpoint need to get rounds report
   * @param {BogRoundReportQuery} params
   * @param {ApiOption=} option
   * @returns {Promise<RoundReportResponse>}
   */
  async getLotteryRoundReports(params, option = { version: ApiVersion.V1 }) {
    const signal = abortFetch(ABORT_API_KEYS.GetRoundReport);
    return await httpClientV2.get(getLotteryRoundReportsUrl(option), {
      params,
      signal,
    });
  }

  /**
   * @name getLotteryBetReports
   * @description this endpoint need to get bets report
   * @param {BogBetReportQuery} params
   * @param {ApiOption=} option
   * @returns {Promise<BetReportResponse>}
   */
  async getLotteryBetReports(params, option = { version: ApiVersion.V1 }) {
    const signal = abortFetch(ABORT_API_KEYS.GetBetReport);
    return await httpClientV2.get(getLotteryBetReportsUrl(option), {
      params,
      signal
    });
  }

  /**
   * @name getLotteryBetDetails
   * @description this endpoint need to get bets details
   * @param {TableBetReportDetailsQuery} params
   * @param {ApiOption=} option
   * @returns {Promise<Array<TableBetReportDetailsItem>}
   */
  async getLotteryBetDetails(params, option = { version: ApiVersion.V1 }) {
    return await httpClientV2.get(getLotteryBetDetailsUrl(option), {
      params,
    });
  }

  /**
   * @name getBogBetDetails
   * @description this endpoint need to get bets details
   * @param {Object} params
   * @param {ApiOption=} option
   * @returns {Promise<Object>}
   */
  async getBogBetDetails(params, option = { version: ApiVersion.V1 }) {
    return await httpClientV2.get(getBogBetDetailsUrl(option), {
      params,
    });
  }

  /**
   * @param {Object} params
   * @param {ApiOption} option
   * @return {Promise<Object>}
   */
  async getBogRoundDetails(params, option = { version: ApiVersion.V1 }) {
    return await httpClientV2.get(getBogGamesRoundDetailsUrl(option), {
      params,
    });
  }

  /**
   * @name getArcadeBetReports
   * @description this endpoint need to get bet reports of arcade
   * @param {ArcadeBetReportsQuery} params
   * @param {ApiOption=} option
   * @returns {Promise<ArcadeBetReportResponse>}
   */
  async getArcadeBetReports(params, option = { version: ApiVersion.V1 }) {
    const signal = abortFetch(ABORT_API_KEYS.GetBetReport);
    return await httpClientV2.get(getArcadeBetReportsUrl(option), {
      params,
      signal
    });
  }

  async getArcadeRoundReports(params, option = { version: ApiVersion.V1 }) {
    const signal = abortFetch(ABORT_API_KEYS.GetRoundReport);
    return await httpClientV2.get(getArcadeRoundReportsUrl(option), {
      params,
      signal
    });
  }

  /**
   * @name getSlotBetReports
   * @description this endpoint need to get bets report
   * @param {BogBetReportQuery} params
   * @param {ApiOption=} option
   * @returns {Promise<Array<SlotBetReport>>}
   */
  async getSlotBetReports(params, option = { version: ApiVersion.V1 }) {
    const signal = abortFetch(ABORT_API_KEYS.GetBetReport);
    return await httpClientV2.get(getSlotBetReportsUrl(option), {
      params,
      signal
    });
  }

  /**
   * @name getSlotBetDetails
   * @description this endpoint need to get bets details
   * @param {Object} params
   * @param {ApiOption=} option
   * @returns {Promise<Object>}
   */
  async getSlotBetDetails(params, option = { version: ApiVersion.V1 }) {
    return await httpClientV2.get(getSlotBetDetailsUrl(option), {
      params,
    });
  }

  async getTronixBetReports(params, option = { version: ApiVersion.V1 }) {
    const signal = abortFetch(ABORT_API_KEYS.GetBetReport);
    return await httpClientV2.get(getTronixBetReportsUrl(option), {
      params,
      signal
    });
  }

  async getTronixBetDetails(params, option = { version: ApiVersion.V1 }) {
    return await httpClientV2.get(getTronixBetDetailsUrl(option), {
      params,
    });
  }

  /**
   * @name getVirtualBetDetails
   * @description this endpoint need to get bets details
   * @param {Object} params
   * @param {ApiOption=} option
   * @returns {Promise<Object>}
   */
  async getVirtualBetDetails(params, option = { version: ApiVersion.V1 }) {
    return await httpClientV2.get(getVirtualBetDetailsUrl(option), {
      params,
    });
  }

  /**
   * @name getVirtualBetReports
   * @description this endpoint need to get bets report
   * @param {VirtualBetReportQuery} params
   * @param {ApiOption=} option
   * @returns {Promise<Array<VirtualBetReport>>}
   */
  async getVirtualBetReports(params, option = { version: ApiVersion.V1 }) {
    const signal = abortFetch(ABORT_API_KEYS.GetBetReport);
    return await httpClientV2.get(getVirtualBetReportsUrl(option), {
      params,
      signal
    });
  }

  /**
   * @name getVirtualRoundReports
   * @description this endpoint need to get rounds report
   * @param {RoundReportQuery} params
   * @param {ApiOption=} option
   * @returns {Promise<RoundReportResponse>}
   */
  async getVirtualRoundReports(params, option = { version: ApiVersion.V1 }) {
    const signal = abortFetch(ABORT_API_KEYS.GetRoundReport);
    return await httpClientV2.get(getVirtualRoundReportsUrl(option), {
      params,
      signal,
    });
  }

  /**
   * @name getVirtualMarketReports
   * @description this endpoint need to get market report
   * @param {VirtualMarketReportParams} params
   * @param {ApiOption=} option
   * @returns {Promise<VirtualMarketReportResponse>}
   */
  async getVirtualMarketReports(params, option = { version: ApiVersion.V1 }) {
    const signal = abortFetch(ABORT_API_KEYS.GetMarketReport);
    return await httpClientV2.get(getVirtualMarketReportsUrl(option), {
      params,
      signal,
    });
  }

  async getPlayersReport(params, option = { version: ApiVersion.V1 }) {
    return await httpClientV2.get(getPlayersReportUrl(option), { params });
  }

  async getPlayersByGameReport(params, option = { version: ApiVersion.V1 }) {
    return await httpClientV2.get(getPlayersByGameReportUrl(option), { params });
  }

}

export const reportsService = new ReportsService();
