import React from 'react';
import moment from 'moment';

import useTranslation from './useTranslation';
import { formatNumber } from '../core/helpers/formatNumber';
import { convertByCurrency, getCurrency } from '../core/helpers/getCurrency';
import { TableColumnType } from '../core/enums/TableColumnType';
import { DATE_FORMAT_D_M_Y_H_M_S } from '../core/constants/util';

const formatters = {
  [TableColumnType.Text]: () => (value) => value !== null ? value : '-',
  [TableColumnType.Date]: () => (value) => (
    typeof value === 'string' ? moment(moment.utc(value).toDate()).format(DATE_FORMAT_D_M_Y_H_M_S) : '-'
  ),
  [TableColumnType.Number]: () => (value) => formatNumber(Number(value).toFixed(1)),
  [TableColumnType.WithCurrency]: ({ currentCurrency }) => (value) => getCurrency(value, currentCurrency) || '-',
  [TableColumnType.ConvertedNumber]: ({ currentCurrency }) => (value) => convertByCurrency(value, currentCurrency),
  [TableColumnType.ConvertedNumberSameRate]: ({ currentCurrency }) => (value) => convertByCurrency(value, { ...currentCurrency, rate: 1 }),
  [TableColumnType.Odd]: () => (value) => (value !== null && value !== undefined) ? `x${value}` : '-',
  [TableColumnType.None]: () => (value) => value,
};

const useTableColumns = (columns, currentCurrency, isMobile = false) => {
  const translate = useTranslation();
  let formatter = value => value;
  let formattedText;

  let cols = [...columns];

  if (isMobile) {
    cols.sort((a, b) => {
      if (!a._mobileOrder) return 0;
      return a._mobileOrder > b._mobileOrder ? 1 : -1; 
    });
  }

  cols = cols
  .filter(({ hide }) => !hide)
  .map(({ _mobileOrder, text, _dataKey, _type, _text, ...rest }) => {
    formattedText = `${translate(text)}${_text ? ' ' + _text : ''}`;
    if (formatters[_type]) {
      formatter = formatters[_type]({ currentCurrency });
    }

    if (_type === TableColumnType.ConvertedNumber) {
      formattedText = `${translate(text)} (${currentCurrency.currencyCode})`;
    }

    const col = {
      dataKey: _dataKey,
      text: formattedText,
      formatter,
      sortable: false,
      ...rest,
    };

    if (isMobile) {
      col.sortable = false;
    }

    return col;
  });

  return {
    cols
  };
};

export default useTableColumns;
