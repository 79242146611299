import { enumToDropDown } from '../helpers/dropdownDataConverter';

export const Country = {
  NotSelected: 0,
  Afghanistan: 1,
  ÅlandIslands: 2,
  Albania: 3,
  Algeria: 4,
  AmericanSamoa: 5,
  Andorra: 6,
  Angola: 7,
  Anguilla: 8,
  Antarctica: 9,
  AntiguaAndBarbuda: 10,
  Argentina: 11,
  Armenia: 12,
  Aruba: 13,
  Australia: 14,
  Austria: 15,
  Azerbaijan: 16,
  TheBahamas: 17,
  Bahrain: 18,
  Bangladesh: 19,
  Barbados: 20,
  Belarus: 21,
  Belgium: 22,
  Belize: 23,
  Benin: 24,
  Bermuda: 25,
  Bhutan: 26,
  Bolivia: 27,
  BosniaAndHerzegovina: 28,
  Botswana: 29,
  BouvetIsland: 30,
  Brazil: 31,
  BritishIndianOceanTerritory: 32,
  Brunei: 33,
  Bulgaria: 34,
  BurkinaFaso: 35,
  Burundi: 36,
  Cambodia: 37,
  Cameroon: 38,
  Canada: 39,
  CaboVerde: 40,
  CaymanIslands: 41,
  CentralAfricanRepublic: 42,
  Chad: 43,
  Chile: 44,
  China: 45,
  ChristmasIsland: 46,
  CocosIslands: 47,
  Colombia: 48,
  Comoros: 49,
  Congo: 50,
  DRCongo: 51,
  CookIslands: 52,
  CostaRica: 53,
  CoteDIvoire: 54,
  Croatia: 55,
  Cuba: 56,
  Cyprus: 57,
  CzechRepublic: 58,
  Denmark: 59,
  Djibouti: 60,
  Dominica: 61,
  DominicanRepublic: 62,
  Ecuador: 63,
  Egypt: 64,
  ElSalvador: 65,
  EquatorialGuinea: 66,
  Eritrea: 67,
  Estonia: 68,
  Ethiopia: 69,
  FalklandIslands: 70,
  FaroeIslands: 71,
  Fiji: 72,
  Finland: 73,
  France: 74,
  FrenchGuiana: 75,
  FrenchPolynesia: 76,
  FrenchSouthernTerritories: 77,
  Gabon: 78,
  Gambia: 79,
  Georgia: 80,
  Germany: 81,
  Ghana: 82,
  Gibraltar: 83,
  Greece: 84,
  Greenland: 85,
  Grenada: 86,
  Guadeloupe: 87,
  Guam: 88,
  Guatemala: 89,
  Guernsey: 90,
  Guinea: 91,
  GuineaBissau: 92,
  Guyana: 93,
  Haiti: 94,
  HeardIslandandMcdonaldIslands: 95,
  VaticanCity: 96,
  Honduras: 97,
  HongKong: 98,
  Hungary: 99,
  Iceland: 100,
  India: 101,
  Indonesia: 102,
  Iran: 103,
  Iraq: 104,
  Ireland: 105,
  IsleofMan: 106,
  Israel: 107,
  Italy: 108,
  Jamaica: 109,
  Japan: 110,
  Jersey: 111,
  Jordan: 112,
  Kenya: 113,
  Kazakhstan: 114,
  Kiribati: 115,
  NorthKorea: 116,
  SouthKorea: 117,
  Kosovo: 118,
  Kuwait: 119,
  Kyrgyzstan: 120,
  Laos: 121,
  Latvia: 122,
  Lebanon: 123,
  Lesotho: 124,
  Liberia: 125,
  Libya: 126,
  Liechtenstein: 127,
  Lithuania: 128,
  Luxembourg: 129,
  Macau: 130,
  NorthMacedonia: 131,
  Madagascar: 132,
  Malawi: 133,
  Malaysia: 134,
  Maldives: 135,
  Mali: 136,
  Malta: 137,
  MarshallIslands: 138,
  Martinique: 139,
  Mauritania: 140,
  Mauritius: 141,
  Mayotte: 142,
  Mexico: 143,
  Micronesia: 144,
  Moldova: 145,
  Monaco: 146,
  Mongolia: 147,
  Montenegro: 148,
  Montserrat: 149,
  Morocco: 150,
  Mozambique: 151,
  Myanmar: 152,
  Namibia: 153,
  Nauru: 154,
  Nepal: 155,
  Netherlands: 156,
  NetherlandsAntilles: 157,
  NewCaledonia: 158,
  NewZealand: 159,
  Nicaragua: 160,
  Niger: 161,
  Nigeria: 162,
  Niue: 163,
  NorfolkIsland: 164,
  NorthernMarianaIslands: 165,
  Norway: 166,
  Oman: 167,
  Pakistan: 168,
  Palau: 169,
  Palestine: 170,
  Panama: 171,
  PapuaNewGuinea: 172,
  Paraguay: 173,
  Peru: 174,
  Philippines: 175,
  Pitcairn: 176,
  Poland: 177,
  Portugal: 178,
  PuertoRico: 179,
  Qatar: 180,
  Reunion: 181,
  Romania: 182,
  Russia: 183,
  Rwanda: 184,
  SaintHelena: 185,
  SaintKittsAndNevis: 186,
  SaintLucia: 187,
  SaintPierreAndMiquelon: 188,
  SaintVincentAndTheGrenadines: 189,
  Samoa: 190,
  SanMarino: 191,
  SaoTomeAndPrincipe: 192,
  SaudiArabia: 193,
  Senegal: 194,
  Serbia: 195,
  Seychelles: 196,
  SierraLeone: 197,
  Singapore: 198,
  Slovakia: 199,
  Slovenia: 200,
  SolomonIslands: 201,
  Somalia: 202,
  SouthAfrica: 203,
  SouthGeorgiaAndTheSouthSandwichIslands: 204,
  Spain: 205,
  SriLanka: 206,
  Sudan: 207,
  Suriname: 208,
  SvalbardAndJanMayen: 209,
  Eswatini: 210,
  Sweden: 211,
  Switzerland: 212,
  Syria: 213,
  Taiwan: 214,
  Tajikistan: 215,
  Tanzania: 216,
  Thailand: 217,
  TimorLeste: 218,
  Togo: 219,
  Tokelau: 220,
  Tonga: 221,
  TrinidadAndTobago: 222,
  Tunisia: 223,
  Turkey: 224,
  Turkmenistan: 225,
  TurksAndCaicosIslands: 226,
  Tuvalu: 227,
  Uganda: 228,
  Ukraine: 229,
  UnitedArabEmirates: 230,
  UnitedKingdom: 231,
  UnitedStates: 232,
  UnitedStatesMinorOutlyingIslands: 233,
  Uruguay: 234,
  Uzbekistan: 235,
  Vanuatu: 236,
  Venezuela: 237,
  Vietnam: 238,
  BritishVirginIslands: 239,
  UnitedStatesVirginIslands: 240,
  WallisAndFutuna: 241,
  WesternSahara: 242,
  Yemen: 243,
  Zambia: 244,
  Zimbabwe: 245,
  SintEustatius: 246,
  Curacao: 247,
  SaintBarthelemy: 248,
  SaintMartinDutch: 249,
  SaintMartinFrenchpart: 250,
  SouthSudan: 251,
  Bonaire: 252,
  Saba: 253,
  SintEustatiusSE: 254,
  SintMaarten: 255,
};

export const CountryNames = {
[Country.Afghanistan]: 'Afghanistan',
[Country.ÅlandIslands]: 'Aland Islands',
[Country.Albania]: 'Albania',
[Country.Algeria]: 'Algeria',
[Country.AmericanSamoa]: 'American Samoa',
[Country.Andorra]: 'Andorra',
[Country.Angola]: 'Angola',
[Country.Anguilla]: 'Anguilla',
[Country.Antarctica]: 'Antarctica',
[Country.AntiguaAndBarbuda]: 'Antigua and Barbuda',
[Country.Argentina]: 'Argentina',
[Country.Armenia]: 'Armenia',
[Country.Aruba]: 'Aruba',
[Country.Australia]: 'Australia',
[Country.Austria]: 'Austria',
[Country.Azerbaijan]: 'Azerbaijan',
[Country.TheBahamas]: 'The Bahamas',
[Country.Bahrain]: 'Bahrain',
[Country.Bangladesh]: 'Bangladesh',
[Country.Barbados]: 'Barbados',
[Country.Belarus]: 'Belarus',
[Country.Belgium]: 'Belgium',
[Country.Belize]: 'Belize',
[Country.Benin]: 'Benin',
[Country.Bermuda]: 'Bermuda',
[Country.Bhutan]: 'Bhutan',
[Country.Bolivia]: 'Bolivia',
[Country.BosniaAndHerzegovina]: 'Bosnia and Herzegovina',
[Country.Botswana]: 'Botswana',
[Country.BouvetIsland]: 'Bouvet Island',
[Country.Brazil]: 'Brazil',
[Country.BritishIndianOceanTerritory]: 'British Indian Ocean Territory',
[Country.Brunei]: 'Brunei',
[Country.Bulgaria]: 'Bulgaria',
[Country.BurkinaFaso]: 'Burkina Faso',
[Country.Burundi]: 'Burundi',
[Country.Cambodia]: 'Cambodia',
[Country.Cameroon]: 'Cameroon',
[Country.Canada]: 'Canada',
[Country.CaboVerde]: 'Cabo Verde (Cape Verde)',
[Country.CaymanIslands]: 'Cayman Islands',
[Country.CentralAfricanRepublic]: 'Central African Republic',
[Country.Chad]: 'Chad',
[Country.Chile]: 'Chile',
[Country.China]: 'China',
[Country.ChristmasIsland]: 'Christmas Island',
[Country.CocosIslands]: 'Cocos (Keeling) Islands',
[Country.Colombia]: 'Colombia',
[Country.Comoros]: 'Comoros',
[Country.Congo]: 'Congo',
[Country.DRCongo]: 'DR Congo',
[Country.CookIslands]: 'Cook Islands',
[Country.CostaRica]: 'Costa Rica',
[Country.CoteDIvoire]: `Cote D'Ivoire`,
[Country.Croatia]: 'Croatia',
[Country.Cuba]: 'Cuba',
[Country.Cyprus]: 'Cyprus',
[Country.CzechRepublic]: 'Czech Republic',
[Country.Denmark]: 'Denmark',
[Country.Djibouti]: 'Djibouti',
[Country.Dominica]: 'Dominica',
[Country.DominicanRepublic]: 'Dominican Republic',
[Country.Ecuador]: 'Ecuador',
[Country.Egypt]: 'Egypt',
[Country.ElSalvador]: 'El Salvador',
[Country.EquatorialGuinea]: 'Equatorial Guinea',
[Country.Eritrea]: 'Eritrea',
[Country.Estonia]: 'Estonia',
[Country.Ethiopia]: 'Ethiopia',
[Country.FalklandIslands]: 'Falkland Islands (Malvinas)',
[Country.FaroeIslands]: 'Faroe Islands',
[Country.Fiji]: 'Fiji',
[Country.Finland]: 'Finland',
[Country.France]: 'France',
[Country.FrenchGuiana]: 'French Guiana',
[Country.FrenchPolynesia]: 'French Polynesia',
[Country.FrenchSouthernTerritories]: 'French Southern Territories',
[Country.Gabon]: 'Gabon',
[Country.Gambia]: 'Gambia',
[Country.Georgia]: 'Georgia',
[Country.Germany]: 'Germany',
[Country.Ghana]: 'Ghana',
[Country.Gibraltar]: 'Gibraltar',
[Country.Greece]: 'Greece',
[Country.Greenland]: 'Greenland',
[Country.Grenada]: 'Grenada',
[Country.Guadeloupe]: 'Guadeloupe',
[Country.Guam]: 'Guam',
[Country.Guatemala]: 'Guatemala',
[Country.Guernsey]: 'Guernsey',
[Country.Guinea]: 'Guinea',
[Country.GuineaBissau]: 'Guinea Bissau',
[Country.Guyana]: 'Guyana',
[Country.Haiti]: 'Haiti',
[Country.HeardIslandandMcdonaldIslands]: 'Heard Island and Mcdonald Islands',
[Country.VaticanCity]: 'Vatican City',
[Country.Honduras]: 'Honduras',
[Country.HongKong]: 'Hong Kong',
[Country.Hungary]: 'Hungary',
[Country.Iceland]: 'Iceland',
[Country.India]: 'India',
[Country.Indonesia]: 'Indonesia',
[Country.Iran]: 'Iran',
[Country.Iraq]: 'Iraq',
[Country.Ireland]: 'Ireland',
[Country.IsleofMan]: 'Isle of Man',
[Country.Israel]: 'Israel',
[Country.Italy]: 'Italy',
[Country.Jamaica]: 'Jamaica',
[Country.Japan]: 'Japan',
[Country.Jersey]: 'Jersey',
[Country.Jordan]: 'Jordan',
[Country.Kenya]: 'Kenya',
[Country.Kazakhstan]: 'Kazakhstan',
[Country.Kiribati]: 'Kiribati',
[Country.NorthKorea]: 'North Korea',
[Country.SouthKorea]: 'South Korea',
[Country.Kosovo]: 'Kosovo',
[Country.Kuwait]: 'Kuwait',
[Country.Kyrgyzstan]: 'Kyrgyzstan',
[Country.Laos]: 'Laos',
[Country.Latvia]: 'Latvia',
[Country.Lebanon]: 'Lebanon',
[Country.Lesotho]: 'Lesotho',
[Country.Liberia]: 'Liberia',
[Country.Libya]: 'Libya',
[Country.Liechtenstein]: 'Liechtenstein',
[Country.Lithuania]: 'Lithuania',
[Country.Luxembourg]: 'Luxembourg',
[Country.Macau]: 'Macau',
[Country.NorthMacedonia]: 'North Macedonia',
[Country.Madagascar]: 'Madagascar',
[Country.Malawi]: 'Malawi',
[Country.Malaysia]: 'Malaysia',
[Country.Maldives]: 'Maldives',
[Country.Mali]: 'Mali',
[Country.Malta]: 'Malta',
[Country.MarshallIslands]: 'Marshall Islands',
[Country.Martinique]: 'Martinique',
[Country.Mauritania]: 'Mauritania',
[Country.Mauritius]: 'Mauritius',
[Country.Mayotte]: 'Mayotte',
[Country.Mexico]: 'Mexico',
[Country.Micronesia]: 'Micronesia',
[Country.Moldova]: 'Moldova',
[Country.Monaco]: 'Monaco',
[Country.Mongolia]: 'Mongolia',
[Country.Montenegro]: 'Montenegro',
[Country.Montserrat]: 'Montserrat',
[Country.Morocco]: 'Morocco',
[Country.Mozambique]: 'Mozambique',
[Country.Myanmar]: 'Myanmar (Burma)',
[Country.Namibia]: 'Namibia',
[Country.Nauru]: 'Nauru',
[Country.Nepal]: 'Nepal',
[Country.Netherlands]: 'Netherlands',
[Country.NetherlandsAntilles]: 'Netherlands Antilles',
[Country.NewCaledonia]: 'New Caledonia',
[Country.NewZealand]: 'New Zealand',
[Country.Nicaragua]: 'Nicaragua',
[Country.Niger]: 'Niger',
[Country.Nigeria]: 'Nigeria',
[Country.Niue]: 'Niue',
[Country.NorfolkIsland]: 'Norfolk Island',
[Country.NorthernMarianaIslands]: 'Northern Mariana Islands',
[Country.Norway]: 'Norway',
[Country.Oman]: 'Oman',
[Country.Pakistan]: 'Pakistan',
[Country.Palau]: 'Palau',
[Country.Palestine]: 'Palestine',
[Country.Panama]: 'Panama',
[Country.PapuaNewGuinea]: 'Papua New Guinea',
[Country.Paraguay]: 'Paraguay',
[Country.Peru]: 'Peru',
[Country.Philippines]: 'Philippines',
[Country.Pitcairn]: 'Pitcairn',
[Country.Poland]: 'Poland',
[Country.Portugal]: 'Portugal',
[Country.PuertoRico]: 'Puerto Rico',
[Country.Qatar]: 'Qatar',
[Country.Reunion]: 'Reunion',
[Country.Romania]: 'Romania',
[Country.Russia]: 'Russia',
[Country.Rwanda]: 'Rwanda',
[Country.SaintHelena]: 'Saint Helena',
[Country.SaintKittsAndNevis]: 'Saint Kitts and Nevis',
[Country.SaintLucia]: 'Saint Lucia',
[Country.SaintPierreAndMiquelon]: 'Saint Pierre and Miquelon',
[Country.SaintVincentAndTheGrenadines]: 'Saint Vincent and the Grenadines',
[Country.Samoa]: 'Samoa',
[Country.SanMarino]: 'San Marino',
[Country.SaoTomeAndPrincipe]: 'Sao Tome and Principe',
[Country.SaudiArabia]: 'Saudi Arabia',
[Country.Senegal]: 'Senegal',
[Country.Serbia]: 'Serbia',
[Country.Seychelles]: 'Seychelles',
[Country.SierraLeone]: 'Sierra Leone',
[Country.Singapore]: 'Singapore',
[Country.Slovakia]: 'Slovakia',
[Country.Slovenia]: 'Slovenia',
[Country.SolomonIslands]: 'Solomon Islands',
[Country.Somalia]: 'Somalia',
[Country.SouthAfrica]: 'South Africa',
[Country.SouthGeorgiaAndTheSouthSandwichIslands]: 'South Georgia and the South Sandwich Islands',
[Country.Spain]: 'Spain',
[Country.SriLanka]: 'Sri Lanka',
[Country.Sudan]: 'Sudan',
[Country.Suriname]: 'Suriname',
[Country.SvalbardAndJanMayen]: 'Svalbard and Jan Mayen',
[Country.Eswatini]: 'Eswatini (Swaziland)',
[Country.Sweden]: 'Sweden',
[Country.Switzerland]: 'Switzerland',
[Country.Syria]: 'Syria',
[Country.Taiwan]: 'Taiwan',
[Country.Tajikistan]: 'Tajikistan',
[Country.Tanzania]: 'Tanzania',
[Country.Thailand]: 'Thailand',
[Country.TimorLeste]: 'Timor-Leste (East Timor)',
[Country.Togo]: 'Togo',
[Country.Tokelau]: 'Tokelau',
[Country.Tonga]: 'Tonga',
[Country.TrinidadAndTobago]: 'Trinidad and Tobago',
[Country.Tunisia]: 'Tunisia',
[Country.Turkey]: 'Turkey',
[Country.Turkmenistan]: 'Turkmenistan',
[Country.TurksAndCaicosIslands]: 'Turks and Caicos Islands',
[Country.Tuvalu]: 'Tuvalu',
[Country.Uganda]: 'Uganda',
[Country.Ukraine]: 'Ukraine',
[Country.UnitedArabEmirates]: 'United Arab Emirates (UAE)',
[Country.UnitedKingdom]: 'United Kingdom',
[Country.UnitedStates]: 'United States',
[Country.UnitedStatesMinorOutlyingIslands]: 'United States Minor Outlying Islands',
[Country.Uruguay]: 'Uruguay',
[Country.Uzbekistan]: 'Uzbekistan',
[Country.Vanuatu]: 'Vanuatu',
[Country.Venezuela]: 'Venezuela',
[Country.Vietnam]: 'Vietnam',
[Country.BritishVirginIslands]: 'British Virgin Islands',
[Country.UnitedStatesVirginIslands]: 'United States Virgin Islands',
[Country.WallisAndFutuna]: 'Wallis and Futuna',
[Country.WesternSahara]: 'Western Sahara',
[Country.Yemen]: 'Yemen',
[Country.Zambia]: 'Zambia',
[Country.Zimbabwe]: 'Zimbabwe',
[Country.SintEustatius]: 'Sint Eustatius (BQ)',
[Country.Curacao]: 'Curacao',
[Country.SaintBarthelemy]: 'Saint Barthelemy',
[Country.SaintMartinDutch]: 'Saint Martin (Dutch)',
[Country.SaintMartinFrenchpart]: 'Saint Martin (French part)',
[Country.SouthSudan]: 'South Sudan',
[Country.Bonaire]: 'Bonaire',
[Country.Saba]: 'Saba',
[Country.SintEustatiusSE]: 'Sint Eustatius (BQ-SE)',
[Country.SintMaarten]: 'Sint Maarten',
};

export const CountryNamesWithNotSelected = {
  [Country.NotSelected]: 'Not Selected',
  ...CountryNames,
};

export const CounrtyDropdownData = [
  {
    id: `${Country.NotSelected}`,
    value: `${Country.NotSelected}`,
    label: CountryNamesWithNotSelected[Country.NotSelected]
  },
  ...enumToDropDown(CountryNames)
    .sort((a, b) => a.label.localeCompare(b.label))
];
