export const TranslationKeys = {
  ERROR_MESSAGE_TOURNAMENT_ADD_TITLE: 'ERROR_MESSAGE_TOURNAMENT_ADD_TITLE',
  ERROR_MESSAGE_TOURNAMENT_UPDATE_TITLE: 'ERROR_MESSAGE_TOURNAMENT_UPDATE_TITLE',
  OPERATION_FAILED: 'OPERATION_FAILED',
  SUCCESS_MESSAGE_GAME_CONFIG_TITLE: 'SUCCESS_MESSAGE_GAME_CONFIG_TITLE',
  SUCCESS_MESSAGE_GAME_GAME_TYPE_TITLE: 'SUCCESS_MESSAGE_GAME_GAME_TYPE_TITLE',
  SUCCESS_MESSAGE_GAME_GAME_TYPE_UPDATED_TITLE: 'SUCCESS_MESSAGE_GAME_GAME_TYPE_UPDATED_TITLE',
  SUCCESS_MESSAGE_TOURNAMENT_ADD_TITLE: 'SUCCESS_MESSAGE_TOURNAMENT_ADD_TITLE',
  SUCCESS_MESSAGE_TOURNAMENT_UPDATE_TITLE: 'SUCCESS_MESSAGE_TOURNAMENT_UPDATE_TITLE',
  SUCCESS_MESSAGE_MAINTENANCES_UPDATED_TITLE: 'SUCCESS_MESSAGE_MAINTENANCES_UPDATED_TITLE',
  SUCCESS_MESSAGE_MAINTENANCES_BY_ID_EDITED_TITLE: 'SUCCESS_MESSAGE_MAINTENANCES_BY_ID_EDITED_TITLE',
  SUCCESS_MESSAGE_STATIC_CONTENT_EDITED_TITLE: 'SUCCESS_MESSAGE_STATIC_CONTENT_EDITED_TITLE',
  SUCCESS_MESSAGE_STATIC_CONTEST_ADD_TITLE: 'SUCCESS_MESSAGE_STATIC_CONTEST_ADD_TITLE',
  SUCCESS_MESSAGE_FREE_BETS_CANCELED_TITLE: 'SUCCESS_MESSAGE_FREE_BETS_CANCELED_TITLE',
  SUCCESS_MESSAGE_TOURNAMENT_CANCELED_TITLE: 'SUCCESS_MESSAGE_TOURNAMENT_CANCELED_TITLE',
  SUCCESS_MESSAGE_PARTNER_GAME_TYPE_LIMIT_EDITED_TITLE: 'SUCCESS_MESSAGE_PARTNER_GAME_TYPE_LIMIT_EDITED_TITLE',
  SUCCESS_MESSAGE_OPERATION_HAS_COMPLETED_SUCCESSFULLY_TITLE: 'SUCCESS_MESSAGE_OPERATION_HAS_COMPLETED_SUCCESSFULLY_TITLE',
  SUCCESS_NEW_PLATFORM_ADDED_TITLE: 'SUCCESS_NEW_PLATFORM_ADDED_TITLE',
  SUCCESS_MESSAGE_YOU_GOT_NOTIFICATIONS_TITLE: 'SUCCESS_MESSAGE_YOU_GOT_NOTIFICATIONS_TITLE',
  SUCCESS_MESSAGE_YOU_HAVE_DELETED_STATIC_CONTENT_TITLE: 'SUCCESS_MESSAGE_YOU_HAVE_DELETED_STATIC_CONTENT_TITLE',
  SUCCESS_MESSAGE_NEW_FREE_BETS_ADDED_TITLE: 'SUCCESS_MESSAGE_NEW_FREE_BETS_ADDED_TITLE',
  SUCCESS_MESSAGE_FREE_BETS_EDITED_TITLE: 'SUCCESS_MESSAGE_FREE_BETS_EDITED_TITLE',
  SUCCESS: 'SUCCESS',
  OPERATION_HAS_COMPLETED_SUCCESSFULLY: 'OPERATION_HAS_COMPLETED_SUCCESSFULLY',
  ARE_YOU_SURE_YOU_WANT_DEACTIVATE: 'ARE_YOU_SURE_YOU_WANT_DEACTIVATE',
  ARE_YOU_SURE_YOU_WANT_ACTIVATE: 'ARE_YOU_SURE_YOU_WANT_ACTIVATE',
  ARE_YOU_SURE_YOU_TO_ARCHIVE_PARTNER: 'ARE_YOU_SURE_YOU_TO_ARCHIVE_PARTNER',
  GAME_CONFIGURATION: 'GAME_CONFIGURATION',
  DEMO_VERSION: 'DEMO_VERSION',
  VIEW_MODE: 'VIEW_MODE',
  SMALL: 'SMALL',
  BIG: 'BIG',
  NORMAL: 'NORMAL',
  LARGE: 'LARGE',
  SAVE: 'SAVE',
  RESET: 'RESET',
  CANCEL: 'CANCEL',
  PLATFORM: 'PLATFORM',
  INACTIVE: 'INACTIVE',
  ACTIVE: 'ACTIVE',
  DEACTIVATE: 'DEACTIVATE',
  ACTIVATE: 'ACTIVATE',
  PLATFORM_PARTNERS: 'PLATFORM_PARTNERS',
  DEACTIVATE_PARTNER: 'DEACTIVATE_PARTNER',
  ACTIVATE_PARTNER: 'ACTIVATE_PARTNER',
  DEACTIVATE_PLATFORM: 'DEACTIVATE_PLATFORM',
  ACTIVATE_PLATFORM: 'ACTIVATE_PLATFORM',
  ARCHIVE_PARTNER: 'ARCHIVE_PARTNER',
  ARCHIVE_PLATFORM: 'ARCHIVE_PLATFORM',
  NAME: 'NAME',
  UPLOAD_EXCEL_FILE: 'UPLOAD_EXCEL_FILE',
  UPLOAD_EXCEL_FILE_VALIDATION: 'UPLOAD_EXCEL_FILE_VALIDATION',
  ADD_CONTEST_TYPE: 'ADD_CONTEST_TYPE',
  ADD_NEW: 'ADD_NEW',
  GAME: 'GAME',
  EDIT_CONTEST_TYPE: 'EDIT_CONTEST_TYPE',
  WRONG: 'WRONG',
  BET: 'BET',
  CONTEST_TYPE_ID: 'CONTEST_TYPE_ID',
  BET_AMOUNT: 'BET_AMOUNT',
  USER_TYPE: 'USER_TYPE',
  USER_ID: 'USER_ID',
  PLAYER_EXTERNAL_ID: 'PLAYER_EXTERNAL_ID',
  BET_ID: 'BET_ID',
  SELECT_PLATFORM: 'SELECT_PLATFORM',
  PLS_SELECT_PARTNERS: 'PLS_SELECT_PARTNERS',
  BET_CYCLE: 'BET_CYCLE',
  USER_EXTERNAL_ID: 'USER_EXTERNAL_ID',
  ON: 'ON',
  OFF: 'OFF',
  CASH_OUT: 'CASH_OUT',
  AUTO_CASH_OUT: 'AUTO_CASH_OUT',
  BET_REPORT: 'BET_REPORT',
  PLAYER_ID: 'PLAYER_ID',
  ROUND_ID: 'ROUND_ID',
  PARTNER: 'PARTNER',
  DEVICE: 'DEVICE',
  DASHBOARD: 'DASHBOARD',
  REPORTS: 'REPORTS',
  ADMIN: 'ADMIN',
  PLATFORM_SETTINGS: 'PLATFORM_SETTINGS',
  PARTNERS_SETTINGS: 'PARTNERS_SETTINGS',
  PLATFORMS_SETTINGS: 'PLATFORMS_SETTINGS',
  CONTEST_TYPES: 'CONTEST_TYPES',
  BOG: 'BOG',
  BET_REPORTS: 'BET_REPORTS',
  ARCADE: 'ARCADE',
  UNDER_MAINTENANCE: 'UNDER_MAINTENANCE',
  RESULT_CARD: 'RESULT_CARD',
  OUTCOME: 'OUTCOME',
  OR: 'OR',
  DEAL: 'DEAL',
  BET_TYPE: 'BET_TYPE',
  BET_DATE: 'BET_DATE',
  GAME_RESULT: 'GAME_RESULT',
  WIN_AMOUNT: 'WIN_AMOUNT',
  BET_RESULT: 'BET_RESULT',
  BUY_BONUS: 'BUY_BONUS',
  BONUS_WIN: 'BONUS_WIN',
  TOTAL_WIN: 'TOTAL_WIN',
  NEW_MAINTENANCES_ADDED: 'NEW_MAINTENANCES_ADDED',
  ADD_NEW_MAINTENANCES_FAILED: 'ADD_NEW_MAINTENANCES_FAILED',
  ADD_MAINTENANCE: 'ADD_MAINTENANCE',
  NOTIFY_SECONDS: 'NOTIFY_SECONDS',
  GAMES: 'GAMES',
  REASON: 'REASON',
  COMMENT: 'COMMENT',
  STATUS: 'STATUS',
  DO_YOU_WANT_TO_CANCEL_MAINTENANCE: 'DO_YOU_WANT_TO_CANCEL_MAINTENANCE',
  MAINTENANCE_ID: 'MAINTENANCE_ID',
  NOTE: 'NOTE',
  START_DATE: 'START_DATE',
  END_DATE: 'END_DATE',
  CONFIRM: 'CONFIRM',
  DELETE_INFORMATION: 'DELETE_INFORMATION',
  DO_YOU_WANT_TO_DELETE_SELECTED_CONTENT: 'DO_YOU_WANT_TO_DELETE_SELECTED_CONTENT',
  ADD_FRAGMENT: 'ADD_FRAGMENT',
  EDIT: 'EDIT',
  CONTENT: 'CONTENT',
  PRIORITY: 'PRIORITY',
  TITLE: 'TITLE',
  FIELD_IS_REQUIRED: 'FIELD_IS_REQUIRED',
  LANGUAGE_CODE: 'LANGUAGE_CODE',
  SEARCH: 'SEARCH',
  LANGUAGE: 'LANGUAGE',
  STATIC_CONTENTS: 'STATIC_CONTENTS',
  ADD_STATIC_CONTENT: 'ADD_STATIC_CONTENT',
  WIN_TYPE: 'WIN_TYPE',
  CURRENCY: 'CURRENCY',
  BET_COUNT: 'BET_COUNT',
  YOU_CAN_ONLY_UPLOAD_EXCEL_FILE: 'YOU_CAN_ONLY_UPLOAD_EXCEL_FILE',
  TEMPORARY_USER_IDS: 'TEMPORARY_USER_IDS',
  APPLY: 'APPLY',
  DEFAULT_BET_COUNT: 'DEFAULT_BET_COUNT',
  DEFAULT_BET_AMOUNT: 'DEFAULT_BET_AMOUNT',
  WIN: 'WIN',
  WEB_ACTIVE_DATE: 'WEB_ACTIVE_DATE',
  DATEPICKER: 'DATEPICKER',
  ID: 'ID',
  FREE_BET_NAME: 'FREE_BET_NAME',
  PLAYER_COUNT: 'PLAYER_COUNT',
  TOTAL_AMOUNT: 'TOTAL_AMOUNT',
  FREE_BET_LIST: 'FREE_BET_LIST',
  ARE_YOU_SURE_TO_DELETE_INFO_CONNECTED_WITH: 'ARE_YOU_SURE_TO_DELETE_INFO_CONNECTED_WITH',
  DATE_PICKER: 'DATE_PICKER',
  DATE: 'DATE',
  ADD_TYPE: 'ADD_TYPE',
  TOTAL_BONUS: 'TOTAL_BONUS',
  BONUS: 'BONUS',
  MIN: 'MIN',
  MAX: 'MAX',
  STEP: 'STEP',
  COUNT: 'COUNT',
  ADD_STEP: 'ADD_STEP',
  ARE_YOU_SURE_TO_DELETE: 'ARE_YOU_SURE_TO_DELETE',
  ADD_RANGE: 'ADD_RANGE',
  PARTNER_CONFIGURATION: 'PARTNER_CONFIGURATION',
  RANGE: 'RANGE',
  PARTNER_ID: 'PARTNER_ID',
  TOURNAMENT_ID: 'TOURNAMENT_ID',
  PARTNER_NAME: 'PARTNER_NAME',
  MASTER_NAME: 'MASTER_NAME',
  BOSUN_ISLAND: 'BOSUN_ISLAND',
  CANCEL_INFORMATION: 'CANCEL_INFORMATION',
  DO_YOU_WANT_TO_CANCEL_SELECTED_TOURNAMENT: 'DO_YOU_WANT_TO_CANCEL_SELECTED_TOURNAMENT',
  MASTER_LIST: 'MASTER_LIST',
  PLAYERS: 'PLAYERS',
  PLAYERS_BY_WINS: 'PLAYERS_BY_WINS',
  RANGE_WIN_AMOUNT: 'RANGE_WIN_AMOUNT',
  PERSONAL_INFO: 'PERSONAL_INFO',
  TOTAL_BET: 'TOTAL_BET',
  GGR: 'GGR',
  CHART_REPORT: 'CHART_REPORT',
  CHART_DATA: 'CHART_DATA',
  TOTAL_BETS_COUNT: 'TOTAL_BETS_COUNT',
  TOTAL_WIN_COUNT: 'TOTAL_WIN_COUNT',
  NO_DATA_TO_DISPLAY: 'NO_DATA_TO_DISPLAY',
  TOTAL_WIN_COUNT_DESC: 'TOTAL_WIN_COUNT_DESC',
  TOTAL_LOSE_COUNT_DESC: 'TOTAL_LOSE_COUNT_DESC',
  CANCELED_BETS_COUNT_DESC: 'CANCELED_BETS_COUNT_DESC',
  PENDING_BETS_DESC: 'PENDING_BETS_DESC',
  GGR_DESC: 'GGR_DESC',
  GGR_IN_RATIO_DESC: 'GGR_IN_RATIO_DESC',
  TOTAL_WIN_AMOUNT_DESC: 'TOTAL_WIN_AMOUNT_DESC',
  CANCELED_BETS_AMOUNT_DESC: 'CANCELED_BETS_AMOUNT_DESC',
  ACTIVE_PARTNERS_DESC: 'ACTIVE_PARTNERS_DESC',
  NOT_ACTIVE_PARTNERS_DESC: 'NOT_ACTIVE_PARTNERS_DESC',
  CHURNED_PARTNERS_DESC: 'CHURNED_PARTNERS_DESC',
  CHURNED_PARTNERS_LAST_WEEK_DESC: 'CHURNED_PARTNERS_LAST_WEEK_DESC',
  CHURNED_PARTNERS_LAST_MONTH_DESC: 'CHURNED_PARTNERS_LAST_MONTH_DESC',
  TOTAL_PLAYERS_COUNT_DESC: 'TOTAL_PLAYERS_COUNT_DESC',
  TOTAL_LOSE_COUNT: 'TOTAL_LOSE_COUNT',
  CANCELED_BETS_COUNT: 'CANCELED_BETS_COUNT',
  PENDING_BETS: 'PENDING_BETS',
  TOTAL_WIN_AMOUNT: 'TOTAL_WIN_AMOUNT',
  CANCELED_BETS_AMOUNT: 'CANCELED_BETS_AMOUNT',
  TOTAL_LOSE_AMOUNT: 'TOTAL_LOSE_AMOUNT',
  TOTAL_PARTNERS_COUNT: 'TOTAL_PARTNERS_COUNT',
  NOT_ACTIVE_PARTNERS: 'NOT_ACTIVE_PARTNERS',
  ACTIVATE_PARTNERS: 'ACTIVATE_PARTNERS',
  TOTAL_PLAYERS_COUNT: 'TOTAL_PLAYERS_COUNT',
  DESKTOP: 'DESKTOP',
  MOBILE: 'MOBILE',
  PERIOD: 'PERIOD',
  LOSS_AMOUNT: 'LOSS_AMOUNT',
  LOSE_BETS: 'LOSE_BETS',
  WON_BETS: 'WON_BETS',
  ERROR: 'ERROR',
  WRONG_USERNAME_OR_PASSWORD: 'WRONG_USERNAME_OR_PASSWORD',
  USERNAME: 'USERNAME',
  PASSWORD: 'PASSWORD',
  LOG_IN: 'LOG_IN',
  DURATION: 'DURATION',
  TRANSACTION_REPORT: 'TRANSACTION_REPORT',
  FREE_BET_REPORT: 'FREE_BET_REPORT',
  PARTNERS_COUNT: 'PARTNERS_COUNT',
  GAME_PERMISSION: 'GAME_PERMISSION',
  PLATFORMS: 'PLATFORMS',
  GAME_LINES: 'GAME_LINES',
  GAME_NAME: 'GAME_NAME',
  GAME_ID: 'GAME_ID',
  GAME_TYPES: 'GAME_TYPES',
  TEXT: 'TEXT',
  QUICK_BETS: 'QUICK_BETS',
  EDIT_ROUND_TYPES: 'EDIT_ROUND_TYPES',
  QUICK_BETS_SHOULD_BE_BETWEEN: 'QUICK_BETS_SHOULD_BE_BETWEEN',
  MAIN_LIMITS: 'MAIN_LIMITS',
  EDIT_PARTNER: 'EDIT_PARTNER',
  ROUND_TYPES: 'ROUND_TYPES',
  DATE_RANGE: 'DATE_RANGE',
  MAIN_CURRENCY: 'MAIN_CURRENCY',
  CREATION_DATE: 'CREATION_DATE',
  MODIFIED_DATE: 'MODIFIED_DATE',
  FROM: 'FROM',
  TO: 'TO',
  PROFIT: 'PROFIT',
  FINANCIAL_REPORT: 'FINANCIAL_REPORT',
  FREE_BET_ID: 'FREE_BET_ID',
  FREE_BET_COUNT: 'FREE_BET_COUNT',
  CLAIM_DATE: 'CLAIM_DATE',
  EXPIRATION_DATE: 'EXPIRATION_DATE',
  PLAYER_FREE_BETS: 'PLAYER_FREE_BETS',
  BETS_COUNT: 'BETS_COUNT',
  P_L: 'P_L',
  P_BETS_COUNT: 'P_BETS_COUNT',
  STAKE_AMOUNT: 'STAKE_AMOUNT',
  ROUND_REPORT: 'ROUND_REPORT',
  TRANSACTION_ID: 'TRANSACTION_ID',
  TYPE: 'TYPE',
  STATE: 'STATE',
  AMOUNT: 'AMOUNT',
  TRANSACTION_TYPE_SHORT: 'TRANSACTION_TYPE_SHORT',
  ACCEPTED_DATE: 'ACCEPTED_DATE',
  REQUEST_STATE: 'REQUEST_STATE',
  USER: 'USER',
  EXTERNAL_TRANSACTION_ID: 'EXTERNAL_TRANSACTION_ID',
  ERROR_DESCRIPTION: 'ERROR_DESCRIPTION',
  TRANSACTIONS: 'TRANSACTIONS',
  EXTERNAL_ID: 'EXTERNAL_ID',
  NO_PAGE: 'NO_PAGE',
  ADD_ROW: 'ADD_ROW',
  WIN_PLUS_BET: 'WIN_PLUS_BET',
  ADD_FREE_BET: 'ADD_FREE_BET',
  WEB_DELETE_DATE: 'WEB_DELETE_DATE',
  DELETE_ROW: 'DELETE_ROW',
  OK: 'OK',
  PREBONUS_RESULT: 'PREBONUS_RESULT',
  MULTIPLIER: 'MULTIPLIER',
  FREE_SPIN: 'FREE_SPIN',
  CRYSTAL_COLOR_YELLOW: 'CRYSTAL_COLOR_YELLOW',
  CRYSTAL_COLOR_GREEN: 'CRYSTAL_COLOR_GREEN',
  CRYSTAL_COLOR_RED: 'CRYSTAL_COLOR_RED',
  CRYSTAL_COLOR_BLUE: 'CRYSTAL_COLOR_BLUE',
  BONUS_RESULT: 'BONUS_RESULT',
  YELLOW: 'YELLOW',
  GREEN: 'GREEN',
  RED: 'RED',
  BLUE: 'BLUE',
  LOSE: 'LOSE',
  BET_HISTORY: 'BET_HISTORY',
  DECISION: 'DECISION',
  BUY_BONUS_AMOUNT: 'BUY_BONUS_AMOUNT',
  SELECT_ALL: 'SELECT_ALL',
  DEALER: 'DEALER',
  HIT: 'HIT',
  STAND: 'STAND',
  MAIN_HAND: 'MAIN_HAND',
  ODD: 'ODD',
  CURRENCY_CODE: 'CURRENCY_CODE',
  CATEGORY: 'CATEGORY',
  SELECTED_GAMES: 'SELECTED_GAMES',
  ADD_PARTNER: 'ADD_PARTNER',
  CONTINUE: 'CONTINUE',
  ADD_PLATFORM: 'ADD_PLATFORM',
  PLATFORM_NAME: 'PLATFORM_NAME',
  PLAYER: 'PLAYER',
  NOTIFICATIONS: 'NOTIFICATIONS',
  SEARCH_PARTNER: 'SEARCH_PARTNER',
  SEARCH_PLATFORM_OR_PARTNER: 'SEARCH_PLATFORM_OR_PARTNER',
  SETTINGS: 'SETTINGS',
  RESET_PASSWORD: 'RESET_PASSWORD',
  LOG_OUT: 'LOG_OUT',
  BONUS_ISLAND: 'BONUS_ISLAND',
  BONUS_ISLAND_LIST: 'BONUS_ISLAND_LIST',
  FREE_BET: 'FREE_BET',
  CREATE: 'CREATE',
  VIEW: 'VIEW',
  CLONE: 'CLONE',
  DO_YOU_WANT_TO_CANCEL: 'DO_YOU_WANT_TO_CANCEL',
  DELETE: 'DELETE',
  EXPORT: 'EXPORT',
  TOTAL_BET_AMOUNT: 'TOTAL_BET_AMOUNT',
  TOTAL_BUY_BONUS_COUNT: 'TOTAL_BUY_BONUS_COUNT',
  TOTAL_BUY_BONUS_AMOUNT: 'TOTAL_BUY_BONUS_AMOUNT',
  TOP_MARKET: 'TOP_MARKET',
  REGISTERED_DATE: 'REGISTERED_DATE',
  LAST_BET_DATE: 'LAST_BET_DATE',
  USER_REPORT: 'USER_REPORT',
  POINTS_SYSTEM: 'POINTS_SYSTEM',
  OPTIMISATION: 'OPTIMISATION',
  EDIT_MAINTENANCE: 'EDIT_MAINTENANCE',
  MAINTENANCE: 'MAINTENANCE',
  URL_PARAM: 'URL_PARAM',
  USERS_COUNT: 'USERS_COUNT',
  WON_BETS_COUNT: 'WON_BETS_COUNT',
  LOSE_BETS_COUNT: 'LOSE_BETS_COUNT',
  USERS_BET_COUNT: 'USERS_BET_COUNT',
  USERS_PERCENTAGE: 'USERS_PERCENTAGE',
  CONTEST_TYPE_NAME: 'CONTEST_TYPE_NAME',
  AVERAGE_BET: 'AVERAGE_BET',
  TRANSACTION_DETAILS: 'TRANSACTION_DETAILS',
  GGR_IN_RATIO: 'GGR_IN_RATIO',
  CHURNED_PARTNERS: 'CHURNED_PARTNERS',
  CHURNED_PARTNERS_LAST_WEEK: 'CHURNED_PARTNERS_LAST_WEEK',
  CHURNED_PARTNERS_LAST_MONTH: 'CHURNED_PARTNERS_LAST_MONTH',
  TOTAL_BETS_BY_DEVICE: 'TOTAL_BETS_BY_DEVICE',
  TODAY: 'TODAY',
  YESTERDAY: 'YESTERDAY',
  LAST_7_DAYS: 'LAST_7_DAYS',
  THIS_MONTH: 'THIS_MONTH',
  LAST_MONTH: 'LAST_MONTH',
  USER_BLOCKED_1: 'USER_BLOCKED_1',
  USER_BLOCKED_2: 'USER_BLOCKED_2',
  ORDER_N: 'ORDER_N',
  TRANSACTION_TYPE: 'TRANSACTION_TYPE',
  REQUEST_SENT_TIME: 'REQUEST_SENT_TIME',
  RESPONSE_TIME: 'RESPONSE_TIME',
  REQUEST_BODY: 'REQUEST_BODY',
  RESPONSE_BODY: 'RESPONSE_BODY',
  ACTIVE_PARTNERS: 'ACTIVE_PARTNERS',
  FILTERS: 'FILTERS',
  PRODUCTS: 'PRODUCTS',
  PROFILE: 'PROFILE',
  INVALID_PARTNER_ID: 'INVALID_PARTNER_ID',
  IS_ENABLED: 'IS_ENABLED',
  MIN_BET: 'MIN_BET',
  MAX_BET: 'MAX_BET',
  SIGN_IN: 'SIGN_IN',
  WELCOME: 'WELCOME',
  SIGN_IN_P1: 'SIGN_IN_P1',
  SIGN_IN_P2: 'SIGN_IN_P2',
  FORGOT_PASSWORD: 'FORGOT_PASSWORD',
  NEW_PASSWORD: 'NEW_PASSWORD',
  CONFIRM_PASSWORD: 'CONFIRM_PASSWORD',
  BACK_TO_SIGN_IN: 'BACK_TO_SIGN_IN',
  CHANGE_PASSWORD: 'CHANGE_PASSWORD',
  CHANGE_PASSWORD_DESC: 'CHANGE_PASSWORD_DESC',
  PASSWORD_RESET_SUCCESS: 'PASSWORD_RESET_SUCCESS',
  SELECTED_PARTNERS: 'SELECTED_PARTNERS',
  GAME_STATE: 'GAME_STATE',
  ALL: 'ALL',
  LIVE: 'LIVE',
  PRELIVE: 'PRELIVE',
  CLOSE: 'CLOSE',
  REFRESH: 'REFRESH',
  ADD_GAME_TYPE: 'ADD_GAME_TYPE',
  ADD_TO_PARTNER: 'ADD_TO_PARTNER',
  CHOOSE_CURRENCY: 'CHOOSE_CURRENCY',
  KEY: 'KEY',
  ADD: 'ADD',
  BACK: 'BACK',
  NOTIFICATION: 'NOTIFICATION',
  TURBO_MODE: 'TURBO_MODE',
  ENABLED: 'ENABLED',
  DISABLED: 'DISABLED',
  CURRENT_PASSWORD: 'CURRENT_PASSWORD',
  IS_DEFAULT: 'IS_DEFAULT',
  USERS_AND_PERMISSIONS: 'USERS_AND_PERMISSIONS',
  CREATE_DATE: 'CREATE_DATE',
  CREATOR: 'CREATOR',
  EMAIL_VERIFICATION: 'EMAIL_VERIFICATION',
  DEPARTMENT: 'DEPARTMENT',
  VERIFIED: 'VERIFIED',
  NOT_VERIFIED: 'NOT_VERIFIED',
  MANAGER: 'MANAGER',
  ADD_USER: 'ADD_USER',
  EDIT_USER: 'EDIT_USER',
  SURNAME: 'SURNAME',
  EMAIL: 'EMAIL',
  NEXT: 'NEXT',
  PERMISSIONS: 'PERMISSIONS',
  INCLUDED: 'INCLUDED',
  POSSIBLE: 'POSSIBLE',
  CURRENT: 'CURRENT',
  DROP_ELEMENT_HERE: 'DROP_ELEMENT_HERE',
  GROUP_MANAGER: 'GROUP_MANAGER',
  USER_CREATED_SUCCESSFULLY: 'USER_CREATED_SUCCESSFULLY',
  ACTIVATE_USER: 'ACTIVATE_USER',
  DEACTIVATE_USER: 'DEACTIVATE_USER',
  USER_ACTIVATED_SUCCESSFULLY: 'USER_ACTIVATED_SUCCESSFULLY',
  USER_DEACTIVATED_SUCCESSFULLY: 'USER_DEACTIVATED_SUCCESSFULLY',
  SOMETHING_WENT_WRONG: 'SOMETHING_WENT_WRONG',
  USER_EDITED_SUCCESSFULLY: 'USER_EDITED_SUCCESSFULLY',
  WRONG_EMAIL_FORMAT: 'WRONG_EMAIL_FORMAT',
  WRONG_PASSOWRD_FORMAT_ADD_USER: 'WRONG_PASSOWRD_FORMAT_ADD_USER',
  CUSTOMIZE_KPIS: 'CUSTOMIZE_KPIS',
  USER_HAS_NO_PERMISSION: 'USER_HAS_NO_PERMISSION',
  HIDDEN: 'HIDDEN',
  KPIS_CUSTOMIZED_SUCCESSFULLY: 'KPIS_CUSTOMIZED_SUCCESSFULLY',
  TABLE_GAMES: 'TABLE_GAMES',
  MARK_ALL_AS_READ: 'MARK_ALL_AS_READ',
  DEFAULT: 'DEFAULT',
  NOT_DEFAULT: 'NOT_DEFAULT',
  REPLACE_THE_EXISTING: 'REPLACE_THE_EXISTING',
  ARCHIVED: 'ARCHIVED',
  UNARCHIVED: 'UNARCHIVED',
  ARCHIVE_STATUS: 'ARCHIVE_STATUS',
  RESTORE_PASSWORD: 'RESTORE_PASSWORD',
  RESTORE_PASSWORD_DESC: 'RESTORE_PASSWORD_DESC',
  SEND_RESET_LINK: 'SEND_RESET_LINK',
  RESET_LINK_SENT: 'RESET_LINK_SENT',
  RESET_LINK_SENT_DESC: 'RESET_LINK_SENT_DESC',
  SET_NEW_PASSWORD: 'SET_NEW_PASSWORD',
  YOUR_PASSWORD_HAS_EXPIRED_DESC: 'YOUR_PASSWORD_HAS_EXPIRED_DESC',
  CONFIRM_PASSWORD_DOES_NOT_MATCH: 'CONFIRM_PASSWORD_DOES_NOT_MATCH',
  RESEND: 'RESEND',
  RESEND_DESC: 'RESEND_DESC',
  RESTORATION_LINK_EXPIRED: 'RESTORATION_LINK_EXPIRED',
  SEND_EMAIL_VERIFICATION: 'SEND_EMAIL_VERIFICATION',
  ARE_YOU_SURE_TO_SEND_EMAIL_VERIFICATION_REQUEST: 'ARE_YOU_SURE_TO_SEND_EMAIL_VERIFICATION_REQUEST',
  EDIT_PLATFORM: 'EDIT_PLATFORM',
  OKAY: 'OKAY',
  BET_DETAILS: 'BET_DETAILS',
  MAIN_BET: 'MAIN_BET',
  SIDE_BET_21_PLUS_3: 'SIDE_BET_21_PLUS_3',
  SIDE_BET_PERFECT_PAIR: 'SIDE_BET_PERFECT_PAIR',
  PERFECT: 'PERFECT',
  COLORED: 'COLORED',
  RED_BLACK: 'RED_BLACK',
  SUIT_THREE_OF_A_KIND: 'SUIT_THREE_OF_A_KIND',
  STRAIGHT_FLUSH: 'STRAIGHT_FLUSH',
  THREE_OF_A_KIND: 'THREE_OF_A_KIND',
  FLUSH: 'FLUSH',
  BLACKJACK: 'BLACKJACK',
  SURRENDER: 'SURRENDER',
  PUSH: 'PUSH',
  DOUBLE: 'DOUBLE',
  SPLIT: 'SPLIT',
  HAND: 'HAND',
  INSURANCE: 'INSURANCE',
  DEALER_POINTS: 'DEALER_POINTS',
  PLAYER_POINTS: 'PLAYER_POINTS',
  FILE_EXAMPLE: 'FILE_EXAMPLE',
  RESULT_POINT: 'RESULT_POINT',
  EMAIL_CONFRIMATION_LINK_EXPIRED: 'EMAIL_CONFRIMATION_LINK_EXPIRED',
  EMAIL_CONFRIMATION_LINK_EXPIRED_DESC: 'EMAIL_CONFRIMATION_LINK_EXPIRED_DESC',
  EMAIL_CONFIRMED_SUCCESSFULLY: 'EMAIL_CONFIRMED_SUCCESSFULLY',
  CANCELED: 'CANCELED',
  CHART_INFO_DESC: 'CHART_INFO_DESC',
  TRANSLATION_MANAGER: 'TRANSLATION_MANAGER',
  ALIAS: 'ALIAS',
  LABEL: 'LABEL',
  IMPORT: 'IMPORT',
  ADD_TRANSLATION: 'ADD_TRANSLATION',
  MANAGE_GROUPS: 'MANAGE_GROUPS',
  EDIT_TRANSLATION: 'EDIT_TRANSLATION',
  TRANSLATION: 'TRANSLATION',
  TRANSLATIONS_DEFAULT_DESC: 'TRANSLATIONS_DEFAULT_DESC',
  ADD_TO_GROUP: 'ADD_TO_GROUP',
  REMOVE_FROM_GROUP: 'REMOVE_FROM_GROUP',
  DELETE_TRANSLATION: 'DELETE_TRANSLATION',
  YES: 'YES',
  ARE_YOU_SURE_TO_DELETE_THIS_TRANSLATION: 'ARE_YOU_SURE_TO_DELETE_THIS_TRANSLATION',
  ARE_YOU_SURE_TO_REMOVE_THIS_TRANSLATION_FROM_GROUP: 'ARE_YOU_SURE_TO_REMOVE_THIS_TRANSLATION_FROM_GROUP',
  SELECT_GROUP: 'SELECT_GROUP',
  IMPORT_EXCEL: 'IMPORT_EXCEL',
  UPLOAD: 'UPLOAD',
  CHOOSE_FILE_XLS: 'CHOOSE_FILE_XLS',
  BROWSE: 'BROWSE',
  ADD_GROUP: 'ADD_GROUP',
  EDIT_GROUP: 'EDIT_GROUP',
  DELETE_GROUP: 'DELETE_GROUP',
  GROUP_NAME: 'GROUP_NAME',
  ARE_YOU_SURE_TO_DELETE_THIS_GROUP: 'ARE_YOU_SURE_TO_DELETE_THIS_GROUP',
  APPLICATION: 'APPLICATION',
  VIEW_ALIAS: 'VIEW_ALIAS',
  OTHER: 'OTHER',
  PARTNER_INTERNAL_ID: 'PARTNER_INTERNAL_ID',
  USER_APPLICATIONS: 'USER_APPLICATIONS',
  APPLICATIONS_UPDATED_SUCCESSFULLY: 'APPLICATIONS_UPDATED_SUCCESSFULLY',
  ADD_APPLICATIONS_TO_USER_DESC: 'ADD_APPLICATIONS_TO_USER_DESC',
  BETTING_TERMS: 'BETTING_TERMS',
  AWARDING_TYPE: 'AWARDING_TYPE',
  GAME_LINE: 'GAME_LINE',
  MIN_ODD: 'MIN_ODD',
  MAX_ODD: 'MAX_ODD',
  BET_QUANTITY: 'BET_QUANTITY',
  MAX_PAYOUT: 'MAX_PAYOUT',
  TOTAL: 'TOTAL',
  START_END_DATE: 'START_END_DATE',
  FREEBET_TEMPLATE_ADDED_SUCCESSFULLY: 'FREEBET_TEMPLATE_ADDED_SUCCESSFULLY',
  FREEBET_TEMPLATE_EDITED_SUCCESSFULLY: 'FREEBET_TEMPLATE_EDITED_SUCCESSFULLY',
  DO_YOU_WANT_TO_DEACTIVATE: 'DO_YOU_WANT_TO_DEACTIVATE',
  FREE_BET_AMOUNT: 'FREE_BET_AMOUNT',
  FREE_BET_QUANTITY: 'FREE_BET_QUANTITY',
  LAST_UPDATE_DATE: 'LAST_UPDATE_DATE',
  DEACTIVATE_FREE_BET: 'DEACTIVATE_FREE_BET',
  QUANTITY_OF_PLAYERS: 'QUANTITY_OF_PLAYERS',
  AWARD_THE_PLAYER: 'AWARD_THE_PLAYER',
  AWARD_PLAYERS_ERROR_TEXT: 'AWARD_PLAYERS_ERROR_TEXT',
  DOWNLOAD: 'DOWNLOAD',
  PROVIDED_FREE_BET_QUANTITY: 'PROVIDED_FREE_BET_QUANTITY',
  USED_FREE_BET_QUANTITY: 'USED_FREE_BET_QUANTITY',
  PROVIDED_BET_AMOUNT: 'PROVIDED_BET_AMOUNT',
  USED_BET_AMOUNT: 'USED_BET_AMOUNT',
  PLAYER_FREE_BET_ID: 'PLAYER_FREE_BET_ID',
  PLATFORM_ID: 'PLATFORM_ID',
  TOTAL_FREE_BET_AMOUNT: 'TOTAL_FREE_BET_AMOUNT',
  PENDING: 'PENDING',
  EXPIRED: 'EXPIRED',
  ACCEPTED: 'ACCEPTED',
  FINISHED: 'FINISHED',
  FAILED: 'FAILED',
  UNKNOWN: 'UNKNOWN',
  DATE_AND_TIME: 'DATE_AND_TIME',
  VIEW_FREE_BET_STATUS_HISTORY: 'VIEW_FREE_BET_STATUS_HISTORY',
  LANGUAGE_ADDED_SUCCESSFULLY: 'LANGUAGE_ADDED_SUCCESSFULLY',
  LANGUAGE_EDITED_SUCCESSFULLY: 'LANGUAGE_EDITED_SUCCESSFULLY',
  LANGUAGE_DELETED_SUCCESSFULLY: 'LANGUAGE_DELETED_SUCCESSFULLY',
  MANAGE_LANGUAGES: 'MANAGE_LANGUAGES',
  APPLICATION_ID: 'APPLICATION_ID',
  LANGUAGES: 'LANGUAGES',
  ADD_APPLICATION: 'ADD_APPLICATION',
  ADD_LANGUAGE: 'ADD_LANGUAGE',
  APPLICATIONS: 'APPLICATIONS',
  ISO_1: 'ISO_1',
  ISO_2: 'ISO_2',
  EXAMPLE: 'EXAMPLE',
  EDIT_LANGUAGE: 'EDIT_LANGUAGE',
  DELETE_LANGUAGE: 'DELETE_LANGUAGE',
  APPLICATION_NAME: 'APPLICATION_NAME',
  EDIT_APPLICATION: 'EDIT_APPLICATION',
  APPLICATION_EDITED_SUCCESSFULLY: 'APPLICATION_EDITED_SUCCESSFULLY',
  APPLICATION_ADDED_SUCCESSFULLY: 'APPLICATION_ADDED_SUCCESSFULLY',
  APPLICATION_DELETED_SUCCESSFULLY: 'APPLICATION_DELETED_SUCCESSFULLY',
  ARE_YOU_SURE_YOU_WANT_TO_DELETE_THIS_LANGUAGE: 'ARE_YOU_SURE_YOU_WANT_TO_DELETE_THIS_LANGUAGE',
  EDIT_FREE_BET: 'EDIT_FREE_BET',
  VIEW_FREE_BET: 'VIEW_FREE_BET',
  WINNER_NUMBER: 'WINNER_NUMBER',
  RESULT: 'RESULT',
  ITEM_NOT_FOUND: 'ITEM_NOT_FOUND',
  WITH_BETS: 'WITH_BETS',
  WITHOUT_BETS: 'WITHOUT_BETS',
  GAME_LIMITS: 'GAME_LIMITS',
  PARTNER_INFO: 'PARTNER_INFO',
  GENERAL_INFO: 'GENERAL_INFO',
  ACTIVE_PARTNER: 'ACTIVE_PARTNER',
  INACTIVE_PARTNER: 'INACTIVE_PARTNER',
  LAST_MODIFICATION_DATE: 'LAST_MODIFICATION_DATE',
  PARTNER_EDITED_SUCCESSFULLY: 'PARTNER_EDITED_SUCCESSFULLY',
  MAIN_CONFIGS: 'MAIN_CONFIGS',
  GAME_CONFIGS: 'GAME_CONFIGS',
  SKIP_LOBBY: 'SKIP_LOBBY',
  WINTER_MODE: 'WINTER_MODE',
  EDIT_GAME_TYPE: 'EDIT_GAME_TYPE',
  ADD_QUICK_BET: 'ADD_QUICK_BET',
  UNARCHIVE_PARTNER: 'UNARCHIVE_PARTNER',
  ARE_YOU_SURE_YOU_WANT_TO_UNARCHIVE_PARTNER: 'ARE_YOU_SURE_YOU_WANT_TO_UNARCHIVE_PARTNER',
  IS_TEST_PARTNER: 'IS_TEST_PARTNER',
  FREE_BET_MAX_COUNT_EXCEEDED: 'FREE_BET_MAX_COUNT_EXCEEDED',
  PARTNER_EXTERNAL_ID: 'PARTNER_EXTERNAL_ID',
  BET_ON_GAMES: 'BET_ON_GAMES',
  BET_ON_LOTTERY: 'BET_ON_LOTTERY',
  SPORT_FUN: 'SPORT_FUN',
  SLOT: 'SLOT',
  DELETE_APPLICATION: 'DELETE_APPLICATION',
  ARE_YOU_SURE_YOU_WANT_TO_DELETE_THIS_APPLICATION: 'ARE_YOU_SURE_YOU_WANT_TO_DELETE_THIS_APPLICATION',
  GAME_TYPE: 'GAME_TYPE',
  MULTIPLIER_ODD: 'MULTIPLIER_ODD',
  MULTIPLIER_CANDY: 'MULTIPLIER_CANDY',
  WHEEL: 'WHEEL',
  PINK: 'PINK',
  ROUND_RESULT: 'ROUND_RESULT',
  CANDY: 'CANDY',
  CASHOUT_DATE: 'CASHOUT_DATE',
  CRUSH_DATE: 'CRUSH_DATE',
  BOOST_ODD: 'BOOST_ODD',
  BONUS_GAME: 'BONUS_GAME',
  NO: 'NO',
  FREE_SPIN_COUNT: 'FREE_SPIN_COUNT',
  USED_FREE_SPIN_COUNT: 'USED_FREE_SPIN_COUNT',
  AQUA: 'AQUA',
  CORAL: 'CORAL',
  ORANGE: 'ORANGE',
  MULTI: 'MULTI',
  VIOLET: 'VIOLET',
  MARKET: 'MARKET',
  BONUS_WHEEL_ONE: 'BONUS_WHEEL_ONE',
  BONUS_WHEEL_TWO: 'BONUS_WHEEL_TWO',
  MAIN_INFO: 'MAIN_INFO',
  LIMITS: 'LIMITS',
  PLATFORM_AND_PARTNERS: 'PLATFORM_AND_PARTNERS',
  CONTEST_TYPE_EDITED_SUCCESSFULLY: 'CONTEST_TYPE_EDITED_SUCCESSFULLY',
  TYPE_NAME: 'TYPE_NAME',
  TYPE_ID: 'TYPE_ID',
  PARTNER_COUNT: 'PARTNER_COUNT',
  DEACTIVE: 'DEACTIVE',
  MAX_WIN: 'MAX_WIN',
  ADD_CURRENCY: 'ADD_CURRENCY',
  UPLOAD_LIMIT: 'UPLOAD_LIMIT',
  CHANGE_ALL_PARTNERS_LIMITS: 'CHANGE_ALL_PARTNERS_LIMITS',
  SUCCESS_MESSAGE_GAME_TYPE_LIMIT_EDITED: 'SUCCESS_MESSAGE_GAME_TYPE_LIMIT_EDITED',
  SUCCESS_MESSAGE_GAME_TYPE_LIMIT_ADDED: 'SUCCESS_MESSAGE_GAME_TYPE_LIMIT_ADDED',
  DEACTIVATE_GAME_TYPE: 'DEACTIVATE_GAME_TYPE',
  ACTIVATE_GAME_TYPE: 'ACTIVATE_GAME_TYPE',
  DETAILS: 'DETAILS',
  EXPAND: 'EXPAND',
  DEFAULT_TYPE: 'DEFAULT_TYPE',
  BANKER: 'BANKER',
  TIE: 'TIE',
  ROUND_DETAILS: 'ROUND_DETAILS',
  INFO: 'INFO',
  NO_DATA_TO_DISPLAY_PENDING_BET: 'NO_DATA_TO_DISPLAY_PENDING_BET',
  IN_PROCESS: 'IN_PROCESS',
  WEB_ACTIVE: 'WEB_ACTIVE',
  STOPPED: 'STOPPED',
  NOT_STARTED: 'NOT_STARTED',
  ROLLBACK: 'ROLLBACK',
  DELAYED: 'DELAYED',
  REAL: 'REAL',
  TEST: 'TEST',
  EXPORT_ERROR: 'EXPORT_ERROR',
  REAL_MONEY: 'REAL_MONEY',
  LEFT_WHEEL: 'LEFT_WHEEL',
  RIGHT_WHEEL: 'RIGHT_WHEEL',
  BLACK: 'BLACK',
  WILD: 'WILD',
  MIN_BET_AMOUNT: 'MIN_BET_AMOUNT',
  MAX_BET_AMOUNT: 'MAX_BET_AMOUNT',
  MAX_WIN_AMOUNT: 'MAX_WIN_AMOUNT',
  ADDITIONAL_LIMITS: 'ADDITIONAL_LIMITS',
  ADDITIONAL_LIMITS_SHOULD_BE_BETWEEN: 'ADDITIONAL_LIMITS_SHOULD_BE_BETWEEN',
  REMOVE_ADDITIONAL_LIMITS: 'REMOVE_ADDITIONAL_LIMITS',
  VIEW_CHART: 'VIEW_CHART',
  DONE: 'DONE',
  CASHOUT_ODD: 'CASHOUT_ODD',
  ROUND_STEP: 'ROUND_STEP',
  BONUS_ROUND: 'BONUS_ROUND',
  RESULT_MULTIPLIER: 'RESULT_MULTIPLIER',
  RESULT_ODD: 'RESULT_ODD',
  COMPLETE: 'COMPLETE',
  WHEEL_OPENING_TYPE: 'WHEEL_OPENING_TYPE',
  MANUAL: 'MANUAL',
  AUTO: 'AUTO',
  BET_SHOP: 'BET_SHOP',
  CALCULATION: 'CALCULATION',
  ONCANCEL: 'ONCANCEL',
  WEB: 'WEB',
  HOURLY: 'HOURLY',
  DAILY: 'DAILY',
  WEEKLY: 'WEEKLY',
  MONTHLY: 'MONTHLY',
  REGULAR_BET: 'REGULAR_BET',
  FOR_BET: 'FOR_BET',
  FOR_WIN: 'FOR_WIN',
  FOR_LOSE: 'FOR_LOSE',
  PLAYER_REPORT: 'PLAYER_REPORT',
  GUID: 'GUID',
  AT_LEAST_SYMBOLS: 'AT_LEAST_SYMBOLS',
  AT_LEAST_NUMBERS: 'AT_LEAST_NUMBERS',
  AT_LEAST_UPPERCASE: 'AT_LEAST_UPPERCASE',
  AT_LEAST_LOWERCASE: 'AT_LEAST_LOWERCASE',
  AT_LEAST_SPECIAL_SYMBOLS: 'AT_LEAST_SPECIAL_SYMBOLS',
  WRONG_USERNAME_FORMAT: 'WRONG_USERNAME_FORMAT',
  FREE_BET_STATE_CHANGES: 'FREE_BET_STATE_CHANGES',
  STRAIGHT: 'STRAIGHT',
  ADD_RICH_CONTENT: 'ADD_RICH_CONTENT',
  SIMPLE_CONTENT: 'SIMPLE_CONTENT',
  RICH_CONTENT: 'RICH_CONTENT',
  ADD_RICH_CONTENT_FOR: 'ADD_RICH_CONTENT_FOR',
  EDIT_RICH_CONTENT_FOR: 'EDIT_RICH_CONTENT_FOR',
  PLAYER_PARTNER_ID: 'PLAYER_PARTNER_ID',
  PLAYER_PLATFORM_ID: 'PLAYER_PLATFORM_ID',
  CHART_DATE_RANGE_ERROR: 'CHART_DATE_RANGE_ERROR',
  MONTHS: 'MONTHS',
  DAYS: 'DAYS',
  WEEKS: 'WEEKS',
  HOURS: 'HOURS',
  MORE_INFO: 'MORE_INFO',
  VISUALIZATION: 'VISUALIZATION',
  RICH_CONTENT_ADDED_SUCCESSFULLY: 'RICH_CONTENT_ADDED_SUCCESSFULLY',
  RICH_CONTENT_EDITED_SUCCESSFULLY: 'RICH_CONTENT_EDITED_SUCCESSFULLY',
  URL: 'URL',
  WHITE_LIST_ADDED_SUCCESSFULLY: 'WHITE_LIST_ADDED_SUCCESSFULLY',
  WHITE_LIST_EDITED_SUCCESSFULLY: 'WHITE_LIST_EDITED_SUCCESSFULLY',
  WHITE_LIST_DELETED_SUCCESSFULLY: 'WHITE_LIST_DELETED_SUCCESSFULLY',
  DO_YOU_WANT_TO_DELETE_WHITE_LIST: 'DO_YOU_WANT_TO_DELETE_WHITE_LIST',
  TICKET_NUMBER: 'TICKET_NUMBER',
  NONE: 'NONE',
  ONE_LINE: 'ONE_LINE',
  TWO_LINE: 'TWO_LINE',
  THREE_LINE: 'THREE_LINE',
  TULUM: 'TULUM',
  ONE_LINE_IN_5_DRAWS: 'ONE_LINE_IN_5_DRAWS',
  ONE_LINE_IN_15_DRAWS: 'ONE_LINE_IN_15_DRAWS',
  BET_ON_TICKETS: 'BET_ON_TICKETS',
  BET_ON_COLORS: 'BET_ON_COLORS',
  BET_ON_LINES: 'BET_ON_LINES',
  OCEAN: 'OCEAN',
  PURPLE: 'PURPLE',
  MIX: 'MIX',
  TICKET_COLOR: 'TICKET_COLOR',
  ADD_TEMPLATE: 'ADD_TEMPLATE',
  GENERAL_DETAILS: 'GENERAL_DETAILS',
  TEMPLATE_NAME: 'TEMPLATE_NAME',
  UPLOAD_IMAGE: 'UPLOAD_IMAGE',
  DRAG_AND_DROP_A_IMAGE: 'DRAG_AND_DROP_A_IMAGE',
  TEMPLATE_ID: 'TEMPLATE_ID',
  UPLOAD_IMAGE_SIZE_LIMITS: 'UPLOAD_IMAGE_SIZE_LIMITS',
  UPLOAD_IMAGE_DESC: 'UPLOAD_IMAGE_DESC',
  WINNING_COMBINATION: 'WINNING_COMBINATION',
  TIERCE: 'TIERCE',
  FIFTY: 'FIFTY',
  HUNDRED: 'HUNDRED',
  FOURCARDS: 'FOURCARDS',
  TIERCE_TIERCE: 'TIERCE_TIERCE',
  TIERCE_FIFTY: 'TIERCE_FIFTY',
  TIERCE_HUNDRED: 'TIERCE_HUNDRED',
  TIERCE_FOURCARDS: 'TIERCE_FOURCARDS',
  FIFTY_FIFTY: 'FIFTY_FIFTY',
  FIFTY_FOURCARDS: 'FIFTY_FOURCARDS',
  FOURCARDS_FOURCARDS: 'FOURCARDS_FOURCARDS',
  FEATURES: 'FEATURES',
  PG_WEBSITE_LINK: 'PG_WEBSITE_LINK',
  THUMBNAIL: "THUMBNAIL",
  STATIC_CONFIGS: "STATIC_CONFIGS",
  DEMO: "DEMO",
  SUCCESS_MESSAGE_GAME_GENERAL_INFO_UPDATED_TITLE: "SUCCESS_MESSAGE_GAME_GENERAL_INFO_UPDATED_TITLE",
  SUCCESS_MESSAGE_GAME_CONFIG_UPDATED_TITLE: 'SUCCESS_MESSAGE_GAME_CONFIG_UPDATED_TITLE',
  DESCRIPTION: 'DESCRIPTION',
  MATRIX_TYPE: 'MATRIX_TYPE',
  PLAYING: 'PLAYING',
  GAME_TYPE_NAME: 'GAME_TYPE_NAME',
  IS_TURBO: 'IS_TURBO',
  ACTIVE_GAME_TYPES: 'ACTIVE_GAME_TYPES',
  SUCCESS_MESSAGE_GAME_PLATFORM_PARTNERS_UPDATED_TITLE: "SUCCESS_MESSAGE_GAME_PLATFORM_PARTNERS_UPDATED_TITLE",
  FEATURE_DISABLED_DESC: "FEATURE_DISABLED_DESC",
  DYNAMIC_CONFIG: 'DYNAMIC_CONFIG',
  MAIN_BET_ID: 'MAIN_BET_ID',
  AUTO_PLAY: 'AUTO_PLAY',
  SOURCE_TYPE: 'SOURCE_TYPE',
  CASHIER: 'CASHIER',
  TERMINAL: 'TERMINAL',
  HOME: 'HOME',
  AWAY: 'AWAY',
  DRAW: 'DRAW',
  DIFFERENT: 'DIFFERENT',
  EXPORT_LIMITS: 'EXPORT_LIMITS',
  WINNING_NUMBERS: 'WINNING_NUMBERS',
  WINNING_TICKETS: 'WINNING_TICKETS',
  LINES: 'LINES',
  TICKET_NUMBERS: 'TICKET_NUMBERS',
  BASE_BET_AMOUNT: 'BASE_BET_AMOUNT',
  INCREASING_ODD_ON_WIN: 'INCREASING_ODD_ON_WIN',
  INCREASING_ODD_ON_LOSE: 'INCREASING_ODD_ON_LOSE',
  STOP_IF_LOSS: 'STOP_IF_LOSS',
  REPLACE_PARTNER_TRANSLATIONS: 'REPLACE_PARTNER_TRANSLATIONS',
  PREVIOUS_RESULT: 'PREVIOUS_RESULT',
  BETTED_ROUND_ID: 'BETTED_ROUND_ID',
  COLUMN: 'COLUMN',
  SHOW_RELATED_BETS: 'SHOW_RELATED_BETS',
  HAS_THEME: 'HAS_THEME',
  STANDARD: 'STANDARD',
  WINTER: 'WINTER',
  HALLOWEEN: 'HALLOWEEN',
  FOOTBALL: 'FOOTBALL',
  VALENTINE: 'VALENTINE',
  THEMES: 'THEMES',
  GENERAL: 'GENERAL',
  GAME_GENERAL_CONFIGS: 'GAME_GENERAL_CONFIGS',
  GAME_SPECIFIC_CONFIGS: 'GAME_SPECIFIC_CONFIGS',
  WAGERING_MIN_ODD: 'WAGERING_MIN_ODD',
  PLATFORM_EDITED_SUCCESSFULLY: 'PLATFORM_EDITED_SUCCESSFULLY',
  PLATFORM_INFO: 'PLATFORM_INFO',
  IS_ARCHIVED: 'IS_ARCHIVED',
  BOMB_COUNT: 'BOMB_COUNT',
  IS_BONUS: 'IS_BONUS',
  GAME_LOGO: 'GAME_LOGO',
  JACKS_OR_BETTER: 'JACKS_OR_BETTER',
  TOW_PAIR: 'TOW_PAIR',
  FULL_HOUSE: 'FULL_HOUSE',
  FULL_OR_KIND: 'FULL_OR_KIND',
  ROYAL_FLUSH: 'ROYAL_FLUSH',
  INITIAL_CARDS: 'INITIAL_CARDS',
  FINAL_CARDS: 'FINAL_CARDS',
  FREE_BET_FAILED: 'FREE_BET_FAILED',
  HELD: 'HELD',
  ARE_YOU_SURE_YOU_WANT_ARCHIVE: 'ARE_YOU_SURE_YOU_WANT_ARCHIVE',
  UNARCHIVE_PLATFORM: 'UNARCHIVE_PLATFORM',
  ARE_YOU_SURE_YOU_WANT_UNARCHIVE: 'ARE_YOU_SURE_YOU_WANT_UNARCHIVE',
  INPUT_VALUE_MUST_BE_POSITIVE: 'INPUT_VALUE_MUST_BE_POSITIVE',
  AUTH_TYPE: 'AUTH_TYPE',
  COOKIE: 'COOKIE',
  BEARER: 'BEARER',
  VIRTUAL_GAMES: 'VIRTUAL_GAMES',
  QUANTITY: 'QUANTITY',
  DRUM: 'DRUM',
  SHIELD: 'SHIELD',
  CABIN: 'CABIN',
  MASK: 'MASK',
  SEVEN: 'SEVEN',
  CROWN: 'CROWN',
  WINNING_SYMBOLS: 'WINNING_SYMBOLS',
  TOTAL_ODD: 'TOTAL_ODD',
  WIDGET: 'WIDGET',
  LIST: 'LIST',
  WON_BET_AMOUNT: 'WON_BET_AMOUNT',
  CANCELED_BET_AMOUNT: 'CANCELED_BET_AMOUNT',
  TOTAL_BETS_DESKTOP: 'TOTAL_BETS_DESKTOP',
  TOTAL_BETS_MOBILE: 'TOTAL_BETS_MOBILE',
  DASHBOARD_REPORT: 'DASHBOARD_REPORT',
  TICKETS: 'TICKETS',
  HAS_REAL_MODE_SWITCHER: 'HAS_REAL_MODE_SWITCHER',
  CURRENCY_LIST: 'CURRENCY_LIST',
  CURRENCY_RATE: 'CURRENCY_RATE',
  CURRENCY_NAME: 'CURRENCY_NAME',
  SYMBOL: 'SYMBOL',
  PRECISION: 'PRECISION',
  RATE: 'RATE',
  RESET_FILTERS: 'RESET_FILTERS',
  MAX_IMAGE_UPLOAD_SIZE_MUST_BE: 'MAX_IMAGE_UPLOAD_SIZE_MUST_BE',
  DISPLAY_NAME: 'DISPLAY_NAME',
  LIMITS_DEFAULT_BET_AMOUNT_MUST_BE: 'LIMITS_DEFAULT_BET_AMOUNT_MUST_BE',
  UPLOAD_FLAG: 'UPLOAD_FLAG',
  FLAG: 'FLAG',
  INTERNAL_ID: 'INTERNAL_ID',
  GAMBLED_GAMES: 'GAMBLED_GAMES',
  GAMBLE_ODD: 'GAMBLE_ODD',
  UPLOAD_LIMITS: 'UPLOAD_LIMITS',
  PARTNERS: 'PARTNERS',
  SUCCESS_MESSAGE_UPDATE_PLATFORM_GAME_LIMITS: 'SUCCESS_MESSAGE_UPDATE_PLATFORM_GAME_LIMITS',
  GAME_TYPE_ID: 'GAME_TYPE_ID',
  GAMBLED_COUNT: 'GAMBLED_COUNT',
  OVER: 'OVER',
  UNDER: 'UNDER',
  HIGH: 'HIGH',
  MEDIUM: 'MEDIUM',
  LOW: 'LOW',
  MAGENTA: 'MAGENTA',
  WHITE: 'WHITE',
  GRAY: 'GRAY',
  RISK: 'RISK',
  SECTOR: 'SECTOR',
  CLASSIC: 'CLASSIC',
  SELECTED_NUMBERS: 'SELECTED_NUMBERS',
  DRAW_NUMBERS: 'DRAW_NUMBERS',
  PLAYERS_SELECTION: 'PLAYERS_SELECTION',
  MAX_FLAG_IMAGE_UPLOAD_SIZE_MUST_BE: 'MAX_FLAG_IMAGE_UPLOAD_SIZE_MUST_BE',
  JACKPOT: 'JACKPOT',
  FREE_SPINS: 'FREE_SPINS',
  BONUS_TYPE: 'BONUS_TYPE',
  MONEY_TYPE: 'MONEY_TYPE',
  SPIN_RESULT: 'SPIN_RESULT',
  COLLECT: 'COLLECT',
  GUARANTEED_WIN: 'GUARANTEED_WIN',
  ANDAR: 'ANDAR',
  BAHAR: 'BAHAR',
  JOKER: 'JOKER',
  DEALT_CARDS_COUNT: 'DEALT_CARDS_COUNT',
  JOKER_CARD: 'JOKER_CARD',
  LIGHTNING_ODD: 'LIGHTNING_ODD',
  CARDS: 'CARDS',
  IS_AUTOBET: 'IS_AUTOBET',
  QUICK_DATE_RANGE: 'QUICK_DATE_RANGE',
  CASHOUT_TYPE: 'CASHOUT_TYPE',
  CASHOUT_FEATURE: 'CASHOUT_FEATURE',
  STEPS: 'STEPS',
  DOORS: 'DOORS',
  DOOR: 'DOOR',
  SELECT_LIMITS_FOR_PARTNERS_MESSAGE: 'SELECT_LIMITS_FOR_PARTNERS_MESSAGE',
  JACKPOT_ID: 'JACKPOT_ID',
  JACKPOT_NAME: 'JACKPOT_NAME',
  ACTIVE_GAME_COUNT: 'ACTIVE_GAME_COUNT',
  CLUB_JACKPOT_CURRENT_AMOUNT: 'CLUB_JACKPOT_CURRENT_AMOUNT',
  DIAMOND_JACKPOT_CURRENT_AMOUNT: 'DIAMOND_JACKPOT_CURRENT_AMOUNT',
  HEART_JACKPOT_CURRENT_AMOUNT: 'HEART_JACKPOT_CURRENT_AMOUNT',
  SPADE_JACKPOT_CURRENT_AMOUNT: 'SPADE_JACKPOT_CURRENT_AMOUNT',
  JACKPOT_LIST: 'JACKPOT_LIST',
  ADD_JACKPOT: 'ADD_JACKPOT',
  PLAYER_JACKPOT_REPORT: 'PLAYER_JACKPOT_REPORT',
  CURRENT_JACKPOT_AMOUNT: 'CURRENT_JACKPOT_AMOUNT',
  AFTER_WIN_JACKPOT_AMOUNT: 'AFTER_WIN_JACKPOT_AMOUNT',
  JACKPOT_SYMBOL: 'JACKPOT_SYMBOL',
  JACKPOT_CONFIGS: 'JACKPOT_CONFIGS',
  IMPLEMENT_ON_GAMES: 'IMPLEMENT_ON_GAMES',
  JACKPOT_EDITED_SUCCESSFULLY: 'JACKPOT_EDITED_SUCCESSFULLY',
  JACKPOT_ADDED_SUCCESSFULLY: 'JACKPOT_ADDED_SUCCESSFULLY',
  ACCUMULATION: 'ACCUMULATION',
  CARD_SUIT_REFILL_AMOUNT: 'CARD_SUIT_REFILL_AMOUNT',
  REFUND_JACKPOT_AMOUNT: 'REFUND_JACKPOT_AMOUNT',
  CARD_SUIT_LIMITS: 'CARD_SUIT_LIMITS',
  CARD_SUIT_ACCUMULATION: 'CARD_SUIT_ACCUMULATION',
  CLUB: 'CLUB',
  DIAMOND: 'DIAMOND',
  HEART: 'HEART',
  SPADE: 'SPADE',
  MIN_AMOUNT: 'MIN_AMOUNT',
  MAX_AMOUNT: 'MAX_AMOUNT',
  JACKPOT_SYMBOL_REPORT: 'JACKPOT_SYMBOL_REPORT',
  SYMBOL_NAME: 'SYMBOL_NAME',
  WIN_FREQUENCY: 'WIN_FREQUENCY',
  EDIT_JACKPOT: 'EDIT_JACKPOT',
  VIEW_JACKPOT: 'VIEW_JACKPOT',
  TRUE: 'TRUE',
  LINE: 'LINE',
  PROMO: 'PROMO',
  TOTAL_TICKETS_COUNT: 'TOTAL_TICKETS_COUNT',
  TICKET_ID: 'TICKET_ID',
  FREE_SPIN_TOTAL_ODD: 'FREE_SPIN_TOTAL_ODD',
  ONLINE: 'ONLINE',
  IS_TERMINAL: 'IS_TERMINAL',
  JANUARY: 'JANUARY',
  FEBRUARY: 'FEBRUARY',
  MARCH: 'MARCH',
  APRIL: 'APRIL',
  MAY: 'MAY',
  JUNE: 'JUNE',
  JULY: 'JULY',
  AUGUST: 'AUGUST',
  SEPTEMBER: 'SEPTEMBER',
  OCTOBER: 'OCTOBER',
  NOVEMBER: 'NOVEMBER',
  DECEMBER: 'DECEMBER',
  PROMO_LIST: 'PROMO_LIST',
  PLAYER_PROMO_REPORT: 'PLAYER_PROMO_REPORT',
  DELETE_PROMO_TEMPLATE: 'DELETE_PROMO_TEMPLATE',
  DO_YOU_WANT_TO_DELETE_PROMO_TEMPLATE: 'DO_YOU_WANT_TO_DELETE_PROMO_TEMPLATE',
  PROMO_TEMPLATES_DELETED_SUCCESSFULLY: 'PROMO_TEMPLATES_DELETED_SUCCESSFULLY',
  ADD_PROMO_TEMPLATE: 'ADD_PROMO_TEMPLATE',
  EDIT_PROMO_TEMPLATE: 'EDIT_PROMO_TEMPLATE',
  VIEW_PROMO_TEMPLATE: 'VIEW_PROMO_TEMPLATE',
  PROMO_TEMPLATE_EDITED_SUCCESSFULLY: 'PROMO_TEMPLATE_EDITED_SUCCESSFULLY',
  PROMO_TEMPLATE_ADDED_SUCCESSFULLY: 'PROMO_TEMPLATE_ADDED_SUCCESSFULLY',
  PROMO_CODE: 'PROMO_CODE',
  PROMO_LIMIT_COUNT: 'PROMO_LIMIT_COUNT',
  AVAILABLE_FOR_VERIFIED_PLAYERS_ONLY: 'AVAILABLE_FOR_VERIFIED_PLAYERS_ONLY',
  TERMS: 'TERMS',
  PROMO_ON_GAMES: 'PROMO_ON_GAMES',
  FREE_BET_TEMPLATE: 'FREE_BET_TEMPLATE',
  PROMO_NAME: 'PROMO_NAME',
  PLAYER_PROMO_ID: 'PLAYER_PROMO_ID',
  CLAIMED_FREE_BET_COUNT: 'CLAIMED_FREE_BET_COUNT',
  CLAIMED_DATE: 'CLAIMED_DATE',
  PROMO_ID: 'PROMO_ID',
  IS_CANCELED: 'IS_CANCELED',
  PLATFORM_INTERNAL_ID: 'PLATFORM_INTERNAL_ID',
  EXPORT_FOR_ALL_LANGUAGES: 'EXPORT_FOR_ALL_LANGUAGES',
  EXPORT_FOR_SELECTED_LANGUAGE: 'EXPORT_FOR_SELECTED_LANGUAGE',
  SCORE: 'SCORE',
  TRADING_CARD: 'TRADING_CARD',
  CHANGED_CARD: 'CHANGED_CARD',
  TRADES: 'TRADES',
  BUSTED: 'BUSTED',
  BUY: 'BUY',
  SELL: 'SELL',
  TOTAL_TRADES_AMOUNT: 'TOTAL_TRADES_AMOUNT',
  MAIN: 'MAIN',
  BUY_CARD: 'BUY_CARD',
  SELL_CARD: 'SELL_CARD',
  BET_CALCULATED_DATE: 'BET_CALCULATED_DATE',
  IS_BONUS_BET: 'IS_BONUS_BET',
  SYSTEM: 'SYSTEM',
  MATCH_NAME: 'MATCH_NAME',
  MARKET_NAME: 'MARKET_NAME',
  CURRENT_SCORE: 'CURRENT_SCORE',
  MARKET_SELECTION: 'MARKET_SELECTION',
  MATCH_RESULT: 'MATCH_RESULT',
  OPTIONAL: 'OPTIONAL',
  ROUND_DURATION: 'ROUND_DURATION',
  IS_FREE_BET: 'IS_FREE_BET',
  IS_LIVE: 'IS_LIVE',
  BETSHOP: 'BETSHOP',
  MULTIPLE: 'MULTIPLE',
  COMBINATION: 'COMBINATION',
  SUPER_ADMIN: 'SUPER_ADMIN',
  SINGLE: 'SINGLE',
  FREE_BET_TYPE: 'FREE_BET_TYPE',
  USED_FREE_BET_AMOUNT: 'USED_FREE_BET_AMOUNT',
  TOTAL_UNIQUE_PLAYERS_COUNT: 'TOTAL_UNIQUE_PLAYERS_COUNT',
  FREE_BET_MONEY_TYPE: 'FREE_BET_MONEY_TYPE',
  IS_DAILY: 'IS_DAILY',
  WAGER_AMOUNT: 'WAGER_AMOUNT',
  MARKETS_REPORT: 'MARKETS_REPORT',
  CRYPTO_GAMES: 'CRYPTO_GAMES',
  SPORT_NAME: 'SPORT_NAME',
  MARKET_TYPE_NAME: 'MARKET_TYPE_NAME',
  AVERAGE_BET_AMOUNT: 'AVERAGE_BET_AMOUNT',
  SEQUENCE: 'SEQUENCE',
  HANDICAP: 'HANDICAP',
  MATCH_ID: 'MATCH_ID',
  CASHOUTED: 'CASHOUTED',
  WIN_RETURN: 'WIN_RETURN',
  LOSE_RETURN: 'LOSE_RETURN',
  RETURN: 'RETURN',
  JACKPOT_RESULT: 'JACKPOT_RESULT',
  JACKPOT_TYPE: 'JACKPOT_TYPE',
  TOURNAMENTS: 'TOURNAMENTS',
  ADD_TOURNAMENT: 'ADD_TOURNAMENT',
  PG_TOURNAMENTS: 'PG_TOURNAMENTS',
  TOURNAMENT_NAME: 'TOURNAMENT_NAME',
  WIN_CRITERIA: 'WIN_CRITERIA',
  ENTRY_TYPE: 'ENTRY_TYPE',
  PRIZE_POOL_TYPE: 'PRIZE_POOL_TYPE',
  POOL: 'POOL',
  MIN_PLAYER_COUNT: 'MIN_PLAYER_COUNT',
  TOURNAMENT_REPORT: 'TOURNAMENT_REPORT',
  EDIT_TOURNAMENT: 'EDIT_TOURNAMENT',
  VIEW_TOURNAMENT: 'VIEW_TOURNAMENT',
  DURATION_TYPE: 'DURATION_TYPE',
  SINGLE_BUY_IN: 'SINGLE_BUY_IN',
  REBUY: 'REBUY',
  FREE_ROLL: 'FREE_ROLL',
  FREE_ROLL_WITH_ENTRYFEE: 'FREE_ROLL_WITH_ENTRYFEE',
  FREE_WEEKEND: 'FREE_WEEKEND',
  MONDAY_TO_FRIDAY: 'MONDAY_TO_FRIDAY',
  WIN_COUNT: 'WIN_COUNT',
  LOSS_COUNT: 'LOSS_COUNT',
  IMAGES: 'IMAGES',
  UPLOAD_THUMBNAIL: 'UPLOAD_THUMBNAIL',
  UPLOAD_BANNER: 'UPLOAD_BANNER',
  FIX_AMOUNT: 'FIX_AMOUNT',
  ACCUMULATIVE: 'ACCUMULATIVE',
  ACCUMULATION_CRITERIA: 'ACCUMULATION_CRITERIA',
  PRICE: 'PRICE',
  PRICE_BRAKEDOWN: 'PRICE_BRAKEDOWN',
  BUY_IN: 'BUY_IN',
  BONUS_MONEY: 'BONUS_MONEY',
  FUND_TYPE: 'FUND_TYPE',
  FUND: 'FUND',
  UPCOMING: 'UPCOMING',
  PG_TOURNAMENT: 'PG_TOURNAMENT',
  PG_COIN: 'PG_COIN',
  FREE_ENTRY: 'FREE_ENTRY',
  PG_TOURNAMENT_EDITED_SUCCESSFULLY: 'PG_TOURNAMENT_EDITED_SUCCESSFULLY',
  PG_TOURNAMENT_ADDED_SUCCESSFULLY: 'PG_TOURNAMENT_ADDED_SUCCESSFULLY',
  SPECIAL: 'SPECIAL',
  FREE_BET_SOURCE: 'FREE_BET_SOURCE',
  INTERNAL: 'INTERNAL',
  EXTERNAL: 'EXTERNAL',
  BIGGEST_ODD: 'BIGGEST_ODD',
  DO_YOU_WANT_TO_CANCEL_TOURNAMENT: 'DO_YOU_WANT_TO_CANCEL_TOURNAMENT',
  TOURNAMENT_CANCELED_SUCCESSFULLY: 'TOURNAMENT_CANCELED_SUCCESSFULLY',
  CANCEL_TOURNAMENT: 'CANCEL_TOURNAMENT',
  COLLECTED_POOL: 'COLLECTED_POOL',
  SPIN_COUNT: 'SPIN_COUNT',
  ONGOING: 'ONGOING',
  CLOSED: 'CLOSED',
  REPORT_BY_GAME: 'REPORT_BY_GAME',
  REPORT_BY_PLAYER: 'REPORT_BY_PLAYER',
  PLACE: 'PLACE',
  REBUY_COUNT: 'REBUY_COUNT',
  PRIZE_SHARE: 'PRIZE_SHARE',
  PRIZE_AMOUNT: 'PRIZE_AMOUNT',
  COLLECTED_POINTS: 'COLLECTED_POINTS',
  FROM_LOSS: 'FROM_LOSS',
  FROM_BET: 'FROM_BET',
  HAS_TOURNAMENT: 'HAS_TOURNAMENT',
  PARTNER_COMPARISON_REPORT: 'PARTNER_COMPARISON_REPORT',
  TURNOVER: 'TURNOVER',
  DAYS_5: 'DAYS_5',
  MONTHS_3: 'MONTHS_3',
  DATA_TYPE: 'DATA_TYPE',
  V_MONEY: 'V_MONEY',
  BALANCE: 'BALANCE',
  MIN_STAKE_AMOUNT: 'MIN_STAKE_AMOUNT',
  BOARD_CARDS: 'BOARD_CARDS',
  WINNING_BOX: 'WINNING_BOX',
  RED_CARDS: 'RED_CARDS',
  SPADES: 'SPADES',
  HEARTS: 'HEARTS',
  CLUBS: 'CLUBS',
  FOUR_OF_KIND: 'FOUR_OF_KIND',
  TWO_PAIRS: 'TWO_PAIRS',
  ONE_PAIR: 'ONE_PAIR',
  HIGH_CARD: 'HIGH_CARD',
  BOX: 'BOX',
  DIAMONDS: 'DIAMONDS',
  WIN_MARKETS: 'WIN_MARKETS',
  BOX_CARDS: 'BOX_CARDS',
  ROUND_POOL: 'ROUND_POOL',
  MARKET_TYPE: 'MARKET_TYPE',
  INITIAL: 'INITIAL',
  PRE_FLOP: 'PRE_FLOP',
  PRE_TURN: 'PRE_TURN',
  PRE_RIVER: 'PRE_RIVER',
  ROUND_TOTAL_BET: 'ROUND_TOTAL_BET',
  ROUND_TOTAL_WIN: 'ROUND_TOTAL_WIN',
  EXTRA_BETS: 'EXTRA_BETS',
  START_CARD: 'START_CARD',
  SAME: 'SAME',
  EXTRA_MARKET: 'EXTRA_MARKET',
  MORE_ODD_NUMBERS: 'MORE_ODD_NUMBERS',
  MORE_EVEN_NUMBERS: 'MORE_EVEN_NUMBERS',
  EQUAL_ODD_AND_EVEN_NUMBERS: 'EQUAL_ODD_AND_EVEN_NUMBERS',
  MORE_IN_FIRST_40_NUMBERS: 'MORE_IN_FIRST_40_NUMBERS',
  MORE_IN_LAST_40_NUMBERS: 'MORE_IN_LAST_40_NUMBERS',
  DISTRIBUTED_EQUALLY_BETWEEN_REGIONS: 'DISTRIBUTED_EQUALLY_BETWEEN_REGIONS',
  MORE_HOT_NUMBERS: 'MORE_HOT_NUMBERS',
  MORE_COLD_NUMBERS: 'MORE_COLD_NUMBERS',
  EQUAL_HOT_AND_COLD_NUMBERS: 'EQUAL_HOT_AND_COLD_NUMBERS',
  LAST_NUMBER_IS_ODD: 'LAST_NUMBER_IS_ODD',
  LAST_NUMBER_IS_EVENT: 'LAST_NUMBER_IS_EVENT',
  MORE_ODD_NUMBERS_IN_FIRST_FIVE: 'MORE_ODD_NUMBERS_IN_FIRST_FIVE',
  MORE_EVEN_NUMBERS_IN_FIRST_FIVE: 'MORE_EVEN_NUMBERS_IN_FIRST_FIVE',
  API_VERSION: 'API_VERSION',
  JACKPOT_PENDING: 'JACKPOT_PENDING',
  GAMBLE_PENDING: 'GAMBLE_PENDING',
  DELETE_ALL: 'DELETE_ALL',
  DELETE_TRANSLATIONS: 'DELETE_TRANSLATIONS',
  ARE_YOU_SURE_TO_DELETE_TRANSLATIONS: 'ARE_YOU_SURE_TO_DELETE_TRANSLATIONS',
  DELETE_PARTNER_TRANSLATIONS: 'DELETE_PARTNER_TRANSLATIONS',
  CASHBACK: 'CASHBACK',
  PROMO_BONUS: 'PROMO_BONUS',
  LAST_1_HOUR: 'LAST_1_HOUR',
  LAST_6_HOURS: 'LAST_6_HOURS',
  EDIT_QUICK_DATE_RANGE_FILTERS: 'EDIT_QUICK_DATE_RANGE_FILTERS',
  MAX_5_QUICK_DATE_RANGES: 'MAX_5_QUICK_DATE_RANGES',
  ADD_OPTION: 'ADD_OPTION',
  SELECT: 'SELECT',
  BUY_IN_CONDITIONS: 'BUY_IN_CONDITIONS',
  FREE_BET_IDS: 'FREE_BET_IDS',
  CUSTOMIZED_SUCCESSFULLY: 'CUSTOMIZED_SUCCESSFULLY',
  NUMBER_OF_MINES: 'NUMBER_OF_MINES',
  AUTOBET: 'AUTOBET',
  PLAYERS_DECISIONS: 'PLAYERS_DECISIONS',
  TIME_ZONE: 'TIME_ZONE',
  INVOICE_EXPORT: 'INVOICE_EXPORT',
  EXPORT_DATA: 'EXPORT_DATA',
  FINANCIAL_EXPORT_GENERATED: 'FINANCIAL_EXPORT_GENERATED',
  LOADING: 'LOADING',
  EXPORTING: 'EXPORTING',
  NOWRUZ: 'NOWRUZ',
  INSTANT: 'INSTANT',
  TORNADO_ODD: 'TORNADO_ODD',
  ROWS: 'ROWS',
  BALLS: 'BALLS',
  REBUY_AMOUNT: 'REBUY_AMOUNT',
  JOIN_COUNT: 'JOIN_COUNT',
  JOIN_AMOUNT: 'JOIN_AMOUNT',
  ADD_CASHBACK: 'ADD_CASHBACK',
  EDIT_CASHBACK: 'EDIT_CASHBACK',
  VIEW_CASHBACK: 'VIEW_CASHBACK',
  TURNOVER_GOAL: 'TURNOVER_GOAL',
  CASHBACK_PERCENTAGE: 'CASHBACK_PERCENTAGE',
  CASHBACK_ID: 'CASHBACK_ID',
  COMPLETED: 'COMPLETED',
  PG_CASHBACK_EDITED_SUCCESSFULLY: 'PG_CASHBACK_EDITED_SUCCESSFULLY',
  PG_CASHBACK_ADDED_SUCCESSFULLY: 'PG_CASHBACK_ADDED_SUCCESSFULLY',
  PG_CASHBACK_CANCELED_SUCCESSFULLY: 'PG_CASHBACK_CANCELED_SUCCESSFULLY',
  DO_YOU_WANT_TO_CANCEL_CASHBACK: 'DO_YOU_WANT_TO_CANCEL_CASHBACK',
  CASHBACK_NAME: 'CASHBACK_NAME',
  GAMBLE_TURNOVER_THRESHOLD: 'GAMBLE_TURNOVER_THRESHOLD',
  GAMES_COUNT: 'GAMES_COUNT',
  LOCATION: 'LOCATION',
  PLAYER_IP: 'PLAYER_IP',
  SESSION_COUNT: 'SESSION_COUNT',
  SESSION_DURATION: 'SESSION_DURATION',
  ACCOUNT_STATUS: 'ACCOUNT_STATUS',
  BLOCK: 'BLOCK',
  BLOCKED: 'BLOCKED',
  PLAYER_BET_DETAILS: 'PLAYER_BET_DETAILS',
  GENERATED_GGR: 'GENERATED_GGR',
  BLOCK_PLAYER: 'BLOCK_PLAYER',
  PLAYER_INTERNAL_ID: 'PLAYER_INTERNAL_ID',
  ID_ADDRESS: 'ID_ADDRESS',
  ARE_YOU_SURE_TO_BLOCK_FOLLOWING_PLAYER: 'ARE_YOU_SURE_TO_BLOCK_FOLLOWING_PLAYER',
  BLOCK_UNTIL: 'BLOCK_UNTIL',
  ARE_YOU_SURE_TO_UNBLOCK_FOLLOWING_PLAYER: 'ARE_YOU_SURE_TO_UNBLOCK_FOLLOWING_PLAYER',
  ACTIVE_PLAYER: 'ACTIVE_PLAYER',
  SUCCESS_MESSAGE_USER_ACTIVATED_TITLE: 'SUCCESS_MESSAGE_USER_ACTIVATED_TITLE',
  SUCCESS_MESSAGE_USER_BLOCKED_TITLE: 'SUCCESS_MESSAGE_USER_BLOCKED_TITLE',
  CASHBACK_REPORT: 'CASHBACK_REPORT',
  PLAYERS_RECEIVED: 'PLAYERS_RECEIVED',
  TOTAL_COUNT_OF_GAMBLES: 'TOTAL_COUNT_OF_GAMBLES',
  CASHBACK_PLAYER_REPORT: 'CASHBACK_PLAYER_REPORT',
  CASHBACK_BET_AMOUNT: 'CASHBACK_BET_AMOUNT',
  COUNT_OF_GAMBLE_DECISIONS: 'COUNT_OF_GAMBLE_DECISIONS',
  ACCUMULATED_CASHBACK: 'ACCUMULATED_CASHBACK',
  PLAYER_REPORT_DETAILS: 'PLAYER_REPORT_DETAILS',
  GAMBLE_COUNT: 'GAMBLE_COUNT',
  UNBLOCK: 'UNBLOCK',
  UNBLOCK_PLAYER: 'UNBLOCK_PLAYER',
  BLOCK_DATE: 'BLOCK_DATE',
  BLOCKED_UNTIL: 'BLOCKED_UNTIL',
  HIDE_PLAYER_ID: 'HIDE_PLAYER_ID',
  BAHAMUT_WALLET: 'BAHAMUT_WALLET',
  CASINO_WALLET: 'CASINO_WALLET',
  PLEASE_SELECT_TOURNAMENT_ID: 'PLEASE_SELECT_TOURNAMENT_ID',
  GAMBLE: 'GAMBLE',
  IS_CLAIMED: 'IS_CLAIMED',
  CLAIMED_COUNT: 'CLAIMED_COUNT',
  CLAIMED_AMOUNT: 'CLAIMED_AMOUNT',
  CURRENCY_REPORT: 'CURRENCY_REPORT',
  CURRENCY_SYMBOL: 'CURRENCY_SYMBOL',
  COIN: 'COIN',
  NATIVE: 'NATIVE',
  CURRENCY_TYPE: 'CURRENCY_TYPE',
  BONUS_WALLET: 'BONUS_WALLET',
  WALLET_TYPE: 'WALLET_TYPE',
  STAGE: 'STAGE',
  ENTRY_FEE: 'ENTRY_FEE',
  CASE_NUMBER: 'CASE_NUMBER',
  CASE_INITIAL_AMOUNT: 'CASE_INITIAL_AMOUNT',
  CASE_AMOUNT: 'CASE_AMOUNT',
  OFFER: 'OFFER',
  CARD_SUIT_CONFIGS: 'CARD_SUIT_CONFIGS',
  CARD_CRITERIA: 'CARD_CRITERIA',
  FIRST_NAME: 'FIRST_NAME',
  LAST_NAME: 'LAST_NAME',
  EXTERNAL_FREEBET_ID: 'EXTERNAL_FREEBET_ID',
  GAME_CATEGORY: 'GAME_CATEGORY',
  PERMISSION_SELECTION: 'PERMISSION_SELECTION',
  GAME_COMPARISON_REPORT: 'GAME_COMPARISON_REPORT',
  PG_LOBBY: 'PG_LOBBY',
  CREATE_BANNER: 'CREATE_BANNER',
  BANNER_MANAGER: 'BANNER_MANAGER',
  PLATFORM_TYPE: 'PLATFORM_TYPE',
  TRIGGER: 'TRIGGER',
  UPLOAD_FILE: 'UPLOAD_FILE',
  REDIRECTION: 'REDIRECTION',
  SCHEDULED: 'SCHEDULED',
  PUBLISH: 'PUBLISH',
  ENABLE_TOP_WINS: 'ENABLE_TOP_WINS',
  ENABLE_JACKPOT: 'ENABLE_JACKPOT',
  ENABLE_GAME_LIMITS: 'ENABLE_GAME_LIMITS',
  ENABLE_PLAYER_COUNT: 'ENABLE_PLAYER_COUNT',
  ENABLE_PRE_LOBBY: 'ENABLE_PRE_LOBBY',
  CREATE_CATEGORY: 'CREATE_CATEGORY',
  ADD_CATEGORY: 'ADD_CATEGORY',
  EDIT_CATEGORY: 'EDIT_CATEGORY',
  ORDER: 'ORDER',
  CATEGORY_NAME: 'CATEGORY_NAME',
  ADD_GAME: 'ADD_GAME',
  EDIT_GAME: 'EDIT_GAME',
  TOURNAMENT: 'TOURNAMENT',
  OLD: 'OLD',
  NEW: 'NEW',
  CATEGORIES: 'CATEGORIES',
  LOBBY_ID: 'LOBBY_ID',
  LOBBY_TYPE: 'LOBBY_TYPE',
  LOBBY_NAME: 'LOBBY_NAME',
  ADD_LOBBY: 'ADD_LOBBY',
  PG_LOBBY_ADDED_SUCCESSFULLY: 'PG_LOBBY_ADDED_SUCCESSFULLY',
  PG_LOBBY_EDITED_SUCCESSFULLY: 'PG_LOBBY_EDITED_SUCCESSFULLY',
  UPLOAD_ICON: 'UPLOAD_ICON',
  COPY: 'COPY',
  COPIED: 'COPIED',
  PERMISSION_DENIED: 'PERMISSION_DENIED',
  BANNERS: 'BANNERS',
  EDIT_BANNER: 'EDIT_BANNER',
  FREE_BET_PRIZE: 'FREE_BET_PRIZE',
  FREE_BET_WIN: 'FREE_BET_WIN',
  ALL_CURRENCY: 'ALL_CURRENCY',
  VIEW_BANNER: 'VIEW_BANNER',
  EXCLUSIVE: 'EXCLUSIVE',
  CURRENCY_REPORT_BY_PARTNERS: 'CURRENCY_REPORT_BY_PARTNERS',
  DAYS_10: 'DAYS_10',
  DAYS_20: 'DAYS_20',
  ENABLE_PLAYER_LIKES: 'ENABLE_PLAYER_LIKES',
  HAS_LANDSCAPE_ROTATE: 'HAS_LANDSCAPE_ROTATE',
  SHOW_PAY_TABLE: 'SHOW_PAY_TABLE',
  EVEN: 'EVEN',
  SUM_IN_3_10: 'SUM_IN_3_10',
  SUM_IN_11_18: 'SUM_IN_11_18',
  WINNING_MARKETS: 'WINNING_MARKETS',
  BONUS_MULTIPLIER_MARKETS: 'BONUS_MULTIPLIER_MARKETS',
  BONUS_MULTIPLIER: 'BONUS_MULTIPLIER',
  HAS_PASCAL_LOADER: 'HAS_PASCAL_LOADER',
  POPUP_MANAGER: 'POPUP_MANAGER',
  ADD_POPUP: 'ADD_POPUP',
  EDIT_POPUP: 'EDIT_POPUP',
  VIEW_POPUP: 'VIEW_POPUP',
  LOBBY: 'LOBBY',
  BANNER_UPLOAD: 'BANNER_UPLOAD',
  POPUP_NAME: 'POPUP_NAME',
  PLEASE_TRY_AGAIN: 'PLEASE_TRY_AGAIN',
  BOOSTED_JACKPOT_LIST: 'BOOSTED_JACKPOT_LIST',
  ADD_BOOSTED_JACKPOT: 'ADD_BOOSTED_JACKPOT',
  PRIZE_POOL: 'PRIZE_POOL',
  PRIZE_POOL_LEFT: 'PRIZE_POOL_LEFT',
  WINNING_COUNT: 'WINNING_COUNT',
  WINNING_COUNT_LEFT: 'WINNING_COUNT_LEFT',
  EDIT_BOOSTED_JACKPOT: 'EDIT_BOOSTED_JACKPOT',
  VIEW_BOOSTED_JACKPOT: 'VIEW_BOOSTED_JACKPOT',
  FEATURE_NAME: 'FEATURE_NAME',
  GAME_LIST: 'GAME_LIST',
  DAILY_USER_WIN_LIMIT: 'DAILY_USER_WIN_LIMIT',
  WHEEL_SECTION_COUNT: 'WHEEL_SECTION_COUNT',
  FIRST_CURRENCY: 'FIRST_CURRENCY',
  SECONDARY_CURRENCIES: 'SECONDARY_CURRENCIES',
  FEATURE_DURATION: 'FEATURE_DURATION',
  WHEEL_SECTION_COUNT_VALIDATION: 'WHEEL_SECTION_COUNT_VALIDATION',
  FINANCIAL_CONFIG: 'FINANCIAL_CONFIG',
  BET_LEVEL: 'BET_LEVEL',
  BOOSTED_JACKPOT_ADDED_SUCCESSFULLY: 'BOOSTED_JACKPOT_ADDED_SUCCESSFULLY',
  BOOSTED_JACKPOT_EDITED_SUCCESSFULLY: 'BOOSTED_JACKPOT_EDITED_SUCCESSFULLY',
  PLAYER_BOOSTED_JACKPOT_REPORT: 'PLAYER_BOOSTED_JACKPOT_REPORT',
  BOOSTED_JACKPOT_ID: 'BOOSTED_JACKPOT_ID',
  BOOSTED_JACKPOT_NAME: 'BOOSTED_JACKPOT_NAME',
  CURRENT_PRIZE_POOL: 'CURRENT_PRIZE_POOL',
  AFTER_WIN_PRIZE_POOL: 'AFTER_WIN_PRIZE_POOL',
  CANCEL_BOOSTED_JACKPOT: 'CANCEL_BOOSTED_JACKPOT',
  BOOSTED_JACKPOT_CANCELED_SUCCESSFULLY: 'BOOSTED_JACKPOT_CANCELED_SUCCESSFULLY',
  JACKPOT_FEATURE: 'JACKPOT_FEATURE',
  POPUP_ADDED_SUCCESSFULLY: 'POPUP_ADDED_SUCCESSFULLY',
  POPUP_EDITED_SUCCESSFULLY: 'POPUP_EDITED_SUCCESSFULLY',
  POPUPS: 'POPUPS',
  POPUP_CANCELED_SUCCESSFULLY: 'POPUP_CANCELED_SUCCESSFULLY',
  CANCEL_POPUP: 'CANCEL_POPUP',
  BACKOFFICE_API_URL: 'BACKOFFICE_API_URL',
  FOR_LOBBY: 'FOR_LOBBY',
  TRONIX_ZONE: 'TRONIX_ZONE',
  VIRTUAL_SPORTS: 'VIRTUAL_SPORTS',
  PREDICTOR: 'PREDICTOR',
  PREDICTIONS: 'PREDICTIONS',
  ROUND: 'ROUND',
  MATCH: 'MATCH',
  TEAM: 'TEAM',
  LEAGUE: 'LEAGUE',
  LEADERBOARD: 'LEADERBOARD',
  CREATE_ROUND: 'CREATE_ROUND',
  PRIZE: 'PRIZE',
  STARTED: 'STARTED',
  VERIFICATION: 'VERIFICATION',
  POSTPONED: 'POSTPONED',
  EDIT_ROUND: 'EDIT_ROUND',
  VIEW_ROUND: 'VIEW_ROUND',
  MATCH_DATE: 'MATCH_DATE',
  PRODUCT_TYPE: 'PRODUCT_TYPE',
  SPORT_TYPE: 'SPORT_TYPE',
  REGIONS: 'REGIONS',
  COMPETITIONS: 'COMPETITIONS',
  MIN_PARTICIPANTS_COUNT: 'MIN_PARTICIPANTS_COUNT',
  SELECT_DISTRIBUTION: 'SELECT_DISTRIBUTION',
  SELECT_MATCHES: 'SELECT_MATCHES',
  VERIFIED_USER: 'VERIFIED_USER',
  IS_TEST_ROUND: 'IS_TEST_ROUND',
  IS_VISIBLE: 'IS_VISIBLE',
  MACTHES: 'MACTHES',
  CASH_PRIZE_POOL: 'CASH_PRIZE_POOL',
  FREE_BET_PRIZE_POOL: 'FREE_BET_PRIZE_POOL',
  FREE_SPIN_PRIZE_POOL: 'FREE_SPIN_PRIZE_POOL',
  ROUND_ADDED_SUCCESSFULLY: 'ROUND_ADDED_SUCCESSFULLY',
  ROUND_EDIT_SUCCESSFULLY: 'ROUND_EDIT_SUCCESSFULLY',
  PARTNER_DOESNT_HAVE_ANY_SPORTS: 'PARTNER_DOESNT_HAVE_ANY_SPORTS',
  EMAIL_REQUIRED: 'EMAIL_REQUIRED',
  ROUNDS: 'ROUNDS',
  PARTICIPANTS_COUNT: 'PARTICIPANTS_COUNT',
  FREE_SPEEN: 'FREE_SPEEN',
  IS_TEST: 'IS_TEST',
  ROUND_NAME: 'ROUND_NAME',
  VISIBILITY_EDITED_SUCCESSFULLY: 'VISIBILITY_EDITED_SUCCESSFULLY',
  CANCEL_ROUND: 'CANCEL_ROUND',
  ROUND_CANCELED_SUCCESSFULLY: 'ROUND_CANCELED_SUCCESSFULLY',
  TEAMS: 'TEAMS',
  LEAGUE_NAME:'LEAGUE_NAME',
  TEAM_NAME:'TEAM_NAME',
  SHORT_NAME:'SHORT_NAME',
  LOGO: 'LOGO',
  JERSEY: 'JERSEY',
  BANNER_JERSEY: 'BANNER_JERSEY',
  LEAGUE_ID: 'LEAGUE_ID',
  EDIT_TEAM: 'EDIT_TEAM',
  UPLOAD_LOGO: 'UPLOAD_LOGO',
  UPLOAD_JERSEY: 'UPLOAD_JERSEY',
  UPLOAD_BANNER_JERSEY: 'UPLOAD_BANNER_JERSEY',
  TEAM_EDITED_SUCCESSFULLY: 'TEAM_EDITED_SUCCESSFULLY',
  PREDICTION_OF_PLAYER: 'PREDICTION_OF_PLAYER',
  PREDICTION_OF_EXACT_TIME: 'PREDICTION_OF_EXACT_TIME',
  NUMBER_OF_PREDICTIONS: 'NUMBER_OF_PREDICTIONS',
  PREDICTION_OF_MATCH_RESULT: 'PREDICTION_OF_MATCH_RESULT',
  CORRECT_PLAYER_NAME: 'CORRECT_PLAYER_NAME',
  CORRECT_SCORE: 'CORRECT_SCORE',
  FIRST_GOAL_EXACT_TIME: 'FIRST_GOAL_EXACT_TIME',
  FIRST_PLAYER_TO_SCORE: 'FIRST_PLAYER_TO_SCORE',
  MATCHES: 'MATCHES',
  HOME_TEAM: 'HOME_TEAM',
  AWAY_TEAM: 'AWAY_TEAM',
  HOME_TEAM_SCORE: 'HOME_TEAM_SCORE',
  AWAY_TEAM_SCORE: 'AWAY_TEAM_SCORE',
  MATCH_STATUS: 'MATCH_STATUS',
  RECALCULATE: 'RECALCULATE',
  MATCH_RECALCULATED: 'MATCH_RECALCULATED',
  MATCH_EDITED_SUCCESSFULLY: 'MATCH_EDITED_SUCCESSFULLY',
  LEAGUES: 'LEAGUES',
  REGION: 'REGION',
  BOOKING: 'BOOKING',
  PREMATCH: 'PREMATCH',
  PREMATCH_LIVE: 'PREMATCH_LIVE',
  LEAGUE_EDITED_SUCCESSFULLY: 'LEAGUE_EDITED_SUCCESSFULLY',
  EDIT_LEAGUE: 'EDIT_LEAGUE',
  ROUND_LEADERBOARD: 'ROUND_LEADERBOARD',
  NICKNAME: 'NICKNAME',
  EARNED_POINTS: 'EARNED_POINTS',
  WINNING: 'WINNING',
  PAYMENT_STATUS: 'PAYMENT_STATUS',
  EDIT_MATCH: 'EDIT_MATCH',
  FIRST_PLAYER_PREDICTED_TO_SCORE: 'FIRST_PLAYER_PREDICTED_TO_SCORE',
  EXACT_TIME: 'EXACT_TIME',
  PREDICTED_EXACT_TIME: 'PREDICTED_EXACT_TIME',
  POINTS: 'POINTS',
  FINAL_SCORE: 'FINAL_SCORE',
  PREDICTED_FINAL_SCORE: 'PREDICTED_FINAL_SCORE',
  LICENSE: 'LICENSE',
  ARE_YOU_SURE_TO_CHANGE_VISIBILITY: 'ARE_YOU_SURE_TO_CHANGE_VISIBILITY',
  PLAYER_WINNING: 'PLAYER_WINNING',
  CONFIGURATION: 'CONFIGURATION',
  BANNER: 'BANNER',
  LEAGUE_STATISTICS: 'LEAGUE_STATISTICS',
  STATISTICS_OF_THE_LAST_MATCHES: 'STATISTICS_OF_THE_LAST_MATCHES',
  ADD_MIN_PARTICIPANTS: 'ADD_MIN_PARTICIPANTS',
  IN_PROGRESS: 'IN_PROGRESS',
  CONFIGURATION_EDITED_SUCCESSFULLY: 'CONFIGURATION_EDITED_SUCCESSFULLY',
  COUNTRY: 'COUNTRY',
  RANK: 'RANK',
  GAMBLE_AMOUNT: 'GAMBLE_AMOUNT',
  SELECTED: 'SELECTED',
  TEMPLATES: 'TEMPLATES',
  TEMPLATE_EDITED_SUCCESSFULLY: 'TEMPLATE_EDITED_SUCCESSFULLY',
  TEMPLATE_ADDED_SUCCESSFULLY: 'TEMPLATE_ADDED_SUCCESSFULLY',
  EDIT_TEMPLATE: 'EDIT_TEMPLATE',
  DELETE_TEMPLATE: 'DELETE_TEMPLATE',
  DO_YOU_WANT_TO_DELETE: 'DO_YOU_WANT_TO_DELETE',
  PERMISSION_TEMPLATE_DELETED_SUCCESSFULLY: 'PERMISSION_TEMPLATE_DELETED_SUCCESSFULLY',
  CHOOSE_FROM_TEMPLATE: 'CHOOSE_FROM_TEMPLATE'
};
