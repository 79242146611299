import { action, flow, makeObservable, observable } from 'mobx';

import { downloadFileFromHttpResponse } from '../../core/helpers/fileDownloader';
import { boostedJackpotService } from './boostedJackpotService';
import { HttpResponse } from '../../core/classes/HttpResponse';

class BoostedJackpotStore {

  boostedJackpots = new HttpResponse({ items: [], count: 0 });

  boostedJackpotDetails = new HttpResponse({});

  playerBoostedJackpotReports = new HttpResponse({ items: [], count: 0 });

  isPlayerBoostedJackpotReportExportLoading = false;

  constructor() {
    makeObservable(this, {

      boostedJackpots: observable,
      setBoostedJackpots: action.bound,
      getBoostedJackpots: flow,

      boostedJackpotDetails: observable,
      setBoostedJackpotDetails: action.bound,
      getBoostedJackpotDetails: flow,

      playerBoostedJackpotReports: observable,
      setPlayerBoostedJackpotReports: action.bound,
      getPlayerBoostedJackpotReports: flow,

      isPlayerBoostedJackpotReportExportLoading: observable,
      setIsPlayerBoostedJackpotReportExportLoading: action.bound
    });
  }

  setBoostedJackpots = (data) => {
    this.boostedJackpots = data;
  }
  resetBoostedJackpots = () => {
    this.setBoostedJackpots(this.boostedJackpots.fetching(true));
  };
  getBoostedJackpots = function* (params) {
    this.setBoostedJackpots(this.boostedJackpots.fetching());
    const data = yield boostedJackpotService.getBoostedJackpots(params);
    this.setBoostedJackpots(this.boostedJackpots.fetched(data));
  };

  setBoostedJackpotDetails = (data) => {
    this.boostedJackpotDetails = data;
  }
  resetBoostedJackpotDetails = () => {
    this.setBoostedJackpotDetails(this.boostedJackpotDetails.fetching(true));
  }
  getBoostedJackpotDetails = function* (id) {
    this.setBoostedJackpotDetails(this.boostedJackpotDetails.fetching());
    const data = yield boostedJackpotService.getBoostedJackpotDetails(id);
    this.setBoostedJackpotDetails(this.boostedJackpotDetails.fetched(data));
  };

  setPlayerBoostedJackpotReports = (data) => {
    this.playerBoostedJackpotReports = data;
  }
  getPlayerBoostedJackpotReports = function* (params) {
    this.setPlayerBoostedJackpotReports(this.playerBoostedJackpotReports.fetching());
    const data = yield boostedJackpotService.getPlayerBoostedJackpotReports(params);
    this.setPlayerBoostedJackpotReports(this.playerBoostedJackpotReports.fetched(data));
  };

  addBoostedJackpot = (data) => {
    return boostedJackpotService.addBoostedJackpot(data);
  }

  editBoostedJackpot = (data) => {
    return boostedJackpotService.editBoostedJackpot(data);
  }

  cancelBoostedJackpot = (id) => {
    return boostedJackpotService.cancelBoostedJackpot(id);
  }

  setIsPlayerBoostedJackpotReportExportLoading = (data) => {
    this.isPlayerBoostedJackpotReportExportLoading = data;
  }

  exportPlayerBoostedJackpotReport = async (params) => {
    this.setIsPlayerBoostedJackpotReportExportLoading(true);
    const res = await boostedJackpotService.exportPlayerBoostedJackpotReport(params);
    if (!res.ok) {
      this.setIsPlayerBoostedJackpotReportExportLoading(false);
      throw new Error('Something went wrong while exporting.');
    }
    try {
      await downloadFileFromHttpResponse(res, 'playerBootsedJackpotReport.xlsx');
    } catch (error) {
      console.error(error);
    }
    this.setIsPlayerBoostedJackpotReportExportLoading(false);
    return res;
  };

}

export const boostedJackpotStore = new BoostedJackpotStore();