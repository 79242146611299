import React from 'react';
import { observer } from 'mobx-react-lite';
import styled from 'styled-components';

import { Checkbox } from '../../../shared';

const RowContent = styled.label`
  display: flex;
  justify-content: space-between;

  border-radius: 4px;
  box-sizing: border-box;
  padding: 10px 14px;
  transition-duration: 0.25s;
  cursor: pointer;
  & *, & :before {
    transition: none !important;
  } 

  &:hover {
    background-color: rgba(0, 0, 0, 0.05);
  }
`;

const PartnerCheckboxRow = observer(({
  id,
  label,
  checked,
  onChange
}) => {

  return (
    <RowContent>
      <Checkbox
        label={label}
        checked={checked}
        onChange={onChange}
      />
      <span>{ id }</span>
    </RowContent>
  );
});

export default PartnerCheckboxRow;
