export const TimeZone = {
  UTC_M_12_00: -12,
  UTC_M_11_00: -11,
  UTC_M_10_00: -10,
  UTC_M_09_30: -9.5,
  UTC_M_09_00: -9,
  UTC_M_08_00: -8,
  UTC_M_07_00: -7,
  UTC_M_06_00: -6,
  UTC_M_05_00: -5,
  UTC_M_04_30: -4.5,
  UTC_M_04_00: -4,
  UTC_M_03_30: -3.5,
  UTC_M_03_00: -3,
  UTC_M_02_00: -2,
  UTC_M_01_00: -1,
  UTC_P_00_00: 0,
  UTC_P_01_00: 1,
  UTC_P_02_00: 2,
  UTC_P_03_00: 3,
  UTC_P_03_30: 3.5,
  UTC_P_04_00: 4,
  UTC_P_04_30: 4.5,
  UTC_P_05_00: 5,
  UTC_P_05_30: 5.5,
  UTC_P_06_00: 6,
  UTC_P_06_30: 6.5,
  UTC_P_07_00: 7,
  UTC_P_08_00: 8,
  UTC_P_08_45: 8.75,
  UTC_P_09_00: 9,
  UTC_P_09_30: 9.5,
  UTC_P_10_00: 10,
  UTC_P_10_30: 10.5,
  UTC_P_11_00: 11,
  UTC_P_11_30: 11.5,
  UTC_P_12_00: 12,
  UTC_P_12_45: 12.75,
  UTC_P_13_00: 13,
  UTC_P_14_00: 14
};

export const TimeZoneNames = {
  [TimeZone.UTC_M_12_00]: 'UTC-12:00',
  [TimeZone.UTC_M_11_00]: 'UTC-11:00',
  [TimeZone.UTC_M_10_00]: 'UTC-10:00',
  [TimeZone.UTC_M_09_30]: 'UTC-09:30',
  [TimeZone.UTC_M_09_00]: 'UTC-09:00',
  [TimeZone.UTC_M_08_00]: 'UTC-08:00',
  [TimeZone.UTC_M_07_00]: 'UTC-07:00',
  [TimeZone.UTC_M_06_00]: 'UTC-06:00',
  [TimeZone.UTC_M_05_00]: 'UTC-05:00',
  [TimeZone.UTC_M_04_30]: 'UTC-04:30',
  [TimeZone.UTC_M_04_00]: 'UTC-04:00',
  [TimeZone.UTC_M_03_30]: 'UTC-03:30',
  [TimeZone.UTC_M_03_00]: 'UTC-03:00',
  [TimeZone.UTC_M_02_00]: 'UTC-02:00',
  [TimeZone.UTC_M_01_00]: 'UTC-01:00',
  [TimeZone.UTC_P_00_00]: 'UTC+00:00',
  [TimeZone.UTC_P_01_00]: 'UTC+01:00',
  [TimeZone.UTC_P_02_00]: 'UTC+02:00',
  [TimeZone.UTC_P_03_00]: 'UTC+03:00',
  [TimeZone.UTC_P_03_30]: 'UTC+03:30',
  [TimeZone.UTC_P_04_00]: 'UTC+04:00',
  [TimeZone.UTC_P_04_30]: 'UTC+04:30',
  [TimeZone.UTC_P_05_00]: 'UTC+05:00',
  [TimeZone.UTC_P_05_30]: 'UTC+05:30',
  [TimeZone.UTC_P_06_00]: 'UTC+06:00',
  [TimeZone.UTC_P_06_30]: 'UTC+06:30',
  [TimeZone.UTC_P_07_00]: 'UTC+07:00',
  [TimeZone.UTC_P_08_00]: 'UTC+08:00',
  [TimeZone.UTC_P_08_45]: 'UTC+08:45',
  [TimeZone.UTC_P_09_00]: 'UTC+09:00',
  [TimeZone.UTC_P_09_30]: 'UTC+09:30',
  [TimeZone.UTC_P_10_00]: 'UTC+10:00',
  [TimeZone.UTC_P_10_30]: 'UTC+10:30',
  [TimeZone.UTC_P_11_00]: 'UTC+11:00',
  [TimeZone.UTC_P_11_30]: 'UTC+11:30',
  [TimeZone.UTC_P_12_00]: 'UTC+12:00',
  [TimeZone.UTC_P_12_45]: 'UTC+12:45',
  [TimeZone.UTC_P_13_00]: 'UTC+13:00',
  [TimeZone.UTC_P_14_00]: 'UTC+14:00' 
};

export const TimeZoneDropdownData = [
  {
    id: TimeZone.UTC_M_12_00,
    name: TimeZoneNames[TimeZone.UTC_M_12_00]
  },
  {
    id: TimeZone.UTC_M_11_00,
    name: TimeZoneNames[TimeZone.UTC_M_11_00]
  },
  {
    id: TimeZone.UTC_M_10_00,
    name: TimeZoneNames[TimeZone.UTC_M_10_00]
  },
  {
    id: TimeZone.UTC_M_09_30,
    name: TimeZoneNames[TimeZone.UTC_M_09_30]
  },
  {
    id: TimeZone.UTC_M_09_00,
    name: TimeZoneNames[TimeZone.UTC_M_09_00]
  },
  {
    id: TimeZone.UTC_M_08_00,
    name: TimeZoneNames[TimeZone.UTC_M_08_00]
  },
  {
    id: TimeZone.UTC_M_07_00,
    name: TimeZoneNames[TimeZone.UTC_M_07_00]
  },
  {
    id: TimeZone.UTC_M_06_00,
    name: TimeZoneNames[TimeZone.UTC_M_06_00]
  },
  {
    id: TimeZone.UTC_M_05_00,
    name: TimeZoneNames[TimeZone.UTC_M_05_00]
  },
  {
    id: TimeZone.UTC_M_04_30,
    name: TimeZoneNames[TimeZone.UTC_M_04_30]
  },
  {
    id: TimeZone.UTC_M_04_00,
    name: TimeZoneNames[TimeZone.UTC_M_04_00]
  },
  {
    id: TimeZone.UTC_M_03_30,
    name: TimeZoneNames[TimeZone.UTC_M_03_30]
  },
  {
    id: TimeZone.UTC_M_03_00,
    name: TimeZoneNames[TimeZone.UTC_M_03_00]
  },
  {
    id: TimeZone.UTC_M_02_00,
    name: TimeZoneNames[TimeZone.UTC_M_02_00]
  },
  {
    id: TimeZone.UTC_M_01_00,
    name: TimeZoneNames[TimeZone.UTC_M_01_00]
  },
  {
    id: TimeZone.UTC_P_00_00,
    name: TimeZoneNames[TimeZone.UTC_P_00_00]
  },
  {
    id: TimeZone.UTC_P_01_00,
    name: TimeZoneNames[TimeZone.UTC_P_01_00]
  },
  {
    id: TimeZone.UTC_P_02_00,
    name: TimeZoneNames[TimeZone.UTC_P_02_00]
  },
  {
    id: TimeZone.UTC_P_03_00,
    name: TimeZoneNames[TimeZone.UTC_P_03_00]
  },
  {
    id: TimeZone.UTC_P_03_30,
    name: TimeZoneNames[TimeZone.UTC_P_03_30]
  },
  {
    id: TimeZone.UTC_P_04_00,
    name: TimeZoneNames[TimeZone.UTC_P_04_00]
  },
  {
    id: TimeZone.UTC_P_04_30,
    name: TimeZoneNames[TimeZone.UTC_P_04_30]
  },
  {
    id: TimeZone.UTC_P_05_00,
    name: TimeZoneNames[TimeZone.UTC_P_05_00]
  },
  {
    id: TimeZone.UTC_P_05_30,
    name: TimeZoneNames[TimeZone.UTC_P_05_30]
  },
  {
    id: TimeZone.UTC_P_06_00,
    name: TimeZoneNames[TimeZone.UTC_P_06_00]
  },
  {
    id: TimeZone.UTC_P_06_30,
    name: TimeZoneNames[TimeZone.UTC_P_06_30]
  },
  {
    id: TimeZone.UTC_P_07_00,
    name: TimeZoneNames[TimeZone.UTC_P_07_00]
  },
  {
    id: TimeZone.UTC_P_08_00,
    name: TimeZoneNames[TimeZone.UTC_P_08_00]
  },
  {
    id: TimeZone.UTC_P_08_45,
    name: TimeZoneNames[TimeZone.UTC_P_08_45]
  },
  {
    id: TimeZone.UTC_P_09_00,
    name: TimeZoneNames[TimeZone.UTC_P_09_00]
  },
  {
    id: TimeZone.UTC_P_09_30,
    name: TimeZoneNames[TimeZone.UTC_P_09_30]
  },
  {
    id: TimeZone.UTC_P_10_00,
    name: TimeZoneNames[TimeZone.UTC_P_10_00]
  },
  {
    id: TimeZone.UTC_P_10_30,
    name: TimeZoneNames[TimeZone.UTC_P_10_30]
  },
  {
    id: TimeZone.UTC_P_11_00,
    name: TimeZoneNames[TimeZone.UTC_P_11_00]
  },
  {
    id: TimeZone.UTC_P_11_30,
    name: TimeZoneNames[TimeZone.UTC_P_11_30]
  },
  {
    id: TimeZone.UTC_P_12_00,
    name: TimeZoneNames[TimeZone.UTC_P_12_00]
  },
  {
    id: TimeZone.UTC_P_12_45,
    name: TimeZoneNames[TimeZone.UTC_P_12_45]
  },
  {
    id: TimeZone.UTC_P_13_00,
    name: TimeZoneNames[TimeZone.UTC_P_13_00]
  },
  {
    id: TimeZone.UTC_P_14_00,
    name: TimeZoneNames[TimeZone.UTC_P_14_00]
  }
];
