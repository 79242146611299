import { action, flow, makeObservable, observable } from 'mobx';

import { HttpResponse } from '../../core/classes/HttpResponse';
import { GameLineType } from '../../core/enums/GameLineType';
import { getLineType } from '../../core/helpers/getLineType';
import { reportsService } from './reportsService';

class ReportsStore {

  /** @type {HttpResponse<RoundReportResponse>}*/
  bogRounds = new HttpResponse({ items: [], count: 0 });

  /** @type {HttpResponse<BetReportResponse>}*/
  bogBets = new HttpResponse({ items: [], count: 0 });

  /** @type {HttpResponse<RoundReportResponse>}*/
  betshopRounds = new HttpResponse({ items: [], count: 0 });

  /** @type {HttpResponse<BetReportResponse>}*/
  betshopBets = new HttpResponse({ items: [], count: 0 });

  /** @type {HttpResponse<object>}*/
  betshopBetDetails = new HttpResponse({});

  /** @type {HttpResponse<ArcadeBetReportResponse>}*/
  arcadeBets = new HttpResponse({ items: [], count: 0 });

  arcadeRounds = new HttpResponse({ items: [], count: 0 });

  /** @type {HttpResponse<RoundReportResponse>}*/
  tableGamesRounds = new HttpResponse({ items: [], count: 0 });

  /** @type {HttpResponse<BetReportResponse>}*/
  tableGamesBets = new HttpResponse({ items: [], count: 0 });

  /** @type {HttpResponse<RoundReportResponse>}*/
  lotteryRounds = new HttpResponse({ items: [], count: 0 });

  /** @type {HttpResponse<BetReportResponse>}*/
  lotteryBets = new HttpResponse({ items: [], count: 0 });

  /** @type {HttpResponse<BetReportResponse>}*/
  slotBets = new HttpResponse({ items: [], count: 0 });

  /** @type {HttpResponse<BetReportResponse>}*/
  tronixBets = new HttpResponse({ items: [], count: 0 });

  /** @type {HttpResponse<TableRoundReportDetailsResponse>}*/
  tableGamesRoundDetails = new HttpResponse({});

  /** @type {HttpResponse<Array<TableBetReportDetailsItem>>}*/
  tableGamesBetDetails = new HttpResponse([]);

  arcadeGamesBetDetails = new HttpResponse({});

  /** @type {HttpResponse<object>}*/
  lotteryBetDetails = new HttpResponse({});

  /** @type {HttpResponse<object>}*/
  bogRoundDetails = new HttpResponse({});

  /** @type {HttpResponse<object>}*/
  bogBetDetails = new HttpResponse({});

  /** @type {HttpResponse<object>}*/
  slotBetDetails = new HttpResponse({});

  /** @type {HttpResponse<object>}*/
  tronixBetDetails = new HttpResponse({});

  /** @type {HttpResponse<VirtualReportResponse>}*/
  virtualBets = new HttpResponse({ items: [], count: 0 });

  /** @type {HttpResponse<object>}*/
  virtualBetDetails = new HttpResponse({});

  virtualRoundReports = new HttpResponse({ items: [], count: 0 });

  virtualMarketReports = new HttpResponse({ items: [], count: 0 });

  betshopMarketReports = new HttpResponse({ items: [], count: 0 });

  playersReport = new HttpResponse({ items: [], count: 0 });

  playersByGameReport = new HttpResponse({ items: [], count: 0 });

  constructor() {
    makeObservable(this, {
      bogRounds: observable,
      bogBets: observable,
      bogBetDetails: observable,
      betshopRounds: observable,
      betshopBets: observable,
      betshopBetDetails: observable,
      tableGamesRounds: observable,
      tableGamesBets: observable,
      tableGamesBetDetails: observable,
      tableGamesRoundDetails: observable,
      arcadeBets: observable,
      arcadeRounds: observable,
      lotteryRounds: observable,
      lotteryBets: observable,
      lotteryBetDetails: observable,
      slotBets: observable,
      slotBetDetails: observable,
      tronixBets: observable,
      tronixBetDetails: observable,
      bogRoundDetails: observable,
      virtualBets: observable,
      virtualBetDetails: observable,
      virtualRoundReports: observable,
      virtualMarketReports: observable,
      betshopMarketReports: observable,
      playersReport: observable,
      playersByGameReport: observable,
      arcadeGamesBetDetails: observable,
      setBogRounds: action.bound,
      setBogBets: action.bound,
      setBogBetDetails: action.bound,
      setBetshopRounds: action.bound,
      setBetshopBets: action.bound,
      setBetshopBetDetails: action.bound,
      setTableGamesRounds: action.bound,
      setTableGamesBets: action.bound,
      setTableGamesBetDetails: action.bound,
      setTableGamesRoundDetails: action.bound,
      setArcadeBets: action.bound,
      setArcadeRounds: action.bound,
      setSlotBets: action.bound,
      setSlotBetDetails: action.bound,
      setTronixBets: action.bound,
      setTronixBetDetails: action.bound,
      setLotteryRounds: action.bound,
      setLotteryBets: action.bound,
      setLotteryBetDetails: action.bound,
      resetBetReport: action.bound,
      resetSlotBetDetails: action.bound,
      setBogRoundDetails: action.bound,
      setVirtualBets: action.bound,
      setVirtualBetDetails: action.bound,
      setVirtualRoundReports: action.bound,
      setVirtualMarketReports: action.bound,
      setBetshopMarketReports: action.bound,
      setArcadeGamesBetDetails: action.bound,
      setPlayersReport: action.bound,
      setPlayersByGameReport: action.bound,
      getBogRoundReports: flow.bound,
      getBogBetReports: flow.bound,
      getBogBetDetails: flow.bound,
      getBetshopRoundReports: flow.bound,
      getBetshopBetReports: flow.bound,
      getBetshopBetDetails: flow.bound,
      getTableGamesRoundReports: flow.bound,
      getTableGamesBetReports: flow.bound,
      getTableGamesBetDetails: flow.bound,
      getTableGamesRoundDetails: flow.bound,
      getLotteryRoundReports: flow.bound,
      getLotteryBetReports: flow.bound,
      getLotteryBetDetails: flow.bound,
      getArcadeBetReports: flow.bound,
      getArcadeRoundReports: flow.bound,
      getSlotBetReports: flow.bound,
      getSlotBetDetails: flow.bound,
      getTronixBetReports: flow.bound,
      getTronixBetDetails: flow.bound,
      getBogRoundDetails: flow.bound,
      getVirtualBets: flow.bound,
      getVirtualBetDetails: flow.bound,
      getVirtualRoundReports: flow.bound,
      getVirtualMarketReports: flow.bound,
      getBetshopMarketReports: flow.bound,
      getPlayersReport: flow,
      getPlayersByGameReport: flow,
      getArcadeGamesBetDetails: flow.bound,
    });
  }

  /**
   * @name setBogRounds
   * @param {HttpResponse<RoundReportResponse>} bogRounds
   * @return {void}
   */
  setBogRounds = (bogRounds) => {
    this.bogRounds = bogRounds;
  };

  /**
   * @name setBogBets
   * @param {HttpResponse<BetReportResponse>} bogBets
   * @return {void}
   */
  setBogBets = (bogBets) => {
    this.bogBets = bogBets;
  };

  /**
   * @name setBogBetDetails
   * @param {HttpResponse<Object>} data
   * @return {void}
   */
  setBogBetDetails = (data) => {
    this.bogBetDetails = data;
  };

  /**
   * @name setSlotBets
   * @param {HttpResponse<BetReportResponse>} data
   * @return {void}
   */
  setSlotBets = (data) => {
    this.slotBets = data;
  };

  /**
   * @name setSlotBetDetails
   * @param {HttpResponse<Object>} data
   * @return {void}
   */
  setSlotBetDetails = (data) => {
    this.slotBetDetails = data;
  };

  /**
   * @name setTronixBets
   * @param {HttpResponse<BetReportResponse>} data
   * @return {void}
   */
  setTronixBets = (data) => {
    this.tronixBets = data;
  };

  /**
 * @name setTronixBetDetails
 * @param {HttpResponse<Object>} data
 * @return {void}
 */
  setTronixBetDetails = (data) => {
    this.tronixBetDetails = data;
  };

  /**
   * @name setBetshopRounds
   * @param {HttpResponse<RoundReportResponse>} data
   * @return {void}
   */
  setBetshopRounds = (data) => {
    this.betshopRounds = data;
  };

  /**
   * @name setBetshopBets
   * @param {HttpResponse<BetReportResponse>} data
   * @return {void}
   */
  setBetshopBets = (data) => {
    this.betshopBets = data;
  };

  /**
   * @name setBetshopBetDetails
   * @param {HttpResponse<object>} data
   * @return {void}
   */
  setBetshopBetDetails = (data) => {
    this.betshopBetDetails = data;
  };

  setArcadeGamesBetDetails = (data) => {
    this.arcadeGamesBetDetails = data;
  }

  /**
   * @name setLotteryRounds
   * @param {HttpResponse<RoundReportResponse>} lotteryRounds
   * @return {void}
   */
  setLotteryRounds = (lotteryRounds) => {
    this.lotteryRounds = lotteryRounds;
  };

  /**
   * @name setLotteryBets
   * @param {HttpResponse<BetReportResponse>} lotteryBets
   * @return {void}
   */
  setLotteryBets = (lotteryBets) => {
    this.lotteryBets = lotteryBets;
  };

  /**
   * @name setLotteryBetDetails
   * @param {HttpResponse<object>} lotteryBetDetails
   * @return {void}
   */
  setLotteryBetDetails = (lotteryBetDetails) => {
    this.lotteryBetDetails = lotteryBetDetails;
  };

  /**
   * @name setTableGamesRounds
   * @param {HttpResponse<RoundReportResponse>} tableGamesRounds
   * @return {void}
   */
  setTableGamesRounds = (tableGamesRounds) => {
    this.tableGamesRounds = tableGamesRounds;
  };

  /**
   * @name setTableGamesBets
   * @param {HttpResponse<BetReportResponse>} tableGamesBets
   * @return {void}
   */
  setTableGamesBets = (tableGamesBets) => {
    this.tableGamesBets = tableGamesBets;
  };

  /**
   * @name setTableGamesBetDetails
   * @param {HttpResponse<Array<TableBetReportDetailsItem>>} tableGamesBetDetails
   * @return {void}
   */
  setTableGamesBetDetails = (tableGamesBetDetails) => {
    this.tableGamesBetDetails = tableGamesBetDetails;
  };

  /**
   * @name setTableGamesRoundDetails
   * @param {HttpResponse<TableRoundReportDetailsResponse>} tableGamesRoundDetails
   * @return {void}
   */
  setTableGamesRoundDetails = (tableGamesRoundDetails) => {
    this.tableGamesRoundDetails = tableGamesRoundDetails;
  };

  /**
   * @name resetTableGamesRoundDetails
   * @returns {void}
   */
  resetTableGamesRoundDetails = () => {
    this.setTableGamesRoundDetails(this.tableGamesRoundDetails.fetching(true));
  };

  /**
   * @name setArcadeBets
   * @param {HttpResponse<ArcadeBetReportResponse>} arcadeBets
   * @return {void}
   */
  setArcadeBets = (arcadeBets) => {
    this.arcadeBets = arcadeBets;
  };

  setArcadeRounds = (data) => {
    this.arcadeRounds = data;
  };

  /**
   * @name setFinancial
   * @param {HttpResponse<FinancialReportResponse>} financial
   * @return {void}
   */
  setFinancial = (financial) => {
    this.financial = financial;
  };

  /**
   * @name setBogRoundDetails
   * @param {HttpResponse<Object>} bogRoundDetails
   * @return {void}
   */
  setBogRoundDetails = (bogRoundDetails) => {
    this.bogRoundDetails = bogRoundDetails;
  }

  /**
   * @name setVirtualBets
   * @param {HttpResponse<VirtualReportResponse>} virtualBets
   * @return {void}
   */
  setVirtualBets = (virtualBets) => {
    this.virtualBets = virtualBets;
  }

  /**
   * @name setVirtualBetDetails
   * @param {HttpResponse<Object>} virtualBetDetails
   * @return {void}
   */
  setVirtualBetDetails = (virtualBetDetails) => {
    this.virtualBetDetails = virtualBetDetails;
  }

  /**
   * @name resetVirtualBetDetails
   * @returns {void}
   */
  resetVirtualBetDetails = () => {
    this.setVirtualBetDetails(this.virtualBetDetails.fetching(true));
  };

  /**
   * @name setVirtualRoundReports
   * @param {HttpResponse<RoundReportResponse>} virtualRoundReports
   */
  setVirtualRoundReports = (virtualRoundReports) => {
    this.virtualRoundReports = virtualRoundReports;
  }

  /**
   * @name setVirtualMarketReports
   * @param {HttpResponse<VirtualMarketReportResponse>} data
   * @return {void}
   */
  setVirtualMarketReports = (data) => {
    this.virtualMarketReports = data;
  };

  /**
   * @name setBetshopMarketReports
   * @param {HttpResponse<VirtualMarketReportResponse>} data
   * @return {void}
   */
  setBetshopMarketReports = (data) => {
    this.betshopMarketReports = data;
  };

  setPlayersReport = (data) => {
    this.playersReport = data;
  }

  setPlayersByGameReport = (data) => {
    this.playersByGameReport = data;
  }

  /**
   * @name getBogRoundReports
   * @param {RoundReportQuery} params
   * @returns {Generator<*, void, *>}
   */
  getBogRoundReports = function* (params) {
    const { concat, ...restParams } = params;
    this.setBogRounds(this.bogRounds.fetching());
    const report = yield reportsService.getBogRoundReports(restParams);
    const data = concat ? this.bogRounds.concat(report) : report;
    this.setBogRounds(this.bogRounds.fetched(data));
  };

  /**
   * @name getBogBetReports
   * @param {BogBetReportQuery} params
   * @return {Generator<*, void, *>}
   */
  getBogBetReports = function* (params) {
    const { concat, ...restParams } = params;
    this.setBogBets(this.bogBets.fetching());
    const report = yield reportsService.getBogBetReports(restParams);
    const data = concat ? this.bogBets.concat(report) : report;
    this.setBogBets(this.bogBets.fetched(data));
  };

  /**
   * @name getBetshopRoundReports
   * @param {RoundReportQuery} params
   * @returns {Generator<*, void, *>}
   */
  getBetshopRoundReports = function* (params) {
    const { concat, ...restParams } = params;
    this.setBetshopRounds(this.betshopRounds.fetching());
    const report = yield reportsService.getBetshopRoundReports(restParams);
    const data = concat ? this.betshopRounds.concat(report) : report;
    this.setBetshopRounds(this.betshopRounds.fetched(data));
  };

  /**
   * @name getBetshopBetReports
   * @param {BogBetReportQuery} params
   * @return {Generator<*, void, *>}
   */
  getBetshopBetReports = function* (params) {
    const { concat, ...restParams } = params;
    this.setBetshopBets(this.betshopBets.fetching());
    const report = yield reportsService.getBetshopBetReports(restParams);
    const data = concat ? this.betshopBets.concat(report) : report;
    this.setBetshopBets(this.betshopBets.fetched(data));
  };

  /**
   * @name getBetshopBetDetails
   * @param {TableBetReportDetailsQuery} params
   * @return {Generator<Promise<Object>, void, *>}
   */
  getBetshopBetDetails = function* (params) {
    this.setBetshopBetDetails(this.betshopBetDetails.fetching());
    const report = yield reportsService.getBetshopBetDetails(params);
    this.setBetshopBetDetails(this.betshopBetDetails.fetched(report));
  }

  /**
   * @name getBogRoundReports
   * @param {RoundReportQuery} params
   * @returns {Generator<*, void, *>}
   */
  getTableGamesRoundReports = function* (params) {
    const { concat, ...restParams } = params;
    this.setTableGamesRounds(this.tableGamesRounds.fetching());
    const report = yield reportsService.getTableGamesRoundReports(restParams);
    const data = concat ? this.tableGamesRounds.concat(report) : report;
    this.setTableGamesRounds(this.tableGamesRounds.fetched(data));
  };

  /**
   * @name getBogBetReports
   * @param {BogBetReportQuery} params
   * @return {Generator<*, void, *>}
   */
  getTableGamesBetReports = function* (params) {
    const { concat, ...restParams } = params;
    this.setTableGamesBets(this.tableGamesBets.fetching());
    const report = yield reportsService.getTableGamesBetReports(restParams);
    const data = concat ? this.tableGamesBets.concat(report) : report;
    this.setTableGamesBets(this.tableGamesBets.fetched(data));
  };

  /**
   * @name getTableGamesBetDetails
   * @param {TableBetReportDetailsQuery} params
   * @return {Generator<Array<TableBetReportDetailsItem>, void, *>}
   */
  getTableGamesBetDetails = function* (params) {
    this.setTableGamesBetDetails(this.tableGamesBetDetails.fetching());
    const report = yield reportsService.getTableGamesBetDetails(params);
    this.setTableGamesBetDetails(this.tableGamesBetDetails.fetched(report));
  };


  getArcadeGamesBetDetails = function* (params) {
    this.setArcadeGamesBetDetails(this.arcadeGamesBetDetails.fetching());
    const report = yield reportsService.getArcadeGamesBetDetails(params);
    this.setArcadeGamesBetDetails(this.arcadeGamesBetDetails.fetched(report));
  };

  /**
   * @name getTableGamesRoundDetails
   * @param {TableRoundReportDetailsQuery} params
   * @return {Generator<*, void, *>}
   */
  getTableGamesRoundDetails = function* (params) {
    this.setTableGamesRoundDetails(this.tableGamesRoundDetails.fetching());
    const report = yield reportsService.getTableGamesRoundDetails(params);
    this.setTableGamesRoundDetails(this.tableGamesRoundDetails.fetched(report));
  };

  /**
   * @name getLotteryRoundReports
   * @param {RoundReportQuery} params
   * @returns {Generator<*, void, *>}
   */
  getLotteryRoundReports = function* (params) {
    const { concat, ...restParams } = params;
    this.setLotteryRounds(this.lotteryRounds.fetching());
    const report = yield reportsService.getLotteryRoundReports(restParams);
    const data = concat ? this.lotteryRounds.concat(report) : report;
    this.setLotteryRounds(this.lotteryRounds.fetched(data));
  };

  /**
   * @name getLotteryBetReports
   * @param {BogBetReportQuery} params
   * @return {Generator<*, void, *>}
   */
  getLotteryBetReports = function* (params) {
    const { concat, ...restParams } = params;
    this.setLotteryBets(this.lotteryBets.fetching());
    const report = yield reportsService.getLotteryBetReports(restParams);
    const data = concat ? this.lotteryBets.concat(report) : report;
    this.setLotteryBets(this.lotteryBets.fetched(data));
  };

  /**
   * @name getLotteryBetDetails
   * @param {TableBetReportDetailsQuery} params
   * @return {Generator<Array<TableBetReportDetailsItem>, void, *>}
   */
  getLotteryBetDetails = function* (params) {
    this.setLotteryBetDetails(this.lotteryBetDetails.fetching());
    const report = yield reportsService.getLotteryBetDetails(params);
    this.setLotteryBetDetails(this.lotteryBetDetails.fetched(report));
  };

  /**
   * @name getSlotBetReports
   * @param {BogBetReportQuery} params
   * @return {Generator<*, void, *>}
   */
  getSlotBetReports = function* (params) {
    const { concat, ...restParams } = params;
    this.setSlotBets(this.slotBets.fetching());
    const report = yield reportsService.getSlotBetReports(restParams);
    const data = concat ? this.slotBets.concat(report) : report;
    this.setSlotBets(this.slotBets.fetched(data));
  };

  /**
   * @name getSlotBetDetails
   * @param {TableBetReportDetailsQuery} params
   * @return {Generator<Object>, void, *>}
   */
  getSlotBetDetails = function* (params) {
    this.setSlotBetDetails(this.slotBetDetails.fetching());
    const report = yield reportsService.getSlotBetDetails(params);
    this.setSlotBetDetails(this.slotBetDetails.fetched(report));
  };

  /**
   * @name getTronixBetReports
   * @param {BogBetReportQuery} params
   * @return {Generator<*, void, *>}
   */
  getTronixBetReports = function* (params) {
    const { concat, ...restParams } = params;
    this.setTronixBets(this.tronixBets.fetching());
    const report = yield reportsService.getTronixBetReports(restParams);
    const data = concat ? this.tronixBets.concat(report) : report;
    this.setTronixBets(this.tronixBets.fetched(data));
  };

  /**
   * @name getTronixBetDetails
   * @param {TableBetReportDetailsQuery} params
   * @return {Generator<Object>, void, *>}
   */
  getTronixBetDetails = function* (params) {
    this.setTronixBetDetails(this.tronixBetDetails.fetching());
    const report = yield reportsService.getTronixBetDetails(params);
    this.setTronixBetDetails(this.tronixBetDetails.fetched(report));
  };

  /**
   * @name getBogBetDetails
   * @param {Object} params
   * @return {Generator<Object>, void, *>}
   */
  getBogBetDetails = function* (params) {
    this.setBogBetDetails(this.bogBetDetails.fetching());
    const report = yield reportsService.getBogBetDetails(params);
    this.setBogBetDetails(this.bogBetDetails.fetched(report));
  };

  /**
   * @name getArcadeBetReports
   * @param {ArcadeBetReportsQuery} params
   * @return {Generator<*, void, *>}
   */
  getArcadeBetReports = function* (params) {
    const { concat, ...restParams } = params;
    this.setArcadeBets(this.arcadeBets.fetching());
    const report = yield reportsService.getArcadeBetReports(restParams);
    const data = concat ? this.arcadeBets.concat(report) : report;
    this.setArcadeBets(this.arcadeBets.fetched(data));
  };

  getArcadeRoundReports = function* (params) {
    const { concat, ...restParams } = params;
    this.setArcadeRounds(this.arcadeRounds.fetching());
    const report = yield reportsService.getArcadeRoundReports(restParams);
    const data = concat ? this.arcadeRounds.concat(report) : report;
    this.setArcadeRounds(this.arcadeRounds.fetched(data));
  };

  /**
   * @name getBogRoundDetails
   * @param {GetBogRoundDetailsQuery} params
   * @return {Generator<Promise<AxiosResponse<any>>, void, *>}
   */
  getBogRoundDetails = function* (params) {
    this.setBogRoundDetails(this.bogRoundDetails.fetching());
    const report = yield reportsService.getBogRoundDetails(params);
    this.setBogRoundDetails(this.bogRoundDetails.fetched(report));
  };

  /**
   * @name getVirtualBets
   * @param {VirtualBetReportQuery} params
   * @return {Generator<*, void, *>}
   */
  getVirtualBets = function* (params) {
    const { concat, ...restParams } = params;
    this.setVirtualBets(this.virtualBets.fetching());
    const report = yield reportsService.getVirtualBetReports(restParams);
    const data = concat ? this.virtualBets.concat(report) : report;
    this.setVirtualBets(this.virtualBets.fetched(data));
  }

  /**
   * @name getVirtualBetDetails
   * @param {TableBetReportDetailsQuery} params
   * @return {Generator<Promise<Object>, void, *>}
   */
  getVirtualBetDetails = function* (params) {
    this.setVirtualBetDetails(this.virtualBetDetails.fetching());
    const report = yield reportsService.getVirtualBetDetails(params);
    this.setVirtualBetDetails(this.virtualBetDetails.fetched(report));
  }

  /**
   * @name getVirtualRoundReports
   * @param {RoundReportQuery} params
   * @returns {Generator<*, void, *>}
   */
  getVirtualRoundReports = function* (params) {
    const { concat, ...restParams } = params;
    this.setVirtualRoundReports(this.virtualRoundReports.fetching());
    const report = yield reportsService.getVirtualRoundReports(restParams);
    const data = concat ? this.virtualRoundReports.concat(report) : report;
    this.setVirtualRoundReports(this.virtualRoundReports.fetched(data));
  };

  /**
   * @name getVirtualMarketReports
   * @param {VirtualMarketReportParams} params
   * @returns {Generator<*, void, *>}
   */
  getVirtualMarketReports = function* (params) {
    const { concat, ...restParams } = params;
    this.setVirtualMarketReports(this.virtualMarketReports.fetching());
    const report = yield reportsService.getVirtualMarketReports(restParams);
    const data = concat ? this.virtualMarketReports.concat(report) : report;
    this.setVirtualMarketReports(this.virtualMarketReports.fetched(data));
  };

  /**
   * @name getBetshopMarketReports
   * @param {VirtualMarketReportParams} params
   * @returns {Generator<*, void, *>}
   */
  getBetshopMarketReports = function* (params) {
    const { concat, ...restParams } = params;
    this.setBetshopMarketReports(this.betshopMarketReports.fetching());
    const report = yield reportsService.getBetshopMarketReports(restParams);
    const data = concat ? this.betshopMarketReports.concat(report) : report;
    this.setBetshopMarketReports(this.betshopMarketReports.fetched(data));
  };

  getPlayersReport = function* (params) {
    this.setPlayersReport(this.playersReport.fetching());
    const data = yield reportsService.getPlayersReport(params);
    this.setPlayersReport(this.playersReport.fetched(data));
  }

  getPlayersByGameReport = function* (params) {
    this.setPlayersByGameReport(this.playersByGameReport.fetching());
    const data = yield reportsService.getPlayersByGameReport(params);
    this.setPlayersByGameReport(this.playersByGameReport.fetched(data));
  }

  /**
   * @name getBetReport
   * @param {ArcadeBetReportsQuery|BogBetReportQuery} params
   * @returns {Generator<*, void, *>}
   */
  getBetReport = (params) => {
    const type = getLineType();
    switch (type) {
      case GameLineType.Arcade:
      case GameLineType.CryptoGames:
        return this.getArcadeBetReports(params);
      case GameLineType.Bog:
        return this.getBogBetReports(params);
      case GameLineType.TableGames:
        return this.getTableGamesBetReports(params);
      case GameLineType.Lottery:
        return this.getLotteryBetReports(params);
      case GameLineType.BetShop:
        return this.getBetshopBetReports(params);
      case GameLineType.Slot:
        return this.getSlotBetReports(params);
      case GameLineType.TronixZone:
        return this.getTronixBetReports(params);
      case GameLineType.Virtual:
        return this.getVirtualBets(params);
      default:
        console.error(`Invalid type-(${type})`);
        break;
    }
  };

  /**
   * @name getBetDetailsReport
   * @param {Object} params
   * @returns {Generator<*, void, *>}
   */
  getBetDetailsReport = (params) => {
    const type = getLineType();
    switch (type) {
      case GameLineType.Bog:
        return this.getBogBetDetails(params);
      case GameLineType.TableGames:
        return this.getTableGamesBetDetails(params);
      case GameLineType.Lottery:
        return this.getLotteryBetDetails(params);
      case GameLineType.BetShop:
        return this.getBetshopBetDetails(params);
      case GameLineType.Slot:
        return this.getSlotBetDetails(params);
      case GameLineType.TronixZone:
        return this.getTronixBetDetails(params);
      case GameLineType.Virtual:
        return this.getVirtualBetDetails(params)
      case GameLineType.Arcade:
        return this.getArcadeGamesBetDetails(params);
      default:
        console.error(`Invalid type-(${type})`);
        break;
    }
  };

  /**
   * @name getRoundReport
   * @param {ArcadeBetReportsQuery|BogBetReportQuery} params
   * @returns {Generator<*, void, *>}
   */
  getRoundReport = (params) => {
    const type = getLineType();
    switch (type) {
      case GameLineType.Bog:
        return this.getBogRoundReports(params);
      case GameLineType.TableGames:
        return this.getTableGamesRoundReports(params);
      case GameLineType.Arcade:
      case GameLineType.CryptoGames:
        return this.getArcadeRoundReports(params);
      case GameLineType.Lottery:
        return this.getLotteryRoundReports(params);
      case GameLineType.BetShop:
        return this.getBetshopRoundReports(params);
      case GameLineType.Virtual:
        return this.getVirtualRoundReports(params);
      default:
        console.error(`Invalid type-(${type})`);
        break;
    }
  };

  /**
   * @name getMarketReport
   * @param {VirtualMarketReportParams} params
   * @returns {Generator<*, void, *>}
   */
  getMarketReport = (params) => {
    const type = getLineType();
    switch (type) {
      case GameLineType.Virtual:
        return this.getVirtualMarketReports(params);
      case GameLineType.BetShop:
        return this.getBetshopMarketReports(params);
      default:
        console.error(`Invalid type-(${type})`);
        break;
    }
  };

  /**
   * @name resetBetReport
   * @returns {void}
   */
  resetBetReport = () => {
    this.setArcadeBets(this.arcadeBets.fetching(true));
    this.setBogBets(this.bogBets.fetching(true));
    this.setTableGamesBets(this.tableGamesBets.fetching(true));
  };

  /**
   * @name resetBetDetails
   * @returns {void}
   */
  resetBetDetails = () => {
    this.setBogBetDetails(this.bogBetDetails.fetching(true));
    this.setTableGamesBetDetails(this.tableGamesBetDetails.fetching(true));
    this.setArcadeGamesBetDetails(this.arcadeGamesBetDetails.fetching(true));
    this.setLotteryBetDetails(this.lotteryBetDetails.fetching(true));
    this.setBetshopBetDetails(this.betshopBetDetails.fetching(true));
    this.setSlotBetDetails(this.slotBetDetails.fetching(true));
    this.setTronixBetDetails(this.tronixBetDetails.fetching(true));
    this.setVirtualBetDetails(this.virtualBetDetails.fetching(true));
  };

  /**
   * @name resetSlotBetDetails
   * @returns {void}
   */
  resetSlotBetDetails = () => {
    this.setSlotBetDetails(this.slotBetDetails.fetching(true));
  };

  /**
   * @name resetRoundReport
   * @returns {void}
   */
  resetRoundReport = () => {
    this.setBogRounds(this.bogRounds.fetching(true));
    this.setTableGamesRounds(this.tableGamesRounds.fetching(true));
    this.setVirtualRoundReports(this.tableGamesRounds.fetching(true));
  };

  /**
   * @name resetTableGamesBetDetails
   * @returns {void}
   */
  resetTableGamesBetDetails = () => {
    this.setTableGamesBetDetails(this.tableGamesBetDetails.fetching(true));
  };

  /**
   * @name getLineBets
   * @returns {HttpResponse<ArcadeBetReportResponse|BetReportResponse>}
   */
  getLineBets = () => {
    const type = getLineType();
    switch (type) {
      case GameLineType.Arcade:
      case GameLineType.CryptoGames:
        return this.arcadeBets;
      case GameLineType.Bog:
        return this.bogBets;
      case GameLineType.TableGames:
        return this.tableGamesBets;
      case GameLineType.Lottery:
        return this.lotteryBets;
      case GameLineType.BetShop:
        return this.betshopBets;
      case GameLineType.Slot:
        return this.slotBets;
      case GameLineType.TronixZone:
        return this.tronixBets;
      case GameLineType.Virtual:
        return this.virtualBets
      default:
        console.error(`Invalid type-(${type})`);
        break;
    }
  };

  /**
   * @name getLineBetDetails
   * @returns {HttpResponse<Record>}
   */
  getLineBetDetails = () => {
    const type = getLineType();
    switch (type) {
      case GameLineType.Bog:
        return this.bogBetDetails;
      case GameLineType.TableGames:
        return this.tableGamesBetDetails;
      case GameLineType.Lottery:
        return this.lotteryBetDetails;
      case GameLineType.BetShop:
        return this.betshopBetDetails;
      case GameLineType.Slot:
        return this.slotBetDetails;
      case GameLineType.TronixZone:
        return this.tronixBetDetails;
      case GameLineType.Virtual:
        return this.virtualBetDetails
      case GameLineType.CryptoGames:
        return this.arcadeGamesBetDetails
      default:
        console.error(`Invalid type-(${type})`);
        break;
    }
  };

  getLineRounds = () => {
    const type = getLineType();
    switch (type) {
      case GameLineType.Bog:
        return this.bogRounds;
      case GameLineType.TableGames:
        return this.tableGamesRounds;
      case GameLineType.Arcade:
      case GameLineType.CryptoGames:
        return this.arcadeRounds;
      case GameLineType.Lottery:
        return this.lotteryRounds;
      case GameLineType.BetShop:
        return this.betshopRounds;
      case GameLineType.Virtual:
        return this.virtualRoundReports;
      default:
        console.error(`Invalid type-(${type})`);
        break;
    }
  };

  /**
   * @name getLineMarkets
   * @returns {HttpResponse<VirtualMarketReportResponse>}
   */
  getLineMarkets = () => {
    const type = getLineType();
    switch (type) {
      case GameLineType.Virtual:
        return this.virtualMarketReports;
      case GameLineType.BetShop:
        return this.betshopMarketReports;
      default:
        console.error(`Invalid type-(${type})`);
        break;
    }
  };
}

export const reportsStore = new ReportsStore();