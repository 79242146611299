import { ApiVersion } from '../../core/enums/ApiVersion';
import { DEFAULT_HEADERS, httpClient, httpClientV2 } from '../httpClient/httpClient';
import {
  boostedJackpotUrl,
  boostedJackpotDetailsUrl,
  playerBoostedJackpotReportsUrl,
  cancelBoostedJackpotUrl,
  exportPlayerBoostedJackpotReportUrl
} from '../../core/constants/endpoints';
import { abortFetch } from '../../core/helpers/abortController';
import { ABORT_API_KEYS } from '../../core/constants/abortApiKeys';
import { combineQuery } from '../../core/helpers/queryHelper';

class BoostedJackpotService {

  async getBoostedJackpots(params = {}, option = { version: ApiVersion.V1 }) {
    const signal = abortFetch(ABORT_API_KEYS.GetBoostedJackpot);
    return await httpClientV2.get(boostedJackpotUrl(option), { params, signal });
  }

  async addBoostedJackpot(data = {}, option = { version: ApiVersion.V1 }) {
    return await httpClient.post(boostedJackpotUrl(option),  data);
  }

  async editBoostedJackpot(data = {}, option = { version: ApiVersion.V1 }) {
    return await httpClient.put(boostedJackpotUrl(option),  data);
  }

  async cancelBoostedJackpot(id, option = { version: ApiVersion.V1 }) {
    return await httpClient.put(cancelBoostedJackpotUrl(id, option));
  }

  async getPlayerBoostedJackpotReports(params = {}, option = { version: ApiVersion.V1 }) {
    const signal = abortFetch(ABORT_API_KEYS.GetBoostedJackpotPlayerReport);
    return await httpClientV2.get(playerBoostedJackpotReportsUrl(option), { params, signal });
  }

  async exportPlayerBoostedJackpotReport(params, option = { version: ApiVersion.V1 }) {
    // axios does not support ReadableStream, so I use Fetch API to get excel file Stream.
    return await fetch(`${exportPlayerBoostedJackpotReportUrl(option)}${combineQuery(params)}`, {
      credentials: 'include',
      headers: {
        ...DEFAULT_HEADERS
      }
    });
  }

  async getBoostedJackpotDetails(id, option = { version: ApiVersion.V1 }) {
    return await httpClientV2.get(boostedJackpotDetailsUrl(id, option));
  }

}

export const boostedJackpotService = new BoostedJackpotService();
