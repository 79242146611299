import { DEFAULT_HEADERS, httpClient, httpClientV2 } from '../httpClient/httpClient';
import {
  getTransactionsReportsChartUrl,
  getTransactionsReportsDashboardUrl,
  getTransactionsReportsFinancialsUrl,
  getTransactionsReportsPartnerActivityUrl,
  getTransactionsReportsResultsUrl,
  getTransactionsReportsUrl,
  getTransactionsReportsTopBetsUrl,
  getTransactionsReportUsersCountUrl,
  getTransactionsReportsGamesUrl,
  getPlayersGameLineTransactionsReportUrl,
  getTransactionsReportsGameLinesUrl,
  getPartnerComparisonReportUrl,
  getPartnerComparisonReportByGameUrl,
  exportFinancialReportUrl,
  generateFinancialInvoiceReportUrl,
  getFinancialInvoiceReportInfoUrl,
  getTransactionsCurrencyReportUrl,
  getGameComparisonReportUrl, getTransactionCurrencyReportsByPartnersUrl,
  exporGameComparisonReportUrl,
  exporPartnerComparisonReportUrl,
} from '../../core/constants/endpoints';
import { ApiVersion } from '../../core/enums/ApiVersion';
import { abortFetch } from '../../core/helpers/abortController';
import { ABORT_API_KEYS } from '../../core/constants/abortApiKeys';
import { combineQuery } from '../../core/helpers/queryHelper';

class TransactionsService {
  /**
   * @name getTransactionsReports
   * @param {TransactionsReportsParams} params
   * @param {ApiOption=} option
   * @return {Promise<TransactionsReportsResponse>}
   */
  async getTransactionsReports(params, option = { version: ApiVersion.V1 }) {
    const signal = abortFetch(ABORT_API_KEYS.GetTransactionsReports);
    return await httpClientV2.get(getTransactionsReportsUrl(option), { params, signal });
  }

  /**
   * @name getTransactionsReportsResults
   * @param {TransactionsReportsResultsParams} params
   * @param {ApiOption=} option
   * @return {Promise<TransactionsReportsResultsResponse>}
   */
  async getTransactionsReportsResults(params, option = { version: ApiVersion.V1 }) {
    return await httpClientV2.get(getTransactionsReportsResultsUrl(option), { params });
  }

  /**
   * @name getTransactionsReportsDashboard
   * @param {TransactionsReportsDashboardParams} params
   * @param {ApiOption=} option
   * @return {Promise<ResponseReportsDashboard>}
   */
  async getTransactionsReportsDashboard(params, option = { version: ApiVersion.V1 }) {
    const signal = abortFetch(ABORT_API_KEYS.GetDashboardReport);
    return await httpClientV2.get(getTransactionsReportsDashboardUrl(option), { params, signal });
  }
  
  /**
   * @name getTransactionsReportsGameLines
   * @param {TransactionsReportsDashboardParams} params
   * @param {ApiOption=} option
   * @return {Promise<Array<TransactionsReportsGame>>}
   */
  async getTransactionsReportsGameLines(params, option = { version: ApiVersion.V1 }) {
    const signal = abortFetch(ABORT_API_KEYS.GetDashboardGameLinesReport);
    return await httpClientV2.get(getTransactionsReportsGameLinesUrl(option), { params, signal });
  }

  /**
   * @name getTransactionsReportsGames
   * @param {TransactionsReportsDashboardParams} params
   * @param {ApiOption=} option
   * @return {Promise<Array<TransactionsReportsGame>>}
   */
  async getTransactionsReportsGames(params, option = { version: ApiVersion.V1 }) {
    const signal = abortFetch(ABORT_API_KEYS.GetDashboardGamesReport);
    return await httpClientV2.get(getTransactionsReportsGamesUrl(option), { params, signal });
  }

  /**
   * @name getTransactionsReportsFinancials
   * @param {TransactionsReportsFinancialsParams} params
   * @param {ApiOption=} option
   * @return {Promise<Array<ReportsFinancialsResult>>}
   */
  async getTransactionsReportsFinancials(params, option = { version: ApiVersion.V1 }) {
    const signal = abortFetch(ABORT_API_KEYS.GetFinancialReports);
    return await httpClientV2.get(getTransactionsReportsFinancialsUrl(option), { params, signal });
  }

  /**
   * @name exportFinancialReport
   * @param {TransactionsReportsFinancialsParams} params
   * @param {ApiOption=} option
   * @return Excel file
   */
  async exportFinancialReport(params, option = { version: ApiVersion.V1 }) {
    // axios does not support ReadableStream, so I use Fetch API to get excel file Stream. 
    return await fetch(`${exportFinancialReportUrl(option)}${combineQuery(params)}`, {
      credentials: 'include',
      headers: {
        ...DEFAULT_HEADERS
      }
    });
  }

  async exportPartnerComparisonReport(params, option = { version: ApiVersion.V1 }) {
    // axios does not support ReadableStream, so I use Fetch API to get excel file Stream.
    return await fetch(`${exporPartnerComparisonReportUrl(option)}${combineQuery(params)}`, {
      credentials: 'include',
      headers: {
        ...DEFAULT_HEADERS
      }
    });
  }

  async exportGameComparisonReport(params, option = { version: ApiVersion.V1 }) {
    // axios does not support ReadableStream, so I use Fetch API to get excel file Stream.
    return await fetch(`${exporGameComparisonReportUrl(option)}${combineQuery(params)}`, {
      credentials: 'include',
      headers: {
        ...DEFAULT_HEADERS
      }
    });
  }

  /**
   * @name getFinancialInvoiceReportInfo
   * @param {ApiOption} option
   * @returns {Promise<void>}
   */
  async getFinancialInvoiceReportInfo(option = { version: ApiVersion.V1 }) {
    return await httpClient.get(getFinancialInvoiceReportInfoUrl(option));
  }

  /**
   * @name generateFinancialInvoiceReport
   * @param {GenerateFinancialInvoiceReportData} data
   * @param {ApiOption} option
   * @returns {Promise<void>}
   */
  async generateFinancialInvoiceReport(data = {}, option = { version: ApiVersion.V1 }) {
    return await httpClient.post(generateFinancialInvoiceReportUrl(option), data);
  }

  /**
   * @name getTransactionsReportsChart
   * @param {TransactionsReportsChartParams} params
   * @param {ApiOption=} option
   * @return {Promise<Array<ReportChartResult>>}
   */
  async getTransactionsReportsChart(params, option = { version: ApiVersion.V1 }) {
    return await httpClientV2.get(getTransactionsReportsChartUrl(option), { params });
  }

  /**
   * @name getTransactionsReportsTopBets
   * @param {TransactionsReportsTopBetsParams} params
   * @param {ApiOption=} option
   * @return {Promise<Array<TopBetsResult>>}
   */
  async getTransactionsReportsTopBets(params, option = { version: ApiVersion.V1 }) {
    return await httpClientV2.get(getTransactionsReportsTopBetsUrl(option), { params });
  }

  /**
   * @name getTransactionsReportsPartnerActivity
   * @param {TransactionsReportsPartnerActivityParams} params
   * @param {ApiOption=} option
   * @return {Promise<Array<PartnerActivityResult>>}
   */
  async getTransactionsReportsPartnerActivity(params, option = { version: ApiVersion.V1 }) {
    return await httpClientV2.get(getTransactionsReportsPartnerActivityUrl(option), { params });
  }

  /**
   * @name getTransactionsReportsUsersCount
   * @param {TransactionsReportsDashboardParams} params
   * @param {ApiOption=} option
   * @return {Promise<number>}
   */
  async getTransactionsReportsUsersCount(params, option = { version: ApiVersion.V1 }) {
    return await httpClientV2.get(getTransactionsReportUsersCountUrl(option), { params });
  }

  /**
   * @name getPlayersGameLineTransactionsReport
   * @param {PlayerTransactionsReportsByGameLineParams} params
   * @param {ApiOption} option
   * @return {Promise<PlayerTransactionsReportsByGameLineResponse>}
   */
  async getPlayersGameLineTransactionsReport(params, option = { version: ApiVersion.V1 }) {
    return await httpClientV2.get(getPlayersGameLineTransactionsReportUrl(option), { params });
  }

  async getPartnerComparisonReport(params, option = { version: ApiVersion.V1 }) {
    const signal = abortFetch(ABORT_API_KEYS.GetPartnerComparisonReports);
    return await httpClientV2.get(getPartnerComparisonReportUrl(option), { params, signal });
  }

  async getPartnerComparisonByGameReport(params, option = { version: ApiVersion.V1 }) {
    return await httpClientV2.get(getPartnerComparisonReportByGameUrl(option), { params });
  }

  async getTransactionsCurrencyReport(params, option = { version: ApiVersion.V1 }) {
    return await httpClient.get(getTransactionsCurrencyReportUrl(option), { params });
  }

  async getGameComparisonReport(params, option = { version: ApiVersion.V1 }) {
    const signal = abortFetch(ABORT_API_KEYS.GetGameComparisonReport);
    return await httpClientV2.get(getGameComparisonReportUrl(option), { params, signal });
  }

  async getTransactionCurrencyReportsByPartners(params, option = { version: ApiVersion.V1 }) {
    return await httpClient.get(getTransactionCurrencyReportsByPartnersUrl(option), { params });
  }

}

export const transactionsService = new TransactionsService();
