import { UserBooleanType, UserType } from '../enums/UserType';
import { FinancialReportBetType } from '../enums/financialReportBetType';
import { TranslationKeys } from './translationKeys';

export const BACKOFFICE_APPLICATION_ID = process.env.REACT_APP_ENV === 'production' ? 7 : 9;

export const BEARER = 'Bearer';

export const AUTH = 'AUTH';
export const ACCESS = 'access';

export const PartnerViewMods = ['CLASSIC_MODE', 'ALTERNATI'];

export const betTypeEnumData = [
  'Red_2',
  'Black_2',
  'Number_2',
  'Hi_2',
  'Red_3',
  'Black_3',
  'Number_3',
  'Hi_3',
  'Lo_3',
  'Red_4',
  'Black_4',
  'Number_4',
  'Hi_4',
  'Lo_4',
  'Red_5',
  'Black_5',
  'Number_5',
  'Hi_5',
  'Lo_5',
  'Red_6',
  'Black_6',
  'Number_6',
  'Hi_6',
  'Lo_6',
  'Red_7',
  'Black_7',
  'Number_7',
  'Hi_7',
  'Lo_7',
  'Red_8',
  'Black_8',
  'Number_8',
  'Hi_8',
  'Lo_8',
  'Red_9',
  'Black_9',
  'Number_9',
  'Hi_9',
  'Lo_9',
  'Red_J',
  'Black_J',
  'J',
  'Hi_J',
  'Lo_J',
  'Red_Q',
  'Black_Q',
  'Q',
  'Hi_Q',
  'Lo_Q',
  'Red_K',
  'Black_K',
  'K',
  'Hi_K',
  'Lo_K',
  'Red_A',
  'Black_A',
  'A',
  'Lo_A',
  'Red',
  'Black',
  'Numbers_2_to_9',
  'Joker',
  'J_Q_K_A',
  'J_Q',
  'K_A',
];

export const userTypeData = [
  {
    id: UserType.Test,
    name: 'TEST',
  },
  {
    id: UserType.Real,
    name: 'REAL',
  },
];

export const userTypeDataWithBoolean = [
  {
    id: UserBooleanType.Test,
    name: 'TEST',
  },
  {
    id: UserBooleanType.Real,
    name: 'REAL',
  },
];

export const financialReportsWithBetsData = [
  {
    id: FinancialReportBetType.WithBets,
    name: TranslationKeys.WITH_BETS,
  },
  {
    id: FinancialReportBetType.WithoutBets,
    name: TranslationKeys.WITHOUT_BETS,
  },
];

export const DATE_FORMAT_D_M_Y = 'DD/MM/YYYY';
export const DATE_FORMAT_D_M_Y_H_M_S = 'DD/MM/YYYY HH:mm:ss';
export const DATE_FORMAT_BACK = 'YYYY-MM-DDTHH:mm:ss';
export const DATE_FORMAT_LONG = 'DD-MM-YYYY HH:mm:ss';

export const DATE_FORMATS = {
  TABLE_SHORT: 'DD/MM/YYYY',
  TABLE_SHORT_TIME: 'DD/MM/YYYY HH:mm:ss',
  TABLE_SHORT_TIME_BACK: 'YYYY/MM/DD HH:mm:ss',
  TABLE_LONG: 'DD/MM/YYYY HH:mm',
  INPUT_SHORT: 'YYYY-MM-DD',
  INPUT_SHORT_TIME: 'DD/MM/YYYY HH:mm:ss',
  INPUT_LONG: 'YYYY-MM-DD HH:mm',
  TIME: 'HH:mm:ss',
};

// for notifications
export const SCROLL_INSURANCE = 10;
export const MAX_BADGE_COUNT = 99;

export const DEFAULT_TAKE = 20;

export const FREE_BET_MAX_IMPORT_COUNT = 1000;

export const noop = () => void 0;

export const stopPropagation = (event) => event.stopPropagation();

export const MAX_IMAGE_UPLOAD_SIZE_BY_KB = 400;

export const MAX_GAME_THUMBNAIL_BY_MB = 3;

export const MAX_AMOUNT_FILTER = 10_000_000; // 10 mln
export const MAX_ID_FILTER = 999_999_999;

export const MONTHS = [
  TranslationKeys.JANUARY,
  TranslationKeys.FEBRUARY,
  TranslationKeys.MARCH,
  TranslationKeys.APRIL,
  TranslationKeys.MAY,
  TranslationKeys.JUNE,
  TranslationKeys.JULY,
  TranslationKeys.AUGUST,
  TranslationKeys.SEPTEMBER,
  TranslationKeys.OCTOBER,
  TranslationKeys.NOVEMBER,
  TranslationKeys.DECEMBER
];

export const JACKPOT_CARD_ACCUMULATION_MAX_PERCENT = 50;