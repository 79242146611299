import { permissionStore } from '../../module';

export const filterUnselectedPermissions = (allPermissionsWithGroups, selectedPermissions) => {
  const allPermissions = allPermissionsWithGroups.reduce((acc, curr) => {
    const items = curr.permissions ? curr.permissions?.map((permission) => permission) : [curr];
    return [ ...items, ...acc, ];
  }, []);
  const unselectedPermissions = allPermissions.filter(({ key: allKey }) => selectedPermissions.every(({ key }) => allKey !== key));
  return unselectedPermissions;
};

export const groupPermissions = (data) => {
  const groupObj = {};
  data.forEach((permission) => {
    const { group } = permission;
    if (groupObj[group]?.permissions) {
      groupObj[group].permissions.push(permission);
    } else {
      groupObj[group] = { group, permissions: [ permission ] };
    }
  });
  return Object.values(groupObj);
};

export const mapPermissionsToTransferListDataModel = (data, translateFn) => {
  const translate = translateFn || (arg => arg);
  return data.map(({ group, permissions }) => ({
    label: group,
    value: group,
    children: permissions?.map(({ key, name }) => ({
      value: key,
      label: translate(name)
    }))
  }))
};

export const mapGroupPermissionsToAPIModel = (data) => {
  return data.reduce((acc, curr) => {
    const items = curr.children ? curr.children?.map(({ value }) => ({ key: value })) : [{ key: curr.value }];
    return [...items, ...acc,];
  }, []);
};

/**
 * @name hasPermission
 * @param {Array<string>} requiredPermissions 
 * @param {boolean} [checkAll]
 * @returns boolean
 */
export const hasPermission = (requiredPermissions, checkAll = true) => {
  const usersPermissions = permissionStore.permissions.data;
  if (!requiredPermissions) return true;
  if (!usersPermissions) return false;
  const checkMethod = checkAll ? Array.prototype.every : Array.prototype.some;
  return checkMethod.call(requiredPermissions, (permission => {
    return usersPermissions.find(userPermission => userPermission.key === permission);
  }));
};
