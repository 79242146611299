import { action, makeObservable, observable, flow, computed } from 'mobx';

import { HttpResponse } from '../../core/classes/HttpResponse';
import { languagesService } from './languagesService';

class LanguagesStore {

  /** @type {HttpResponse<Object>}*/
  translations = new HttpResponse({});

  /** @type {HttpResponse<Array<Language>>}*/
  languages = new HttpResponse({ count: 0, items: [] });

  /**@type {HttpResponse<LanguageResponse>}*/
  language = new HttpResponse({ applicationIds: [] });

  /** @type {HttpResponse<Array<Language>>}*/
  allLanguages = new HttpResponse([]);

  constructor() {
    makeObservable(this, {
      translations: observable,
      allLanguages: observable,
      languages: observable,
      language: observable,
      allLanguagesMap: computed,
      setTranslations: action.bound,
      setAllLanguages: action.bound,
      setLanguages: action.bound,
      setLanguage: action.bound,
      getAllLanguages: flow,
      getLanguages: flow,
      getLanguage: flow,
    });
  }

  /**
   * @name setTranslations
   * @param {HttpResponse<Object>} translations
   */
  setTranslations = (translations) => {
    this.translations = translations;
  };

  /**
   * @name setAllLanguages
   * @param {Array<Language>} data
   */
  setAllLanguages = (data) => {
    this.allLanguages = data;
  };

  get allLanguagesMap() {
    return this.allLanguages.data.reduce((map, language) => {
      const { code, uid } = language;
      map[uid] = language;
      map[code] = language;
      return map;
    }, {});
  }

  /**
   * @name setLanguages
   * @param {Array<Language>} languages
   */
  setLanguages = (languages) => {
    this.languages = languages;
  };

  /**
   * @name setLanguage
   * @param {string} language
   */
  setLanguage = (language) => {
    this.language = language;
  };

  /**
   * @name getLanguages
   * @param {LanguagesQuery=} params
   * @returns {Generator<Promise<Array<Language>>, void, *>}
   */
  getAllLanguages = function* (params) {
    this.setAllLanguages(this.allLanguages.fetching());
    const data = yield languagesService.getLanguages(params);
    this.setAllLanguages(this.allLanguages.fetched(data.items));
  };

  /**
   * @name getLanguages
   * @param {LanguagesQuery=} params
   * @returns {Generator<Promise<Array<Language>>, void, *>}
   */
  getLanguages = function* (params) {
    this.setLanguages(this.languages.fetching());
    const languages = yield languagesService.getLanguages(params);
    this.setLanguages(this.languages.fetched(languages));
  };

  /**
   * @name getLanguage
   * @param {string} languageId
   * @returns {Generator<Promise<LanguageResponse>, void, *>}
   */
  getLanguage = function* (languageId) {
    this.setLanguage(this.language.fetching());
    const language = yield languagesService.getLanguage(languageId);
    this.setLanguage(this.language.fetched(language));
  };

  /**
   * @name addLanguage
   * @param {LanguagesRequestBody} data
   * @returns {Promise<void>}
   */
  addLanguage = (data) => {
    return languagesService.addLanguages(data);
  };

  /**
   * @name editLanguage
   * @param {string} id
   * @param {LanguagesRequestBody} data
   * @returns {Promise<void>}
   */
  editLanguage = (id, data) => {
    return languagesService.editLanguage(id, data);
  };

  /**
   * @name deleteLanguage
   * @param {string} id
   * @returns {Promise<void>}
   */
  deleteLanguage = (id) => {
    return languagesService.deleteLanguage(id);
  };
}

export const languagesStore = new LanguagesStore();
