import React, { useCallback, useMemo, useState } from 'react';
import { ExtendedInput } from '@geneui/components';

import { formatNumber } from '../../../../core/helpers/formatNumber';
import { REGEX_CONSTANTS } from '../../../../core/constants/RegEx';
import { noop } from '../../../../core/constants/util';

export const NumberInput = ({
  max,
  value,
  onChange = noop,
  preventFloats = false,
  onInputValidationRegEx,
  labelAppearance = 'swap',
  preventNegatives = false,
  formatWhenBlurred = false,
  floatingPoints = Infinity,
  ...props
}) => {

  const [ focused, setFocused ] = useState(false);

  const numberRegEx = useMemo(() => {
    if (preventFloats && preventNegatives) {
        return REGEX_CONSTANTS.PositiveAndInteger;
    }
    if (preventFloats || (!preventFloats && floatingPoints === 0)) {
        return REGEX_CONSTANTS.Integer;
    }
    if (preventNegatives) {
        return REGEX_CONSTANTS.Positive;
    }
    return REGEX_CONSTANTS.AllNumbers;
  }, [preventFloats, preventNegatives, floatingPoints]);

  const handleChange = useCallback((event) => {
    const { value } = event.target;

    // onInput RegEx validation
    if (onInputValidationRegEx && !onInputValidationRegEx?.test(value)) return;

    // max value validation
    if (max && +value > max) return;

    // numberRegEx validation
    if (!numberRegEx.test(value)) return;
    onChange(event);
  }, [onInputValidationRegEx, max, onChange, numberRegEx]);

  const handleFocus = useCallback(() => {
    if (!formatWhenBlurred) return; 
    setFocused(true)
  }, [formatWhenBlurred]);

  const handleBlur = useCallback(() => {
    if (!formatWhenBlurred) return; 
    setFocused(false)
  }, [formatWhenBlurred]);

  const formatInputNumber = useCallback(() => {
    if (!value && value !== 0) return '';
    return formatNumber(value);
  }, [value]);

  const inputType = useMemo(() => (
    (formatWhenBlurred && !focused) ? 'text' : 'number'
  ), [formatWhenBlurred, focused]);

  const inputValue = useMemo(() => (
    (formatWhenBlurred && !focused) ? formatInputNumber(value) : value
  ), [formatWhenBlurred, focused, formatInputNumber, value]);

  return (
    <ExtendedInput
      type={inputType}
      value={inputValue}
      onBlur={handleBlur}
      onFocus={handleFocus}
      onChange={handleChange}
      labelAppearance={labelAppearance}
      {...props}
    />
  );
};
