import React, { useCallback, useMemo, useState } from 'react';
import { useDrop } from 'react-dnd';

import { PGTransferListItemWrapper } from '../pg-transfer-list-item-wrapper';
import { PGTransferListSectionHeader } from './section-header';
import { PGTransferListItem } from '../pg-transfer-list-item';
import { CustomDragLayer } from '../custom-drag-layer';
import { PGTransferListSearch } from './list-search';
import { Empty } from '../../empty';

export const PGTransferListSection = ({
  data,
  title,
  onDrop,
  onSelect,
  disabled,
  selectedCount,
  selectedItems,
  containerName,
  oppositeContainerName
}) => {

  const [{ isOver }, drop] = useDrop({
    accept: [`ITEM_${oppositeContainerName}`],
    drop: onDrop,
    collect: monitor => ({
      isOver: !!monitor.isOver(),
    }),
  });

  const [ searchValue, setSearchValue ] = useState('');
  
  const filteredData = useMemo(() => {
    if (!searchValue) return data;
    const lowerCaseSearchValue = searchValue.toLowerCase();
    return data.filter(({ label, value }) => {
      return `${label}`.toLowerCase().includes(lowerCaseSearchValue) || `${value}`.toLowerCase().includes(lowerCaseSearchValue);
    })
  }, [searchValue, data]);

  const totalCount = useMemo(() => {
    return data.reduce((acc, curr) => {
      return acc + (curr.children?.length || 1);
    }, 0);
  }, [data]);

  const isAllSelected = useMemo(() => {
    return totalCount === selectedCount;
  }, [totalCount, selectedCount]);

  const singleSelectedName = useMemo(() => {
    return Object.values(selectedItems)[0]?.label;
  }, [selectedItems]);

  const onAllSelect = useCallback((checked) => {
    const mappedData = data.reduce((acc, curr) => {
      const childrenOrSelf = curr?.children || [ curr ];
      return [ ...acc, ...childrenOrSelf ];
    }, []);
    onSelect(mappedData, checked);
  }, [data, onSelect]);

  return (
    <div className="pg-transfer-list-section">
      <PGTransferListSectionHeader
        title={title}
        totalCount={totalCount}
        selectedCount={selectedCount}
      />
      <PGTransferListSearch onChange={setSearchValue} />
      <CustomDragLayer
        selectedCount={selectedCount}
        containerName={containerName}
        singleSelectedName={singleSelectedName}
      />
      <div ref={drop} className="pg-transfer-list-items">
        {
          filteredData.length ? (
            <>
            {
              !disabled && (
                <PGTransferListItem
                  noPadding
                  label="Select All"
                  withBorder={false}
                  onSelect={onAllSelect}
                  selected={isAllSelected}
                />
              )
            }
              {
                filteredData.map(({ label, value, children }) => (
                  <PGTransferListItemWrapper
                    key={value}
                    value={value}
                    label={label}
                    disabled={disabled}
                    children={children}
                    onSelect={onSelect}
                    selectedItems={selectedItems}
                    containerName={containerName}
                  />
                ))
              }
            </>
          ) : (
            <Empty
              size="medium"
              appearance="greyscale"
              className="pg-transfer-list-empty"
            />
          )
        }
      </div>
    </div>
  );
};
