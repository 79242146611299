import { TranslationKeys as T } from "../constants/translationKeys";

export const LicenseType = {
  None: 0,
  BrazilGLA: 1
};

export const LicenseTypeNames = {
  [LicenseType.None]: T.NONE,
  [LicenseType.BrazilGLA]: 'Brazil GLA'
};
