import styled, { css } from 'styled-components';

export const NavigationParent = styled.section`
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;
  ${props => props.isMobile && css`
    overflow: auto;
  `}
`;

export const Content = styled.section`
  margin: 0 auto;
  display: inline-flex;
  padding: 20px 0 0;
  align-items: center;
  ${props => props.isMobile && css`
    width: 100%;
    padding: 10px 0;
  `}
`;

export const Item = styled.section`
  color: ${props => props.active ? '#1472e4' : '#3C4043'};
  display: flex;
  align-items: center;
  grid-gap: 10px;
  font-weight: 600;
  padding: 10px 20px;
  border-radius: 10px;
  min-width: 80px;
  height: 36px;
  transition-duration: .15s;
  cursor: pointer;
  ${props => props.isMobile && css`
    width: 100px;
    min-width: auto;
  `}
  &:hover {
    color: #1473E6;
  }
`;

export const SubContent = styled.section`
  padding: 10px 0;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  grid-gap: 10px;
`;

export const SubItem = styled.section`
  color: ${props => props.active ? '#1472e4' : '#3C4043'};
  font-weight: 600;
  padding: 10px 20px;
  transition-duration: .15s;
  cursor: pointer;

  &:hover {
    color: #1473E6;
  }
`;
