import { TranslationKeys as T } from '../constants/translationKeys';

export const GameCategory = {
  BetOnGames: 0,
  Arcade: 1,
  BetOnLottery: 2,
  SportFan: 3,
  Slot: 4,
  TableGames: 5,
  BetShop: 6,
  VirtualGames: 7,
  CryptoGames: 8,
  Bonus: 9,
  Instant: 10,
  TronixZone: 11,
  VirtualSports: 12,
  Predictor: 13
};

export const GameCategoryNames = {
  [GameCategory.BetOnGames]: T.BET_ON_GAMES,
  [GameCategory.Arcade]: T.ARCADE,
  [GameCategory.BetOnLottery]: T.BET_ON_LOTTERY,
  [GameCategory.SportFan]: T.SPORT_FUN,
  [GameCategory.Slot]: T.SLOT,
  [GameCategory.TableGames]: T.TABLE_GAMES,
  [GameCategory.BetShop]: T.BET_SHOP,
  [GameCategory.VirtualGames]: T.VIRTUAL_GAMES,
  [GameCategory.CryptoGames]: T.CRYPTO_GAMES,
  [GameCategory.Bonus]: T.BONUS,
  [GameCategory.Instant]: T.INSTANT,
  [GameCategory.TronixZone]: T.TRONIX_ZONE,
  [GameCategory.VirtualSports]: T.VIRTUAL_SPORTS,
  [GameCategory.Predictor]: T.PREDICTOR,
};
