import { PERMISSIONS } from './permissions';
import { TranslationKeys } from './translationKeys';

export const navigations = [
  {
    text: TranslationKeys.DASHBOARD,
    to: '/dashboard',
    path: '/dashboard',
    icon: 'bc-icon-dashboard',
    permissions: [PERMISSIONS.ViewDashboard],
    showOnMobile: true
  },
  {
    text: TranslationKeys.PLATFORM,
    to: '/platform',
    path: '/platform',
    icon: 'bc-icon-players-rmt-36',
    permissions: [
      PERMISSIONS.ViewPlatformSettings,
      PERMISSIONS.ViewPartnerSettings,
      PERMISSIONS.ViewGames,
      PERMISSIONS.ViewGameTypes
    ]
  },
  {
    text: TranslationKeys.REPORTS,
    to: '/reports',
    path: '/reports',
    icon: 'bc-icon-report',
    permissions: [
      PERMISSIONS.ViewUserReport,

      PERMISSIONS.ViewBogBetReport,
      PERMISSIONS.ViewBogRoundReport,

      PERMISSIONS.ViewArcadeBetReport,

      PERMISSIONS.ViewTableBetReport,
      PERMISSIONS.ViewTableRoundReport,

      PERMISSIONS.ViewLottoBetReport,
      PERMISSIONS.ViewLottoRoundReport,

      PERMISSIONS.ViewBetshopBetReport,
      PERMISSIONS.ViewBetshopRoundReport,
      PERMISSIONS.ViewBetshopMarketsReport,

      PERMISSIONS.ViewVirtualBetReport,
      PERMISSIONS.ViewVirtualRoundReport,
      PERMISSIONS.ViewVirtualMarketsReport,

      PERMISSIONS.ViewSlotBetReport,

      PERMISSIONS.ViewTronixBetReport,

      PERMISSIONS.ViewCryptoGamesBetReport,
      PERMISSIONS.ViewCryptoGamesRoundReport,

      PERMISSIONS.ViewFinancialReport,
      PERMISSIONS.ViewTransactionReport,
      PERMISSIONS.ViewFreebetReport,
      PERMISSIONS.ViewPlayerPromoReport,
      PERMISSIONS.ViewTournamentReport,
      PERMISSIONS.ViewPlayerJackpotReport,
      PERMISSIONS.ViewBoostedJackpotPlayerReport,
      PERMISSIONS.ViewComparisonReport,
      PERMISSIONS.ViewCashbackReport,
      PERMISSIONS.ViewCashbackPlayerReport,
    ],
    showOnMobile: true
  },
  {
    text: TranslationKeys.ADMIN,
    to: '/admin',
    path: '/admin',
    icon: 'bc-icon-user',
    permissions: [
      PERMISSIONS.ViewUserManagement,
      PERMISSIONS.ViewUnderMaintenance,
      PERMISSIONS.ViewStaticContent,
      PERMISSIONS.ViewLanguages,
      PERMISSIONS.ViewApplications
    ]
  },
  {
    text: TranslationKeys.BONUS,
    to: '/bonus',
    path: '/bonus',
    icon: 'bc-icon-promotional-bonus-48',
    permissions: [
      PERMISSIONS.ViewBonusIsland,
      PERMISSIONS.ViewBonusIslandList,
      PERMISSIONS.ViewFreebet
    ]
  },
  {
    text: TranslationKeys.TRANSLATION_MANAGER,
    to: '/translation-tool',
    path: '/translation-tool',
    icon: 'bc-icon-note',
    permissions: [PERMISSIONS.ViewTranslations]
  },
  {
    text: TranslationKeys.PG_TOURNAMENTS,
    to: '/pg-tournament',
    path: '/pg-tournament',
    icon: 'bc-icon-assign-tournament',
    permissions: [PERMISSIONS.ViewTournamentList],
  },
  {
    text: TranslationKeys.POPUP_MANAGER,
    to: '/popup-manager',
    path: '/popup-manager',
    icon: 'bc-icon-pop-ups',
    permissions: [PERMISSIONS.ViewPopupManager],
  },
  {
    text: TranslationKeys.PREDICTOR,
    to: '/predictor',
    path: '/predictor',
    icon: 'bc-icon-octopus',
    permissions: [
      PERMISSIONS.ViewPredictorPredictions,
      PERMISSIONS.ViewPredictorRounds,
      PERMISSIONS.ViewPredictorMatches,
      PERMISSIONS.ViewPredictorTeams,
      PERMISSIONS.ViewPredictorLeagues,
      PERMISSIONS.ViewPredictorConfiguration,
      PERMISSIONS.ViewPredictorLeaderboard,
      PERMISSIONS.UpdatePredictorRound,
      PERMISSIONS.EditPredictorConfiguration,
      PERMISSIONS.EditPredictorLeague,
      PERMISSIONS.EditPredictorMatch,
      PERMISSIONS.EditPredictorTeam
    ],
  },
  {
    text: TranslationKeys.PG_LOBBY,
    to: '/pg-lobby',
    path: '/pg-lobby',
    icon: 'bc-icon-favorite-fill',
    permissions: [PERMISSIONS.ViewLobbyList],
  },
];
