import { action, flow, makeObservable, observable } from 'mobx';

import { HttpResponse } from '../../core/classes/HttpResponse';
import { permissionTemplatesService } from './service';

class PermissionTemplatesStore {

  permissionTemplates = new HttpResponse({ items: [], count: 0 });

  allPermissionTemplates = new HttpResponse([]);

  permissionTemplateDetails = new HttpResponse({});

  constructor() {
    makeObservable(this, {

      permissionTemplates: observable,
      setPermissionTemplates: action.bound,
      getPermissionTemplates: flow,

      allPermissionTemplates: observable,
      setAllPermissionTemplates: action.bound,
      getAllPermissionTemplates: flow,

      permissionTemplateDetails: observable,
      setPermissionTemplateDetails: action.bound,
      getPermissionTemplateDetails: flow

    });
  }

  setPermissionTemplates = (data) => {
    this.permissionTemplates = data;
  }
  getPermissionTemplates = function* (params) {
    this.setPermissionTemplates(this.permissionTemplates.fetching());
    const data = yield permissionTemplatesService.getPermissionTemplates(params);
    this.setPermissionTemplates(this.permissionTemplates.fetched(data));
  };

  setAllPermissionTemplates = (data) => {
    this.allPermissionTemplates = data;
  }
  getAllPermissionTemplates = function* () {
    this.setAllPermissionTemplates(this.allPermissionTemplates.fetching());
    const data = yield permissionTemplatesService.getPermissionTemplates();
    this.setAllPermissionTemplates(this.allPermissionTemplates.fetched(data.items));
  };

  setPermissionTemplateDetails = (data) => {
    this.permissionTemplateDetails = data;
  }
  getPermissionTemplateDetails = function* (id) {
    this.setPermissionTemplateDetails(this.permissionTemplateDetails.fetching());
    const data = yield permissionTemplatesService.getPermissionTemplateDetails(id);
    this.setPermissionTemplateDetails(this.permissionTemplateDetails.fetched(data));
  };

  addPermissionTemplate = (data) => {
    return permissionTemplatesService.addPermissionTemplate(data);
  }

  editPermissionTemplate = (data) => {
    return permissionTemplatesService.editPermissionTemplate(data);
  }

  deletePermissionTemplate = (id) => {
    return permissionTemplatesService.deletePermissionTemplate(id);
  }

}

export const permissionTemplatesStore = new PermissionTemplatesStore();