import React, { useCallback, useState } from 'react';

import { Checkbox } from '../../checkbox';
import { Icon } from '../../icon';

export const PGTransferListItem = ({
  label,
  children,
  onSelect,
  selected,
  disabled,
  noPadding = false,
  withBorder = true,
}) => {

  const [ open, setOpen ] = useState(true);

  const handleChange = useCallback((event) => {
    onSelect(event.target.checked);
  }, [onSelect]);

  return (
    <div className={`pg-transfer-list-item ${withBorder ? 'with-border' : ''} ${noPadding ? 'no-padding' : ''}`}>
      <div className="pg-transfer-list-item-header">
        {
          disabled ? (
            label
          ) : (
            <Checkbox
              label={label}
              checked={selected}
              labelTooltip={false}
              onChange={handleChange}
            />
          )
        }
        {
          children ? (
            <Icon
              onClick={() => setOpen((prev) => !prev)}
              type={open ? 'bc-icon-arrow-up' : 'bc-icon-arrow-down'}
              className="child-list-opener-button"
            />
          ) : null
        }
      </div>
      {
        (children && open) ? (
          <div className="pg-transfer-list-item-children">
            { children }
          </div>
        ) : null
      }
    </div>
  );
};
